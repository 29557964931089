import React from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import TransactionsContainer from "./TransactionsContainer";
import ReportedTransactionsContainer from "../Reportedtransactions/ReportedTransactionsContainer";

const { TabPane } = Tabs;

const TransactionsTabsView = props => {
  const { currentPath, onChange } = props;

  return (
    <Tabs activeKey={currentPath} onTabClick={onChange}>
      <TabPane
        key="reported-transactions"
        tab={<Link to="/transactions/reported-transactions">Reported</Link>}
      >
        <ReportedTransactionsContainer {...props} />
      </TabPane>
      <TabPane
        key="all-transactions"
        tab={<Link to="/transactions/all-transactions">All</Link>}
      >
        <TransactionsContainer {...props} />
      </TabPane>
    </Tabs>
  );
};

export default TransactionsTabsView;
