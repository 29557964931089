import React, { Component } from "react";
import { PrivateRoute } from "../app/PrivateRoute";
import { Layout } from "antd";
import { Route, Switch, Redirect } from "react-router-dom";

import { getUser } from "../../helpers";
import LoginContainer from "../login/LoginContainer";
import EmployersContainer from "../Employers/EmployersContainer";
import ErrorsTabsContainer from "../Errors/ErrorsTabsContainer";
import CategoriesListContainer from "../categories/CategoriesListContainer";
import PlaidCategoriesContainer from "../plaidCategories/PlaidCategoriesContainer";
import EmployersDetailsContainer from "../EmployersDetails/EmployersDetailsContainer";
import MerchantsListContainer from "../merchants/MerchantsListContainer";
import RequestAccessContainer from "../RequestAccess/RequestAccessContainer";
import TransactionsTabsContainer from "../Transactions/TransactionsTabsContainer";
import EmployeeDetailContainer from "../Employees/EmployeeDetailContainer";
import JoonReports from "../Employers/JoonReports";

const { Content } = Layout;
class AppContent extends Component {
  render() {
    return (
      <div>
        {/* Base page is login page for logged out user and dashboard for logged in user with lab profile */}
        <Layout>
          <Content>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  return getUser() ? (
                    <Redirect to="/employers" />
                  ) : (
                    <LoginContainer />
                  );
                }}
              />
              <Route path="/sign-in" component={LoginContainer} />
              <PrivateRoute
                exact
                path="/employers"
                component={EmployersContainer}
              />

              <PrivateRoute
                path="/merchants"
                component={MerchantsListContainer}
              />
              <Route
                path="/joon-categories"
                component={CategoriesListContainer}
              />
              <PrivateRoute
                path="/plaid-categories"
                component={PlaidCategoriesContainer}
              />

              <PrivateRoute
                exact
                path="/employers/:empId/employee"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/profile"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/benefit-program"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/invoices"
                component={EmployersDetailsContainer}
              />

              <PrivateRoute
                path="/request-a-demo"
                component={RequestAccessContainer}
              />

              <PrivateRoute
                path="/transactions/all-transactions"
                component={TransactionsTabsContainer}
              />
              <PrivateRoute
                path="/errors/plaid-account-errors"
                component={ErrorsTabsContainer}
              />
              <PrivateRoute
                path="/errors/transaction-account-errors"
                component={ErrorsTabsContainer}
              />
              <PrivateRoute
                path="/errors/stripe-errors"
                component={ErrorsTabsContainer}
              />
              <PrivateRoute
                path="/transactions/reported-transactions"
                component={TransactionsTabsContainer}
              />

              <PrivateRoute
                exact
                path="/employers/:employerId/employees/:empId/profile"
                component={EmployeeDetailContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:employerId/employees/:empId/transactions"
                component={EmployeeDetailContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/merchants/whitelist"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/merchants/blacklist"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:empId/reports"
                component={EmployersDetailsContainer}
              />

              <PrivateRoute
                path="/employers/:empId/merchants"
                component={EmployersDetailsContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:employerId/employees/:empId/benefit_transfers"
                component={EmployeeDetailContainer}
              />
              <PrivateRoute
                exact
                path="/employers/:employerId/employees/:empId/benefit_policy_periods"
                component={EmployeeDetailContainer}
              />
              <PrivateRoute
                exact
                path="/reports"
                component={JoonReports}
              />
            </Switch>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default AppContent;
