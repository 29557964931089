import React, { Component } from "react";
import qs from "qs";
import EmployersDetailsView from "./EmployersDetailsView";

/**
 * @function getCurrentTabName
 * @params pathName
 * @description It will return the current active tab
 */
const getCurrentTabName = pathName => pathName.split("/")[3];
const getEmpId = pathName => pathName.split("/")[2];

export default class EmployersDetailsContainer extends Component {
  state = {
    currentPath: null,
    loading: false,
    id: ""
  };

  componentDidMount() {
    const params = qs.parse(this.props.match.params);

    if (params) {
      this.setState({ id: params.id });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // get pathname like '/settings/vitals' from url
    const pathName = nextProps.location.pathname;
    const currentPage = getCurrentTabName(pathName);
    const empId = getEmpId(pathName);
    if (!prevState.currentPath !== currentPage) {
      return { currentPath: currentPage, id: empId };
    }
    return null;
  }

  render() {
    const { id, currentPath } = this.state;

    return (
      <div>
        <EmployersDetailsView id={id} currentPath={currentPath} />
      </div>
    );
  }
}
