import React from "react";
import {
  Table,
  Pagination,
  Input,
  Select,
  Row,
  Col,
  Form,
  Button,
  Spin,
  Tooltip,
  Icon
} from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

const FormItem = Form.Item;
const { Option } = Select;

const TransactionsView = props => {
  const {
    openBulkPurchasesModal,
    isProcessing,
    filterChange,
    transactionsList,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    searchText,
    onChangeSearch,
    handleSearch,
    updateTransactions,
    fromEmployeeDetail,
    toggleAddTransactionModal,
    toggleValidateTransactionModal,
    toggleDeleteConfirmation,
    toggleDetailModal,
    refreshTransactions,
    perPage,
    changePerPage,
    toggleHistoryModal
  } = props;

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id"
    },
    {
      title: "Employee Name",
      dataIndex: "employee_details.employee_name"
    },
    {
      title: "Employee Email",
      dataIndex: "employee_details.email"
    },

    {
      title: "Employer Name",
      dataIndex: "employee_details.employer_name"
    },
    {
      title: "Merchant",
      dataIndex: "name"
    },
    {
      title: "Amount",
      key: "amount",
      render: record => {
        return <div>{Dollar(record.amount)}</div>;
      }
    },
    {
      title: "Plaid Category",
      dataIndex: "plaid_category"
      /*
      dataIndex: "category",
      render: record => {
        const last = record && record[record.length - 1];
        return <div>{last && <div>{last}</div>}</div>;
      }
      */
    },
    {
      title: "Date",
      dataIndex: "transaction_date",
      width: 150,
      render: record => (
        <div>
          {record && <div>{moment.utc(record).format("DD MMM, YYYY")}</div>}
        </div>
      )
    },
    {
      title: "Benefit Program Name",
      dataIndex: "benefit_program_name"
    },
    {
      title: "",
      // width: "200px",
      render: record => {
        const data = record.joon_valid;
        let status = "";
        if (data) {
          status = "Yes";
        } else {
          status = "No";
        }
        return (
          <div>
            {record && (
              <Row type="flex" justify="space-between">
                {status === "No" ? (
                  <Col className="my-5">
                    <Button
                      onClick={() => toggleValidateTransactionModal(record.id, record.employee_details.employer_id)}
                      type="primary"
                      size="small"
                    >
                      Validate
                    </Button>
                  </Col>
                ) : (
                  <Col className="my-5">
                    <Button
                      onClick={() => updateTransactions(record.id, false, record.benefit_program_id)}
                      type="primary"
                      size="small"
                    >
                      In-Validate
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </div>
        );
      }
    },
    {
      title: "",
      key: "view-details",
      width: 80,
      render: record => (
        <div>
          <Tooltip title="View Details">
            <Button
              shape="circle"
              type="primary"
              size="small"
              onClick={() => toggleDetailModal(record)}
              className="mr-10"
            >
              <Icon type="eye" />
            </Button>
          </Tooltip>
        </div>
      )
    },
    {
      title: "",
      key: "view-history",
      width: 80,
      render: record => (
        <div>
          {record.transaction_activities_list &&
            record.transaction_activities_list.length > 0 && (
              <Tooltip title="View History">
                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  onClick={() => toggleHistoryModal(record)}
                  className="mr-10"
                >
                  <Icon type="clock-circle" />
                </Button>
              </Tooltip>
            )}
        </div>
      )
    },
    {
      title: "",
      key: "actions",
      width: 200,
      render: record => (
        <div>
          {selectedFilter === "manual" && (
            <React.Fragment>
              {/* <Tooltip title="Update">
                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  onClick={() => handleUpdateAction(record)}
                  className="mr-10"
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip> */}

              <Tooltip title="Delete">
                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  onClick={() => toggleDeleteConfirmation(record)}
                >
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      )
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col span={10}>
                <FormItem label="Search">
                  <Row gutter={16}>
                    <Col span={18}>
                      <Input
                        name="searchText"
                        placeholder="Search by name, email, merchant"
                        value={searchText}
                        onChange={onChangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>

              <Col span={14}>
                <Row type="flex" justify="end" gutter={16} align="bottom">
                  <Col span={4}>
                    <FormItem label="Per Page">
                      <Select
                        className="full-width"
                        defaultValue={perPage}
                        onChange={changePerPage}
                        value={perPage}
                      >
                        <Option value="10">10</Option>
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Filter">
                      <Select
                        className="full-width"
                        defaultValue="all"
                        onChange={filterChange}
                        value={selectedFilter}
                      >
                        <Option value="all">All</Option>
                        <Option value="invalid">Invalid</Option>
                        <Option value="valid">Valid</Option>
                        <Option value="manual">Manual</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  {fromEmployeeDetail && (
                    <Col>
                      <FormItem>
                        <Tooltip
                          title="Refresh transactions"
                          placement="topRight"
                        >
                          <Button
                            type="primary"
                            onClick={refreshTransactions}
                            shape="round"
                            icon="reload"
                          />
                        </Tooltip>
                      </FormItem>
                    </Col>
                  )}
                  {/* add button to allow user to add manual transactions */}
                  {fromEmployeeDetail && (
                    <Col>
                      <FormItem>
                        <Tooltip title="Add a transaction" placement="topRight">
                          <Button
                            type="primary"
                            onClick={toggleAddTransactionModal}
                            shape="round"
                            icon="plus"
                          />
                        </Tooltip>
                      </FormItem>
                    </Col>
                  )}
                  <Col>
                    <FormItem>
                      <Tooltip
                        title="Add multiple purchases"
                        placement="topRight"
                      >
                        <Button
                          type="primary"
                          onClick={openBulkPurchasesModal}
                          shape="round"
                          icon="usergroup-add"
                        />
                      </Tooltip>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {transactionsList !== undefined && transactionsList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={transactionsList}
                pagination={false}
                rowKey="id"
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={perPage}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="blank-View">
              {!isProcessing && (
                <Row>
                  <Col span={24}>
                    <FormItem>
                      <p>There are no transactions.</p>
                    </FormItem>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default TransactionsView;
