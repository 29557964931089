import { combineReducers } from "redux";
import recycleState from "redux-recycle"; // reset reducers to initailstate
import { loginReducer } from "./components/login/loginReducer";
import { navigationReducer } from "./components/body/Navigation";

const appReducer = combineReducers({
  navigation: navigationReducer,
  login: recycleState(loginReducer, ["RESET_REDUCERS"], {})
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_REDUCERS") {
    let langInfo = state.locale;
    state = undefined;
    state = { locale: langInfo }; // Save language details
  }

  return appReducer(state, action);
};

export default rootReducer;
