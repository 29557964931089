import React from "react";
import {
  Table,
  Pagination,
  Input,
  Row,
  Col,
  Form,
  Button,
  Spin,
  Tooltip,
  Icon
} from "antd";
import moment from "moment";

const FormItem = Form.Item;
const AccountErrorsView = props => {
  const {
    errorList,
    isProcessing,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    searchText,
    onChangeSearch,
    handleSearch,
    toggleDeleteConfirmation,
    toggleMetaErrorModal,
    toggleErrorModal
  } = props;

  const columns = [
    {
      title: "Request ID",
      dataIndex: "meta",
      render: record => {
        return <div>{record.request_id}</div>;
      }
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      render: record => {
        return <div>{record && record}</div>;
      }
    },

    {
      title: "User Name",
      dataIndex: "user_name",
      render: record => {
        return <div>{record && record}</div>;
      }
    },
    {
      title: "Email",
      dataIndex: "employer_email",
      render: record => {
        return <div>{record && record}</div>;
      }
    },
    {
      title: "Bank Name",
      dataIndex: "meta",
      render: record => {
        return (
          <div>{record && record.institution && record.institution.name}</div>
        );
      }
    },
    {
      title: "Error Code",
      dataIndex: "error",
      render: record => {
        return <div>{record && record.error_code}</div>;
      }
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: record => {
        return (
          <div>
            {record && <div>{moment.utc(record).format("DD MMM, YYYY")}</div>}
          </div>
        );
      }
    },

    {
      title: "",
      // width: "200px",
      render: record => {
        return (
          <div>
            {record && (
              <Row type="flex" justify="space-between" gutter={8}>
                <Col className="my-5">
                  <Button
                    onClick={() => toggleMetaErrorModal(record.meta)}
                    type="primary"
                    size="small"
                  >
                    Meta
                  </Button>
                </Col>
              </Row>
            )}
            {record && (
              <Row type="flex" justify="space-between" gutter={8}>
                <Col className="my-5">
                  <Button
                    onClick={() => toggleErrorModal(record.error)}
                    type="primary"
                    size="small"
                  >
                    Error
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        );
      }
    },

    {
      title: "",
      key: "actions",
      width: 100,
      render: record => (
        <div>
          {selectedFilter === "manual" && (
            <React.Fragment>
              <Tooltip title="Delete">
                <Button
                  shape="circle"
                  type="primary"
                  size="small"
                  onClick={() => toggleDeleteConfirmation(record)}
                >
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </React.Fragment>
          )}
        </div>
      )
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col>
                <FormItem>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        name="searchText"
                        placeholder="Search by error code, bank name, card holder name, etc."
                        value={searchText}
                        onChange={onChangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>
            </Row>
          </div>
          {errorList !== undefined && errorList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={errorList}
                pagination={false}
                rowKey="id"
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
              <div className="blank-View">
                {!isProcessing && (
                  <Row>
                    <Col span={24}>
                      <FormItem>
                        <p>There are no transactions.</p>
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default AccountErrorsView;
