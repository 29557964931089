import axios from "axios";

const getHeaders = () => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json"
  };
};

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: getHeaders(),
  withCredentials: true
})

export const getRequest = (url) => {
  return api.request({
    method: 'GET',
    url: url
  })
}

export const postRequest = (url, data) => {
  return api.request({
    method: 'POST',
    url: url,
    data
  })
}

export const deleteRequest = (url) => {
  return api.request({
    method: 'DELETE',
    url: url
  })
}

export const putRequest = (url, data) => {
  return api.request({
    method: 'PUT',
    url: url,
    data
  })
}