import React from "react";
import { Modal, Button, Table, Row, Col } from "antd";

export default class AssociateCategoryModal extends React.Component {
  render() {
    const {
      showModal,
      handleAssociateCategory,
      toggleModal,
      handleDisassociateCategory,
      selectedKey,
      categories,
      isProcessing,
      categoryId
    } = this.props;

    const columns = [
      {
        title: "Category Name",
        key: "id",
        dataIndex: "name"
      },
      {
        title: "",
        key: "id",
        render: record => {
          return (
            <div>
              <Row type="flex" span={24} justify="end">
                <Col>
                  <Button
                    loading={categoryId === record.id ? isProcessing : false}
                    onClick={() =>
                      record.status === "associated"
                        ? handleDisassociateCategory(record)
                        : handleAssociateCategory(record)
                    }
                    type="primary"
                    size="small"
                  >
                    {record.status === "associated"
                      ? "Disassociate"
                      : "Associate"}
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }
      }
    ];

    return (
      <div>
        <Modal
          title={`Associate ${selectedKey.category || selectedKey.name} to `}
          visible={showModal}
          onCancel={toggleModal}
          footer={null}
        >
          <div>
            <Table
              columns={columns}
              dataSource={categories}
              pagination={false}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
