import React, { Component } from "react";
import { Layout } from "antd";
import { connect } from "react-redux";
import TopNav from "./TopNav";

const { Header } = Layout;
class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    };
  }

  render() {
    return (
      <Header className="header">
        <TopNav
          showDrawer={this.props.showDrawer}
          visible={this.props.visible}
        />
      </Header>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppHeader);
export { connectedPage as AppHeader };
