import React from "react";
import {
  Table,
  Pagination,
  Input,
  Select,
  Row,
  Col,
  Form,
  Button,
  Spin
} from "antd";
import moment from "moment";

const FormItem = Form.Item;
const { Option } = Select;

const RequestAccessView = props => {
  const {
    isProcessing,
    filterChange,
    employerAccessList,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    cancelEmployersRequest,
    searchText,
    onchangeSearch,
    handleSearch
  } = props;

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "user_name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Phone number",
      key: "phone_number",
      dataIndex: "phone_number"
    },
    {
      title: "Company",
      key: "company",
      dataIndex: "company"
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: record => (
        <div>
          {record && <div>{moment(record).format("DD MMM, YYYY")}</div>}
        </div>
      )
    },
    {
      title: "",
      width: "200px",
      render: record => {
        return (
          <div>
            {record && record.status !== null && record.status === "REQUESTED" && (
              <Row type="flex" justify="space-between">
                <Col className="my-5">
                  <Button
                    onClick={() => cancelEmployersRequest(record.id)}
                    type="primary"
                    size="small"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col span={10}>
                <FormItem>
                  <Row gutter={16}>
                    <Col span={18}>
                      <Input
                        name="searchText"
                        placeholder="Search by name, email"
                        value={searchText}
                        onChange={onchangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>

              <Col span={14}>
                <Row type="flex" justify="end" gutter={16}>
                  <Col span={6}>
                    <FormItem>
                      <Select
                        className="full-width"
                        defaultValue="all"
                        onChange={filterChange}
                        value={selectedFilter}
                      >
                        <Option value="all">All</Option>

                        <Option value="invited">Invited</Option>
                        <Option value="cancelled">Cancelled</Option>
                        <Option value="requested">Requested</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {employerAccessList !== undefined && employerAccessList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={employerAccessList}
                pagination={false}
                rowKey="id"
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
              <div className="blank-View">
                {!isProcessing && (
                  <Row>
                    <Col span={24}>
                      <FormItem>
                        <p>There is no data.</p>
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default RequestAccessView;
