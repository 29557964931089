import React from "react";
import {
  Table,
  Button,
  Spin,
  Form,
  Row,
  Col,
  Input,
  Pagination,
  Tooltip
} from "antd";
import AssociateCategoryModal from "../plaidCategories/AssociateCategoryModal";

const FormItem = Form.Item;

const MerchantsListView = props => {
  const {
    onSelectKey,
    isProcessing,
    selectedKey,
    categories,
    showModal,
    toggleModal,
    merchants,
    handleAssociateCategory,
    handleDisassociateCategory,
    categoryId,
    searchText,
    onchangeSearch,
    handleSearch,
    currentPage,
    onChangePage,
    totalRecords,
    toggleAddMerchant
  } = props;

  const columns = [
    {
      title: "Merchant Name",
      key: "id",
      render: (text, record) => {
        return (
          <div
            onClick={() => {
              onSelectKey(record);
            }}
          >
            {record.name}
          </div>
        );
      }
    }
  ];

  return (
    <Spin spinning={isProcessing} className="fixed-spinner">
      <Row gutter={24} justify="space-between">
        <FormItem>
          <Col span={10}>
            <Input
              name="searchText"
              placeholder="Search by name"
              value={searchText}
              onChange={onchangeSearch}
              onPressEnter={handleSearch}
            />
          </Col>
          <Col span={2}>
            <Button type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Col>
          <Col span={2}>
            <FormItem>
              <Tooltip title="Add Merchant" placement="topRight">
                <Button
                  type="primary"
                  onClick={toggleAddMerchant}
                  shape="round"
                  icon="plus"
                />
              </Tooltip>
            </FormItem>
          </Col>
        </FormItem>
      </Row>
      {merchants.length ? (
        <div>
          <Table
            pagination={false}
            rowKey="id"
            dataSource={merchants}
            columns={columns}
          />
          <div className="pagination-wrapper">
            <div className="total-results-wrap">
              <span className="result-count">{totalRecords}</span>

              {totalRecords > 1 ? "results" : "result"}
            </div>

            <Pagination
              total={totalRecords}
              current={currentPage}
              onChange={onChangePage}
              pageSize={10}
              defaultCurrent={currentPage}
            />
          </div>
        </div>
      ) : (
          <div className="blank-View">
            <Row>
              <Col span={24}>
                <FormItem>
                  <p>There are no merchants.</p>
                </FormItem>
              </Col>
            </Row>
          </div>
        )}

      <AssociateCategoryModal
        isProcessing={isProcessing}
        selectedKey={selectedKey}
        categories={categories}
        showModal={showModal}
        toggleModal={toggleModal}
        handleDisassociateCategory={handleDisassociateCategory}
        handleAssociateCategory={handleAssociateCategory}
        categoryId={categoryId}
      />
    </Spin>
  );
};
export default MerchantsListView;
