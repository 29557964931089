import React from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import PlaidTransactionErrorsContainer from "./PlaidTransactionErrorsContainer";
import PlaidAccountErrorsContainer from "./PlaidAccountErrorsContainer";
import StripeErrorsContainer from "./StripeErrorsContainer";

const { TabPane } = Tabs;

const ErrorsTabsView = props => {
  const { currentPath, onChange } = props;

  return (
    <Tabs activeKey={currentPath} onTabClick={onChange}>
      <TabPane
        key="plaid-account-errors"
        tab={
          <Link to="/errors/plaid-account-errors">Plaid Account Errors</Link>
        }
      >
        <PlaidAccountErrorsContainer {...props} />
      </TabPane>
      <TabPane
        key="transaction-account-errors"
        tab={
          <Link to="/errors/transaction-account-errors">
            Transaction Account Errors
          </Link>
        }
      >
        <PlaidTransactionErrorsContainer {...props} />
      </TabPane>
      <TabPane
        key="stripe-errors"
        tab={<Link to="/errors/stripe-errors">Stripe Errors</Link>}
      >
        <StripeErrorsContainer {...props} />
      </TabPane>
    </Tabs>
  );
};

export default ErrorsTabsView;
