import React from "react";
import {
  Table,
  Row,
  Col,
  Form
} from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

const FormItem = Form.Item;

const BenefitTransfersView = props => {
  const {
    isProcessing,
    benefitTransfersList
  } = props;

  const columns = [
    {
      title: "Bnft. Tr. ID",
      dataIndex: "id"
    },
    {
      title: "Inv. ID",
      dataIndex: "invoice_id"
    },
    {
      title: "Dpst. Acc. ID",
      dataIndex: "deposit_account_id"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: value => {
        return <div>{Dollar(value)}</div>;
      }
    },
    {
      title: "Status",
      dataIndex: "stripe_transfer_status"
    },
    {
      title: "TransferType",
      dataIndex: "transfer_type"
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: value => {
        return <div>{moment.utc(value).format("DD MMM, YYYY HH:MM")}</div>;
      }
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: value => {
        return <div>{moment.utc(value).format("DD MMM, YYYY HH:MM")}</div>;
      }
    }
    
  ];
  return (
    <div>
        <React.Fragment>
          {benefitTransfersList !== undefined && benefitTransfersList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={benefitTransfersList.sort((a, b) => b.id - a.id)}
                pagination={false}
                rowKey="id"
              />
            </div>
          ) : (
              <div className="blank-View">
                {!isProcessing && (
                  <Row>
                    <Col span={24}>
                      <FormItem>
                        <p>There are no benefit transfers</p>
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            )}
        </React.Fragment>
    </div>
  );
};

export default BenefitTransfersView;
