import React from "react";
import { Row, Col, Card, Icon } from "antd";
import moment from "moment";

const AccountCard = props => {
  return (
    <Row>
      <Col>
        <div className="funding-accounts-wrapper">
          <div className="funding-card-wrapper">
            <Card>
              <div className="account-card-inner">
                <div className="account-card-icon">
                  <Icon type="dollar" />
                </div>
                <div className="accounts-card-info">
                  <div className="account-bank-name">
                    {props.accountDetails.institution_name}
                  </div>
                  <div className="account-name">
                    {props.accountDetails.account_name} •••• •••• ••••{" "}
                    {props.accountDetails.mask}
                  </div>

                  <p>
                    <span className="label">Added:</span>
                    <span className="value">
                      {moment(props.accountDetails.created_at).format(
                        "DD MMM, YYYY"
                      )}
                    </span>
                  </p>
                  <p>
                    <span className="label">Account Type:</span>
                    <span className="value">
                      {props.accountDetails.sub_type}
                    </span>
                  </p>
                  <p>
                    <span className="label">Account Id:</span>
                  </p>
                  <p>
                    {" "}
                    <span className="value">
                      {props.accountDetails.account_id}
                    </span>
                  </p>
                  <p>
                    <span className="label">Item Id:</span>
                  </p>
                  {props.accountDetails?.plaid_tokens && props.accountDetails?.plaid_tokens.map(token => (
                    <p>
                      <span className="value">
                        {token.status} - {token.plaid_response.item_id}
                      </span>
                    </p>
                  ))
                  }
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AccountCard;
