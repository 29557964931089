import React from "react";
import { Spin, Button, Row, Tabs, Icon, Modal } from "antd";
import { Link } from "react-router-dom";
import qs from "qs";
import {
  getEmployeeDetails,
  removeAdministrativePermission,
  addAdministrativePermission,
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  history,
  getCurrentTabName,
} from "../../helpers";
import EmployeeDetailModal from "./EmployeeDetailModal";
import TransactionsContainer from "../Transactions/TransactionsContainer";
import BenefitTransfersContainer from "../Transactions/BenefitTransfersContainer";

const { TabPane } = Tabs;
const { confirm } = Modal;
export default class EmployeeDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isProcessing: false,
      employeeDetails: null,
      employerId: "",
      employeeId: "",
      currentPath: "",
      hasAdministrativePermission: false,
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.match.params);
    const path = getCurrentTabName(window.location.pathname);
    this.setState({
      currentPath: path,
    });
    if (params && params.empId && params.employerId) {
      this.setState({
        employerId: params.employerId,
        employeeId: params.empId,
      });
      this.loadEmployeeDetails(params.empId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const pathName =
      nextProps && nextProps.location && nextProps.location.pathname;
    if (!pathName) {
      return null;
    }

    const currentPage = getCurrentTabName(pathName);
    if (!prevState.currentPath !== currentPage) {
      return { currentPath: currentPage };
    }
    return null;
  }

  /**
   * @function loadEmployeeDetails
   * @param employeeId
   * @description it will fetch the employee detail
   */
  loadEmployeeDetails = (employeeId) => {
    this.setState({
      isProcessing: true,
    });
    let errorMsg = "";
    getEmployeeDetails(employeeId)
      .then((res) => {
        this.setState({
          isProcessing: false,
        });
        if (res.data.success === true) {
          const hasAdmin = res.data.data.roles.filter(r => r.name === 'EmployerAdmin').length > 0
          this.setState({
            employeeDetails: res.data.data,
            hasAdministrativePermission: hasAdmin
          });
        } else {
          errorMsg = "Sorry, employee detail could not be fetched";
          showErrorNotification(errorMsg);
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
        errorMsg = "There was some error while processing your request";
        showErrorNotification(errorMsg);
      });
  };

  /**
   * @function goToEmployees
   * @description it will redirect user back to employees list
   */
  goToEmployees = () => {
    const { employerId } = this.state;
    history.push(`/employers/${employerId}/employee`);
  };

  /**
   * @function toggleAdministrativePermission
   * @description it toggles administrative permission
   */
  toggleAdministrativePermission = () => {
    this.setState(
      { hasAdministrativePermission: !this.state.hasAdministrativePermission }
    );
  };

  /**
   * @function removeAsAdmin
   * @description It will remove the administrative permissions from an employee.
   */
  removeAsAdmin = (employeeId) => {
    confirm({
      title:
        "Are you sure you want to remove the administrative permissions of this employee?",
      cancelText: "No",
      okText: "Yes",
      centered: true,
      onOk: () => {
        removeAdministrativePermission(employeeId)
          .then((res) => {
            if (res.status !== 200) throw res.data.error;
            showSuccessNotification(
              "Administrative permissions removed successfully!"
            );
            this.toggleAdministrativePermission(employeeId);
          })
          .catch((error) => {
            showErrorNotification(
              error.response.data.error ? error.response.data.error : "Error"
            );
          });
      },
    });
  };

  /**
   * @function makeAdmin
   * @description It will gives administrative permissions to an employee
   */
  makeAdmin = (employeeId) => {
    confirm({
      title:
        "Are you sure you want to give administrative permissions to this employee?",
      cancelText: "No",
      okText: "Yes",
      centered: true,
      onOk: () => {
        addAdministrativePermission(employeeId)
          .then((res) => {
            if (res.status !== 200) throw res.data.error;
            showSuccessNotification(
              "Administrative permissions added successfully!"
            );
            this.toggleAdministrativePermission(employeeId);
          })
          .catch((error) => {
            showErrorNotification(
              error.response.data.error ? error.response.data.error : "Error"
            );
          });
      },
    });
  };

  render() {
    const {
      employeeDetails,
      isProcessing,
      employeeId,
      employerId,
      currentPath,
      hasAdministrativePermission,
    } = this.state;

    return (
      <Spin spinning={isProcessing} className="fixed-spinner">
        <Row className="mb-15">
          <Button type="primary" onClick={this.goToEmployees}>
            <Icon type="left" />
            Back to Employees
          </Button>
        </Row>
        <Tabs activeKey={currentPath}>
          <TabPane
            key={`profile`}
            forceRender={true}
            tab={
              <Link
                to={`/employers/${employerId}/employees/${employeeId}/profile`}
              >
                Profile
              </Link>
            }
          >
            <EmployeeDetailModal
              employeeDetails={employeeDetails}
              makeAdmin={this.makeAdmin}
              removeAsAdmin={this.removeAsAdmin}
              hasAdministrativePermission={hasAdministrativePermission}
            />
          </TabPane>

          <TabPane
            key="transactions"
            forceRender={true}
            tab={
              <Link
                to={`/employers/${employerId}/employees/${employeeId}/transactions`}
              >
                Transactions
              </Link>
            }
          >
            <TransactionsContainer
              employeeId={employeeId}
              employerId={employerId}
              fromEmployeeDetail
            />
          </TabPane>

          <TabPane
            key="benefit_transfers"
            forceRender={true}
            tab={
              <Link
                to={`/employers/${employerId}/employees/${employeeId}/benefit_transfers`}
              >
                Benefit Transfers
              </Link>
            }
          >
            <BenefitTransfersContainer
              employeeId={employeeId}
            // We're actually not using employerID
            // The ajax call does not use it
            // We might want to use it, though
            // employerId={employerId}
            />
          </TabPane>

        </Tabs>
      </Spin>
    );
  }
}
