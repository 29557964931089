import { Modal, Spin, Row, Col } from "antd";
import React from "react";

export default class PreviewReceiptModal extends React.Component {
  render() {
    const {
      attachmentData,
      openReceiptPreviewModal,
      loadingPreview,
      togglePreviewModal,
      fileName,
      previewFileType
    } = this.props;
    return (
      <Modal
        title={fileName}
        wrapClassName="vertical-center-modal"
        visible={openReceiptPreviewModal}
        width={previewFileType === "application/pdf" ? 900 : 520}
        onCancel={() => togglePreviewModal()}
        footer={null}
      >
        <Spin spinning={loadingPreview}>
          {attachmentData ? (
            <div>
              <Row>
                <Col span={24}>
                  {previewFileType === "application/pdf" ? (
                    <object
                      data={attachmentData}
                      type="application/pdf"
                      style={{
                        width: "100%",
                        height: "737px"
                      }}
                    >
                      <embed src={attachmentData} type="application/pdf" />
                    </object>
                  ) : (
                    <img
                      alt=""
                      style={{ width: "100%" }}
                      src={attachmentData}
                    />
                  )}
                </Col>
              </Row>
            </div>
          ) : (
            <div className="text-center kc-font-sz">loading</div>
          )}
        </Spin>
      </Modal>
    );
  }
}
