import React, { Component } from "react";
import { Form, Modal } from "antd";
import moment from "moment";
import _ from "lodash";
import StripeErrorsView from "./StripeErrorsView";
import {
  updateTransaction,
  getEmployersBenefitProgram,
  deleteTransaction,
  getPdf,
  getStripeErrors
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  getProp
} from "../../helpers";
import ErrorsStripeModal from "./ErrorsStripeModal";

class StripeErrorsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0,
      currentPage: 1,
      transactionsList: [],
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      showModal: false,
      totalRecords: "",
      searchText: "",
      employeeId: "",
      showAddTransactionModal: false,
      benefitCategories: [],
      employerId: "",
      transactionId: "",
      showDeleteTransactionConfirmModal: false,
      selectedBenefitCategoryId: "",
      showDetailModal: false,
      transactionDetail: {},
      showRefreshModal: false,
      loadingPreview: false,
      openReceiptPreviewModal: false,
      previewFileType: "",
      fileName: "",
      attachmentData: "",
      fileList: [],
      disabledUploadBtn: false,
      isProcessing: false,
      errorList: [],
      showMetaErrorModal: false,
      showErrorModal: false,
      json: {},
      url: "",
      showDetails: false,
      details: "",
      copiedMessage: false
    };
  }

  componentDidMount() {
    const { currentPage } = this.state;

    this.getErrorsList(currentPage);
  }

  componentWillReceiveProps(prevProps) {
    const { currentPage } = this.state;
    const { refresh } = this.props;

    if (prevProps.refresh !== refresh) {
      this.getErrorsList(currentPage);
    }
  }

  /**
   * @function getCategories
   */
  getCategories = employerId => {
    getEmployersBenefitProgram(employerId).then(
      response => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response)) {
          const getCategories = getProp([
            "data",
            "data",
            "benefit_program_categories"
          ]);
          const categories = getCategories(response);
          this.setState({ benefitCategories: categories });
        } else {
          this.setState({ benefitCategories: [] });
        }
      },
      () => {
        this.setState({ benefitCategories: [] });
      }
    );
  };

  /**
   * @function getTransactionsList
   * @para currentpage
   * @param filter
   * @description it will take the filter and current page and return employers list
   */
  getErrorsList = currentPage => {
    const { searchText } = this.state;
    this.setState({ isProcessing: true, errorList: [] });
    getStripeErrors(currentPage, searchText)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          // eslint-disable-next-line camelcase
          const { total_pages, total_records } = response.data;

          const errorList = response.data.data;

          // eslint-disable-next-line camelcase
          const totalPages = total_pages;
          // eslint-disable-next-line camelcase
          const totalRecords = total_records;
          this.setState({ errorList, totalPages, totalRecords });
        } else {
          this.setState({
            transactionsList: [],
            totalPages: 0,
            totalRecords: 0
          });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = value => {
    const page = 1;
    const { employeeId } = this.state;
    this.setState(
      { selectedFilter: value, currentPage: page, searchText: "" },
      () => {
        this.getTransactionsList(page, value, "", employeeId);
      }
    );
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    this.setState({ currentPage: page });
    this.getErrorsList(page);
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function toggleReferralModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText } = this.state;
    this.setState({ currentPage: 1, isProcessing: true, searchText }, () => {
      this.getErrorsList(1);
    });
  };

  /**
   * @function updateTransactions
   * @description it updates the transactions.
   */
  updateTransactions = (transactionId, status) => {
    const { selectedFilter, searchText, currentPage, employeeId } = this.state;
    this.setState({ isProcessing: true });
    const transactionStatus = {
      valid: status
    };
    updateTransaction(transactionId, transactionStatus)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          let successMsg = "";
          if (status === true) {
            successMsg = "Transactions validated successfully.";
          } else {
            successMsg = "Transactions in-validated successfully.";
          }

          showSuccessNotification(successMsg);

          this.getTransactionsList(
            currentPage,
            selectedFilter,
            searchText,
            employeeId
          );
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch(response => {
        const getError = getProp(["data", "errors", "title"]);
        const errorMsg = getError(response) || "There is some error!";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function toggleAddTransactionModal
   * @description it toggles "Add transaction modal"
   */
  toggleAddTransactionModal = () => {
    const { showAddTransactionModal, benefitCategories } = this.state;

    if (benefitCategories.length > 0) {
      this.setState({
        showAddTransactionModal: !showAddTransactionModal,
        selectedBenefitCategoryId: "",
        fileList: []
      });
    } else {
      Modal.info({
        title: "Add benefit categories",
        content: (
          <div>
            <p>Please add benefit categories to continue adding transactions</p>
          </div>
        ),
        onOk() { }
      });
    }
  };

  /**
   * @function disabledDate
   * @param current
   * @desc This method disables 'Today' button,
   * if current date is selected from filter
   */
  disabledDate = current => current > moment().endOf("day");

  /**
   * @function handleUpdateAction
   * @param transactionObj
   * @desc This method handles settings form for updating transaction
   */
  handleUpdateAction = transactionObj => {
    const { form, fromEmployeeDetail } = this.props;
    if (!fromEmployeeDetail) {
      this.setState({ employeeId: transactionObj.employee_details.id });
      this.getCategories(transactionObj.employee_details.employer_id);
    }
    this.setState(
      {
        showAddTransactionModal: true,
        transactionId: transactionObj.id,
        selectedBenefitCategoryId:
          transactionObj.basic_benefit_program_category_id
      },
      () => {
        form.setFieldsValue({
          transactionName: transactionObj.name,
          amount: transactionObj.amount,
          transactionDate: moment(transactionObj.transaction_date)
        });
      }
    );
  };

  /**
   * @function toggleDeleteConfirmation
   * @param transactionId
   * @desc This method handles toggling delete confirmation modal
   */
  toggleDeleteConfirmation = (transactionObj = {}) => {
    const { showDeleteTransactionConfirmModal } = this.state;
    const { fromEmployeeDetail } = this.props;
    this.setState({
      showDeleteTransactionConfirmModal: !showDeleteTransactionConfirmModal,
      transactionId: transactionObj.id
    });
    if (!fromEmployeeDetail && _.isEmpty({ transactionObj })) {
      this.setState({
        employeeId: transactionObj.employee_details.employer_id
      });
    }
  };

  /**
   * @function removeTransaction
   * @param e
   * @desc This method handles removing a transaction
   */
  removeTransaction = e => {
    const {
      selectedFilter,
      searchText,
      currentPage,
      employeeId,
      transactionId
    } = this.state;
    const { fromEmployeeDetail } = this.props;
    e.stopPropagation();

    this.setState({
      isProcessing: true
    });
    deleteTransaction(transactionId, employeeId)
      .then(res => {
        if (res.data.success === true) {
          showSuccessNotification(res.data.message);
          this.setState({ showDeleteTransactionConfirmModal: false });
          let employeeIdFilter = employeeId;
          if (!fromEmployeeDetail) {
            employeeIdFilter = "";
          }
          this.getTransactionsList(
            currentPage,
            selectedFilter,
            searchText,
            employeeIdFilter
          );
        } else {
          showErrorNotification(res.data.errors.title);
        }
        this.setState({
          isProcessing: false
        });
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /** @function toggleDetailModal
   * @param "transactionDetail"
   * @desc - toggles the transaction detail modal window
   */
  toggleDetailModal = (transactionDetail = {}) => {
    const { showDetailModal } = this.state;
    this.setState({ showDetailModal: !showDetailModal, transactionDetail });
  };

  /**
   *  @function toggleRefreshModal
   * @desc - toggles the transaction refresh modal window
   */
  toggleRefreshModal = () => {
    this.setState({ showRefreshModal: !this.state.showRefreshModal });
  };

  /** @function getPatientDetail
   * @param url
   * @desc - downloads the pdf file to show
   */
  previewReceipt = (fileName, urls, fileType) => {
    this.setState({
      loadingPreview: true,
      openReceiptPreviewModal: true,
      previewFileType: fileType,
      fileName
    });
    const url = fileType === "application/pdf" ? urls.pdf : urls.original;
    if (url) {
      getPdf(url)
        .then(res => {
          const reader = new window.FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            const base64data = reader.result;
            this.setState({
              attachmentData: base64data,
              loadingPreview: false
            });
          };
        })
        .catch(() => {
          this.setState({
            loadingPreview: false
          });
        });
    }
  };

  /** @function togglePreviewModal
   * @desc - toggles the pdf modal window
   */
  togglePreviewModal = () => {
    const { openReceiptPreviewModal } = this.state;
    this.setState({
      openReceiptPreviewModal: !openReceiptPreviewModal,
      attachmentData: ""
    });
  };

  /**
   * @func toggleMetaErrorModal
   * @description it will cancel or open meta error modal.
   */
  toggleMetaErrorModal = (data, url) => {
    const { showMetaErrorModal } = this.state;
    this.setState({ json: JSON.stringify(data), url }, () => {
      this.setState({ showMetaErrorModal: !showMetaErrorModal });
    });
  };

  /**
   * @func toggleErrorModal
   * @description it will cancel or open meta error modal.
   */
  toggleErrorModal = (data, url) => {
    this.setState({ json: JSON.stringify(data), url }, () => {
      this.setState({ showErrorModal: true });
    });
  };

  /**
   * @func toggleMetaErrorModal
   * @description it will cancel or open meta error modal.
   */
  hideMetaErrorModal = () => {
    this.setState({ showMetaErrorModal: false, copiedMessage: false }, () => {
      this.setState({ json: {} });
    });
  };

  /**
   * @func toggleErrorModal
   * @description it will cancel or open meta error modal.
   */
  hideErrorModal = () => {
    this.setState({ showErrorModal: false, copiedMessage: false }, () => {
      this.setState({ json: {}, url: "" });
    });
  };

  /**
   * @func toggleDetails
   * @description it will cancel or open meta error modal.
   */
  toggleDetails = data => {
    this.setState({ details: data }, () => {
      this.setState({ showDetails: true });
    });
  };

  /**
   * @func hideDetailsModal
   * @description it will cancel or open meta error modal.
   */
  hideDetailsModal = () => {
    this.setState({ showDetails: false, details: "" });
  };

  /**
   * @function copyToMessage
   * @description it will show the meta error is copied.
   */
  copyToMessage = () => {
    this.setState({ copiedMessage: true });
  };

  render() {

    const {
      filteredInfo,
      sortedInfo,
      transactionsList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      totalRecords,
      searchText,
      showMetaErrorModal,
      errorList,
      json,
      url,
      copiedMessage
    } = this.state;
    const { fromEmployeeDetail } = this.props; // we will get "employeeId" as props, from Employee detail container

    return (
      <div>
        <StripeErrorsView
          toggleErrorModal={this.toggleErrorModal}
          toggleMetaErrorModal={this.toggleMetaErrorModal}
          errorList={errorList}
          updateTransactions={this.updateTransactions}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onChangeSearch={this.handleChange}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          transactionsList={transactionsList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
          fromEmployeeDetail={fromEmployeeDetail}
          toggleAddTransactionModal={this.toggleAddTransactionModal}
          handleUpdateAction={this.handleUpdateAction}
          toggleDeleteConfirmation={this.toggleDeleteConfirmation}
          toggleDetailModal={this.toggleDetailModal}
          toggleRefreshModal={this.toggleRefreshModal}
          toggleDetails={this.toggleDetails}
        />

        <ErrorsStripeModal
          copied={copiedMessage}
          CopyTo={this.copyToMessage}
          hideErrorModal={this.hideMetaErrorModal}
          json={json}
          url={url}
          title="Error Message"
          showMetaErrorModal={showMetaErrorModal}
        />
      </div>
    );
  }
}
const stripeForm = Form.create()(StripeErrorsContainer);
export default stripeForm;
