import { postRequest, deleteRequest } from "./index"

export const postLogin = (email, password) => {
  const signInObj = {
    user: {
      email,
      password
    }
  };
  return postRequest('/auth/sign_in', signInObj)
};

export const logout = () => {
  const url = "/auth/sign_out"

  return deleteRequest(url)
}
