import React, { Component } from "react";
import { connect } from "react-redux";
import { history } from "../../helpers";
import LoginView from "./LoginView";
import { loginAction } from "./loginActions";
class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      error: false,
      errorMsg: "",
      serverErrorText: "",
      serverError: false
    };
  }

  /**
   * @function handleChange
   * @param e
   * @desc This handles the phone number and password input changes
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      errorMsg: "",
      [name]: value.replace(/\s/g, "")
    });
  };

  /**
   * @function setStateForInvalidData
   * @param errorText
   * @return nothing
   * @desc This method sets error messages state
   */
  setStateForInvalidData = errorText => {
    this.setState({
      error: true,
      errorMsg: errorText,
      serverError: false,
      serverErrorText: ""
    });
  };

  /**
   * @function handleSubmit
   * @param e
   * @desc This handles the submission of login parameters, calls login action
   */
  handleSubmit = e => {
    e.preventDefault();
    const { email, password } = this.state;
    const { logUserIn } = this.props;
    if (!email || !password) {
      this.setStateForInvalidData("email_and_password_required");
      return;
    }
    const regExp = /\S+@\S+\.\S+/;

    if (regExp.test(email) === false) {
      this.setStateForInvalidData("Invalid email");
      return;
    }

    if (email && password) {
      logUserIn(email, password);
    }
  };

  /**
   * @function processErrors
   * @param errors
   * @param nothing
   * @desc This handles setting state
   *  of variable which
   * is to be shown to the user
   *  when api returns some error
   */
  processErrors = errors => {
    switch (errors.source) {
      case "password":
        this.setState({
          errorMsg: "Invalid Password"
        });
        break;
      case "username_or_password":
        this.setState({
          errorMsg: "Invalid username or password"
        });
        break;
      case "not an admin":
        this.setState({
          errorMsg: "User not an admin"
        });
        break;
      default:
        this.setState({
          errorMsg: "Error!"
        });
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.isProcessing && this.props.isProcessing) {
      if (newProps.user) {
        history.push("./employers");
      }
    }

    if (newProps.loginError !== this.props.loginError) {
      this.setState({ error: false, errorMsg: "", serverError: true });
      this.processErrors(newProps.loginError);
    }
  }

  render() {
    const { email, password, errorMsg } = this.state;
    const { isProcessing } = this.props;
    return (
      <LoginView
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        email={email}
        password={password}
        isProcessing={isProcessing}
        errorMsg={errorMsg}
      />
    );
  }
}

const mapStateToProps = state => ({
  isProcessing: state.login.isProcessing,
  loginError: state.login.error,
  user: state.login.user
});

const mapDispatchToProps = dispatch => ({
  logUserIn: (email, password) => dispatch(loginAction(email, password))
});

const connectedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
export { connectedPage as default };
