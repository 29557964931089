import React from "react";
import {
  Table,
  Pagination,
  Input,
  Select,
  Row,
  Col,
  Form,
  Button,
  Spin,
  Tooltip
} from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";
import { UserStatus } from "../../helpers";

const FormItem = Form.Item;
const { Option } = Select;

const canReInvite = user => {
  return user?.status && (user.status == UserStatus.INVITED || (user.status == UserStatus.REMOVED && !user.joined_at))
}

const EmployeesView = props => {
  const {
    isProcessing,
    filterChange,
    employeesList,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    openModal,
    reinviteEmployees,
    cancelInvites,
    searchText,
    onchangeSearch,
    handleSearch,
    openBulkEmployeeModal,
    handleEmployeeSelect,
    toggleRemoveEmployeeConfirmation,
    toggleRefreshModal
  } = props;

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "employeeID",
    },
    {
      title: "Name",
      key: "name",
      render: record => (
        <div>
          {record && (
            <div>{`${record.first_name} ${record.last_name}`}</div>
          )}
        </div>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Deleted At",
      dataIndex: "deleted_at",
      key: "deleted_at",
      render: record => (
        <div>
          {record && <div>{moment(record).format("DD MMM, YYYY")}</div>}
        </div>
      )
    },
    {
      title: "Invited At",
      dataIndex: ["earliest_invite", "sent_at"],
      key: "invited_at",
      render: (text, record) => {
        const sendAt = record?.earliest_invite?.send_at

        return (
          <div>
            {text ? (
              <div>{moment(text).format("DD MMM, YYYY")}</div>
            ) : sendAt ? (
              <div>{`Send on: ${moment(sendAt).format("DD MMM, YYYY")}`}</div>
            ) : (<></>)}
          </div>
        )
      }
    },
    {
      title: "Joined At",
      dataIndex: "accepted_invite.accepted_at",
      key: "joined_at",
      render: record => {
        return (
          <div>
            {record && record !== null && (
              <div>{moment(record).format("DD MMM, YYYY")}</div>
            )}
          </div>
        );
      }
    },
    {
      title: "",
      width: "200px",
      render: record => {
        return (
          <div>
            {canReInvite(record) && (
              <Row type="flex" justify="space-between">
                <Col className="my-5">
                  <Button
                    onClick={() => reinviteEmployees(record.id)}
                    type="primary"
                    size="small"
                  >
                    Re Invite
                  </Button>
                </Col>
                <Col className="my-5">
                  <Button
                    onClick={() => cancelInvites(record.id)}
                    type="primary"
                    size="small"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}

            <Row type="flex" justify="space-between">
              {/* refresh button */}
              {record &&
                record.invite_status !== null &&
                (record.invite_status === "ACCEPTED" ||
                  record.invite_status === "ADMIN") && (
                  <Col className="my-5">
                    <Tooltip title="Refresh Transactions">
                      <Button
                        type="primary"
                        shape="circle"
                        icon="reload"
                        onClick={e => toggleRefreshModal(record.id, e)}
                      />
                    </Tooltip>
                  </Col>
                )}
              {/* Delete button */}
              {record &&
                record.status !== null &&
                [UserStatus.ENABLED, UserStatus.INVITED].includes(record.status) && (
                  <Col className="my-5">
                    <Button
                      onClick={e => toggleRemoveEmployeeConfirmation(record, e)}
                      type="primary"
                      size="small"
                    >
                      Remove
                    </Button>
                  </Col>
                )}
              {record &&
                record.id && (
                  <Col className="my-5">
                    <Button
                      onClick={e => handleEmployeeSelect(record, e)}
                      type="primary"
                      size="small">
                      View
                    </Button>
                  </Col>
                )}
            </Row>
          </div>
        );
      }
    }
  ];

  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col span={10}>
                <FormItem>
                  <Row gutter={16}>
                    <Col span={18}>
                      <Input
                        name="searchText"
                        placeholder="Search by name, email"
                        value={searchText}
                        onChange={onchangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>

              <Col span={14}>
                <Row type="flex" justify="end" gutter={16}>
                  <Col span={6}>
                    <FormItem>
                      <Select
                        className="full-width"
                        defaultValue="all"
                        onChange={filterChange}
                        value={selectedFilter}
                      >
                        <Option value="all">All</Option>
                        <Option value={UserStatus.ENABLED}>{UserStatus.ENABLED}</Option>
                        <Option value={UserStatus.DISABLED}>{UserStatus.DISABLED}</Option>
                        <Option value={UserStatus.INVITED}>{UserStatus.INVITED}</Option>
                        <Option value={UserStatus.REMOVED}>{UserStatus.REMOVED}</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col>
                    <FormItem>
                      <Tooltip title="Invite Employer" placement="topRight">
                        <Button
                          type="primary"
                          onClick={openModal}
                          shape="round"
                          icon="plus"
                        />
                      </Tooltip>
                    </FormItem>
                  </Col>
                  <Col>
                    <FormItem>
                      <Tooltip
                        title="Invite Bulk Employee"
                        placement="topRight"
                      >
                        <Button
                          type="primary"
                          onClick={openBulkEmployeeModal}
                          shape="round"
                          icon="usergroup-add"
                        />
                      </Tooltip>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {employeesList !== undefined && employeesList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={employeesList}
                pagination={false}
                rowKey={"id"}
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="blank-View">
              {!isProcessing && (
                <Row>
                  <Col span={24}>
                    <FormItem>
                      <p>There are no employees.</p>
                    </FormItem>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default EmployeesView;
