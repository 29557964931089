import React from "react";
import { Modal, Form, Input, Row, Col, Button } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;
export default class AddCategoryModal extends React.Component {
  /**
   * @function validateName
   * @param rules
   * @param value
   * @param callback
   */
  validateName = (rule, value, callback) => {
    const { name } = this.props;

    if (value === "" || (value && value.trim() === "")) {
      callback(`Category name required`);
    }

    if (name === null) {
      callback("Category name required");
    }
    callback();
  };

  render() {
    const {
      name,
      description,
      showModal,
      handleAddCategory,
      toggleModal,
      form,
      editCategory,
      updateJoonCategory
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title={editCategory ? "Edit Category" : "Add Category"}
          visible={showModal}
          onCancel={() => {
            toggleModal("cancel");
          }}
          footer={null}
        >
          <Form
            onSubmit={editCategory ? updateJoonCategory : handleAddCategory}
          >
            <Row type="flex" justify="space-between" gutter={16}>
              <Col span={12}>
                <FormItem label="Category Name">
                  {getFieldDecorator("name", {
                    initialValue: `${name}`,
                    rules: [
                      {
                        required: true,
                        validator: this.validateName
                      }
                    ]
                  })(
                    <Input
                      type="text"
                      placeholder="Please enter category name"
                      name="name"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={24}>
                <FormItem label="Category Description">
                  {getFieldDecorator("description", {
                    initialValue: `${description ? description : ""}`,
                    rules: [
                      {
                        required: true
                      }
                    ]
                  })(
                    <TextArea
                      type="text"
                      placeholder="Please enter category description"
                      name="description"
                    />
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row className="modal-footer-row">
              <Col span={24} className="text-right">
                <Button size="small" onClick={toggleModal}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="small"
                  className="ml-8"
                >
                  {editCategory ? "Save" : "Add Category"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
