import React, { Component } from "react";
import { Form } from "antd";
import TransactionsView from "./ReportedTransactionsView";
import {
  getReportedTransactions,
  getPdf,
  adminUpdateTransaction
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  getProp
} from "../../helpers";
import ReportedTransactionDetailModal from "./ReportedTransactionDetailModal";
import PreviewReceiptModal from "./PreviewReceiptModal";

class ReportedTransactionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: 0,
      currentPage: 1,
      perPage: localStorage.getItem("perPage")
        ? parseInt(localStorage.getItem("perPage"))
        : 10,
      transactionsList: [],
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      showModal: false,
      totalRecords: "",
      searchText: "",
      loadingPreview: false,
      openReceiptPreviewModal: false,
      fileName: "",
      attachmentData: "",
      transactionDetail: {},
      showDetailModal: false,
      previewFileType: ""
    };
  }

  componentDidMount() {
    const { currentPage, selectedFilter } = this.state;
    this.getTransactionsList(currentPage, selectedFilter, "");
  }

  componentWillReceiveProps(prevProps) {
    const { currentPage, selectedFilter } = this.state;
    const { refresh } = this.props;

    if (prevProps.refresh !== refresh) {
      this.getTransactionsList(currentPage, selectedFilter, "");
    }
  }

  /**
   * @function getTransactionsList
   * @para currentpage
   * @param filter
   * @description it will take the filter and current page and return employers list
   */
  getTransactionsList = (currentPage, filter, searchText) => {
    const { perPage } = this.state;
    this.setState({ isProcessing: true, transactionsList: [] });
    getReportedTransactions(currentPage, filter, searchText, perPage)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          // eslint-disable-next-line camelcase
          const { total_pages, total_records, data } = response.data;
          const { reported_transactions } = data;
          const transactionsList = reported_transactions;
          // eslint-disable-next-line camelcase
          const totalPages = total_pages;
          // eslint-disable-next-line camelcase
          const totalRecords = total_records;
          this.setState({ transactionsList, totalPages, totalRecords });
        } else {
          this.setState({
            transactionsList: [],
            totalPages: 0,
            totalRecords: 0
          });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = value => {
    const page = 1;
    this.setState({ selectedFilter: value, currentPage: page }, () => {
      this.getTransactionsList(page, value, "");
    });
  };

  /** @function changePerPage
   * @desc - handle change perPage
   */
  changePerPage = page => {
    const { selectedFilter } = this.state;
    const currentPage = 1;
    localStorage.setItem("perPage", page);
    this.setState({ perPage: parseInt(page), currentPage }, () => {
      this.getTransactionsList(currentPage, selectedFilter, "");
    });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    const { selectedFilter } = this.state;
    this.setState({ currentPage: page });
    this.getTransactionsList(page, selectedFilter, "");
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function toggleReferralModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  updateTransactionInternalState = (transactionID, attributes) => {
    this.setState((state) => ({
      ...state,
      transactionsList: state.transactionsList.map((t) =>
        t.transaction_details.id === transactionID
          ? {
            ...t,
            ...attributes,
          }
          : t
      ),
    }));
  };

  /**
   * @function updateTransaction
   */
  updateTransaction = (transactionId, valid, benefitProgramId) => {
    this.updateTransactionInternalState(transactionId, { uiPending: true });
    const payload = {
      valid,
      benefit_program_id: benefitProgramId
    };
    adminUpdateTransaction(transactionId, payload)
      .then((response) => {
        if (response.data.success) {
          let successMsg = "";
          if (valid) {
            successMsg = `Transaction ${transactionId} approved successfully.`;
            this.updateTransactionInternalState(transactionId, {
              uiPending: false,
              uiState: "Approved",
            });
          } else {
            successMsg = `Transaction ${transactionId} declined successfully.`;
            this.updateTransactionInternalState(transactionId, {
              uiPending: false,
              uiState: "Declined",
            });
          }
          showSuccessNotification(successMsg);
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
          this.updateTransactionInternalState(transactionId, {
            uiPending: false,
          });
        }
      })
      .catch((response) => {
        const getError = getProp(["response", "data", "message", "title"]);
        const errorMsg = getError(response) || "There is some error!";
        showErrorNotification(`${errorMsg} (transaction ${transactionId})`);
        this.updateTransactionInternalState(transactionId, {
          uiPending: false,
        });
      });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText, currentPage, selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.getTransactionsList(currentPage, selectedFilter, searchText);
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /** @function getPatientDetail
   * @param url
   * @desc - downloads the pdf file to show
   */
  previewReceipt = (fileName, urls, fileType) => {
    this.setState({
      loadingPreview: true,
      openReceiptPreviewModal: true,
      previewFileType: fileType,
      fileName
    });
    const url = fileType === "application/pdf" ? urls.pdf : urls.original;
    if (url) {
      getPdf(url)
        .then(res => {
          const reader = new window.FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            const base64data = reader.result;
            this.setState({
              attachmentData: base64data,
              loadingPreview: false
            });
          };
        })
        .catch(() => {
          this.setState({
            loadingPreview: false
          });
        });
    }
  };

  /** @function togglePreviewModal
   * @desc - toggles the pdf modal window
   */
  togglePreviewModal = () => {
    const { openReceiptPreviewModal } = this.state;
    this.setState({
      openReceiptPreviewModal: !openReceiptPreviewModal,
      attachmentData: ""
    });
  };

  /** @function toggleDetailModal
   * @param "transactionDetail"
   * @desc - toggles the transaction detail modal window
   */
  toggleDetailModal = (transactionDetail = {}) => {
    const { showDetailModal } = this.state;
    this.setState({ showDetailModal: !showDetailModal, transactionDetail });
  };

  render() {
    const {
      filteredInfo,
      sortedInfo,
      transactionsList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      totalRecords,
      searchText,
      attachmentData,
      openReceiptPreviewModal,
      loadingPreview,
      fileName,
      showDetailModal,
      transactionDetail,
      previewFileType,
      perPage
    } = this.state;

    return (
      <div>
        <TransactionsView
          updateTransaction={this.updateTransaction}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onchangeSearch={this.handleChange}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          transactionsList={transactionsList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
          previewReceipt={this.previewReceipt}
          toggleDetailModal={this.toggleDetailModal}
          perPage={perPage}
          changePerPage={this.changePerPage}
        />
        <PreviewReceiptModal
          attachmentData={attachmentData}
          openReceiptPreviewModal={openReceiptPreviewModal}
          loadingPreview={loadingPreview}
          togglePreviewModal={this.togglePreviewModal}
          fileName={fileName}
          previewFileType={previewFileType}
        />

        <ReportedTransactionDetailModal
          toggleDetailModal={this.toggleDetailModal}
          showDetailModal={showDetailModal}
          transactionDetail={transactionDetail}
          previewReceipt={this.previewReceipt}
        />
      </div>
    );
  }
}
const employeeForm = Form.create()(ReportedTransactionsContainer);
export default employeeForm;
