import { getRequest, postRequest } from "./index";

/**
 * @function getMerchants
 * @param currentPage
 * @param searchText
 * @desc  get merchants
 */

export const getMerchants = (currentPage, searchText) => {
  let url = `/merchants?page=${currentPage}&perpage=10`;
  if (searchText) {
    url = `/merchants?search_text=${encodeURIComponent(searchText)}&page=${currentPage}&perpage=10`;
  }
  return getRequest(url)
};

/**
 * @function searchMerchants
 * @param searchText
 * @desc  match merchants by name using given text, no pagination
 */

export const searchMerchants = searchText => {
  const url = `/merchants?search_text=${searchText}`;
  return getRequest(url)
};

/**
 * @function getWhiteListMerchants
 * @param employerId
 * @param currentPage
 * @desc  get white listed merchants
 */

export const getWhiteListMerchants = (employerId, currentPage) => {
  const url = `/employers/${employerId}/merchants?whitelist=true&page=${currentPage}&perpage=10`;
  return getRequest(url)
};

/**
 * @function getBlackListMerchants
 * @param employerId
 * @param currentPage
 * @desc  get black listed merchants
 */

export const getBlackListMerchants = (employerId, currentPage) => {
  const url = `/employers/${employerId}/merchants?blacklist=true&page=${currentPage}&perpage=10`;
  return getRequest(url)
};

/**
 * @function associateMerchantCategory
 * @param data
 * @desc  associate categories
 */

export const associateMerchantCategory = data => {
  const url = `/merchants/${data.merchantId}/associate`;
  return postRequest(url, { basic_benefit_program_category_id: data.categoryId })
};

/**
 * @function addEmployerMerchant
 * @param employerId
 * @param id
 * @param selectedMerchant
 * @desc  add an employer merchant
 */

export const addEmployerMerchant = (employerId, id, selectedMerchant) => {
  const url = `/employers/${employerId}/merchants/${selectedMerchant}`;
  return postRequest(url, { merchant_id: id })
};

/**
 * @function addNewMerchant
 * @param data
 * @desc  add merchant
 */

export const addNewMerchant = data => {
  const url = '/merchants';
  return postRequest(url, data)
};

/**
 * @function deleteMerchantWhiteList
 * @param employerId
 * @param id
 * @desc  delete merchant from white list
 */

export const deleteMerchantWhiteList = (employerId, id) => {
  const url = `/employers/${employerId}/merchants/remove_from_whitelist`;
  return postRequest(url, { merchant_id: id, employer_id: employerId })
};

/**
 * @function deleteMerchant
 * @param employerId
 * @param id
 * @desc  delete merchant
 */

export const deleteMerchantBlackList = (employerId, id) => {
  const url = `/employers/${employerId}/merchants/remove_from_blacklist`;
  return postRequest(url, { merchant_id: id, employer_id: employerId })
};

/**
 * @function disassociateMerchantsCategory
 * @param data.merchantId The merchant being removed from a category
 * @param data.categoryId The category to remove a merchant from
 * @desc  Remove the association of given merchant to the given category (soft deletion, table entry will still exist)
 */

export const disassociateMerchantsCategory = data => {
  const url = `/merchants/${data.merchantId}/disassociate`;
  return postRequest(url, { basic_benefit_program_category_id: data.categoryId })
};

/**
 * @function getAssociatedMerchantCategories
 * @param merchantId
 * @desc  get associated categories
 */

export const getAssociatedMerchantCategories = merchantId => {
  const url = `/merchants/${merchantId}`;
  return getRequest(url)
};
