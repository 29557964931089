import React, { Component } from "react";
import {
  getPlaidCategories,
  associateCategory,
  disassociateCategory,
  getAssociatedCategories
} from "../../services";
import PlaidCategoriesView from "./PlaidCategoriesView";
import {
  getProp,
  showErrorNotification,
  showSuccessNotification
} from "../../helpers";
import AssociateCategoryModal from "./AssociateCategoryModal";

export default class PlaidCategoriesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: [],
      isProcessing: true,
      selectedKey: "",
      totalPages: 0,
      currentPage: 1,
      totalRecords: "",
      categories: [],
      showModal: false,
      categoryId: ""
    };

    this.getJoonPlaidCategories();
  }

  /**
   * @para plaidCategoryId
   * @description
   */
  getPlaidAssociatedCategories = plaidCategoryId => {
    this.setState({ isProcessing: true });
    getAssociatedCategories(plaidCategoryId)
      .then(response => {
        if (response.data.success) {
          const { data } = response.data;
          const categoryList = data.basic_benefit_program_categories;
          this.setState({
            categories: categoryList,
            isProcessing: false,
            showModal: true
          });
        } else {
          this.setState({
            categories: [],
            isProcessing: false,
            showModal: true
          });
        }
      })
      .catch(() => {
        this.setState({
          categories: [],
          isProcessing: false
        });
      });
  };

  /**
   * @function getJoonPlaidCategories
   * @description get plaid categories
   */

  getJoonPlaidCategories = () => {
    getPlaidCategories().then(
      response => {
        const getSuccess = getProp(["data", "success"]);
        if (getSuccess(response)) {
          const getCategoriesData = getProp(["data", "data"]);
          const plaidCategories = getCategoriesData(response);
          this.setState({ categoryList: plaidCategories, isProcessing: false });
        } else {
          this.setState({ categoryList: [], isProcessing: false });
        }
      },
      () => {
        this.setState({ categoryList: [], isProcessing: false });
      }
    );
  };

  /**
   * @function onSelectKey
   * @description select plaid category
   */

  onSelectKey = plaidCategory => {
    this.getPlaidAssociatedCategories(plaidCategory.id);
    this.setState({ selectedKey: plaidCategory });
  };

  /**
   * @function toggleModal
   * @description close modal
   */

  toggleModal = () => {
    this.setState({ showModal: false });
  };

  /**
   * @function handleAssociateCategory
   * @description associate category with plaid category
   */

  handleAssociateCategory = category => {
    const { selectedKey } = this.state;
    const params = { plaidCategoryId: selectedKey.id, categoryId: category.id };
    this.setState({ isProcessing: true, categoryId: category.id });
    associateCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getPlaidAssociatedCategories(selectedKey.id);
        showSuccessNotification("Category associated successfully");
      } else {
        showErrorNotification("There is some error in association.");
      }
    });
  };

  /**
   * @function handleDisassociateCategory
   * @description disassociate category with plaid category
   */

  handleDisassociateCategory = category => {
    const { selectedKey } = this.state;
    const params = { plaidCategoryId: selectedKey.id, categoryId: category.id };
    this.setState({ isProcessing: true, categoryId: category.id });
    disassociateCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getPlaidAssociatedCategories(selectedKey.id);
        showSuccessNotification("Category disassociated successfully");
      } else {
        showErrorNotification("There is some error in disassociation.");
      }
    });
  };

  render() {
    const {
      categoryList,
      isProcessing,
      selectedKey,
      categories,
      showModal,
      categoryId
    } = this.state;
    return (
      <div>
        <PlaidCategoriesView
          isProcessing={isProcessing}
          categoryList={categoryList}
          selectedKey={selectedKey}
          onSelectKey={this.onSelectKey}
        />
        <AssociateCategoryModal
          isProcessing={isProcessing}
          selectedKey={selectedKey}
          categories={categories}
          showModal={showModal}
          toggleModal={this.toggleModal}
          handleDisassociateCategory={this.handleDisassociateCategory}
          handleAssociateCategory={this.handleAssociateCategory}
          categoryId={categoryId}
        />
      </div>
    );
  }
}
