import React from "react";
import { Modal, Form, Input, Row, Col, Button } from "antd";

const FormItem = Form.Item;

export default class MerchantModal extends React.Component {
  /**
   * @function validateName
   * @param rules
   * @param value
   * @param callback
   */
  validateName = (rule, value, callback) => {
    if (value === "" || (value && value.trim() === "")) {
      callback(`Name required`);
    }

    callback();
  };

  render() {
    const {
      showAddMerchantModal,

      toggleAddMerchant,
      handleAddMerchant,
      form
    } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <Modal
          title={"Add Merchant"}
          visible={showAddMerchantModal}
          onCancel={toggleAddMerchant}
          footer={null}
        >
          <Form onSubmit={handleAddMerchant}>
            <Row type="flex" justify="space-between" gutter={16}>
              <Col span={12}>
                <FormItem label="Merchant name">
                  {getFieldDecorator("userName", {
                    rules: [
                      {
                        required: true,
                        validator: this.validateName
                      }
                    ]
                  })(<Input placeholder="Name" name="name" />)}
                </FormItem>
              </Col>
            </Row>

            <Row className="modal-footer-row">
              <Col span={24} className="text-right">
                <Button size="small" onClick={toggleAddMerchant}>
                  {"Cancel"}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="small"
                  className="ml-8"
                >
                  {"Add"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
