import { deleteRequest, getRequest, putRequest } from "./index";

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getTransactions = (page, filter, searchText, employeeId = "") => {
  let url = '';
  if (filter !== "") {
    url += `/transactions?search_text=${searchText}&transaction_status=${filter}&page=${page}&perpage=10&user_id=${employeeId}`;
  }
  return getRequest(url)
};

/**
 * @function getErrors
 * @desc employers list
 */
export const getPlaidAccountErrors = (page, searchText) => {
  let url = `/plaid_errors?page=${page}&perpage=10`;
  if (searchText) {
    url = `${url}&search_text=${searchText}`;
  }
  return getRequest(url)
};

/**
 * @function getErrors
 * @desc employers list
 */
export const getStripeErrors = (page, searchText) => {
  let url = `/stripe_errors?page=${page}&perpage=10`;
  if (searchText) {
    url = `${url}&search_text=${searchText}`;
  }
  return getRequest(url)
};

/**
 * @function getErrors
 * @desc employers list
 */
export const getPlaidTransactionErrors = (page, searchText) => {
  let url = `/transaction_accounts/plaid_errors?page=${page}&perpage=10`;
  if (searchText) {
    url = `${url}&search_text=${searchText}`;
  }
  return getRequest(url)
};

/**
 * @function getErrors
 * @desc employers list
 */
export const getTransactionErrors = page => {
  const url = `/transaction_accounts/plaid_errors?page=${page}&perpage=10`;
  return getRequest(url)
};

/**
 * @function refreshTransactions
 * @desc This refreshes the employee.
 */
export const updateTransaction = (transactionId, transactionStatus) => {
  const url = `/transactions/${transactionId}`;
  return putRequest(url, transactionStatus)
};

/**
 * @function getPdf
 * @param url
 * @desc This function gets the attachment file of selected order
 */
export const getPdf = url => {
  return getRequest(url)
}

/**
 * @function deleteTransaction
 * @param "employerId", "employeeId"
 * @desc  api to delete employee
 */

export const deleteTransaction = (transactionId, employeeId) => {
  const url = `/manual_transactions/${transactionId}?employee_id=${employeeId}`;
  return deleteRequest(url)
};
