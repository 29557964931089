import React from "react";
import { Modal, Select } from "antd";

const { Option } = Select;
export default class RefreshTransactionsModal extends React.Component {
  state = {
    selectedMonth: "current"
  };

  /**
   * @function handleChange
   * @param value
   * @description it will change selected value
   */
  handleChange = value => {
    this.setState({
      selectedMonth: value
    });
  };

  /**
   * @function handleSubmit
   * @description it will handle refresh trans
   */
  handleSubmit = () => {
    const { selectedMonth } = this.state;
    this.props.handleRefreshTransaction(selectedMonth);
  };

  render() {
    const { showRefreshModal, toggleRefreshModal } = this.props;

    return (
      <div>
        <Modal
          title="Refresh transactions for:"
          visible={showRefreshModal}
          onCancel={toggleRefreshModal}
          onOk={this.handleSubmit}
          className="small-modal"
          okText="Refresh"
        >
          <Select
            onChange={this.handleChange}
            className=""
            defaultValue={this.state.selectedMonth}
          >
            <Option value="prev">Prev month</Option>
            <Option value="current">Current month</Option>
          </Select>
        </Modal>
      </div>
    );
  }
}
