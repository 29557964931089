import { getRequest, postRequest, putRequest, deleteRequest } from "./index";

/**
 * @function getCategories
 * @desc  get categories
 */

export const getCategories = (currentPage, perPage) => {
  let url = '';

  if (perPage) {
    url += `/basic_benefit_program_categories?page=${currentPage}&perpage=30`;
  } else {
    url += `/basic_benefit_program_categories?page=${currentPage}&perpage=10`;
  }

  return getRequest(url)
};

/**
 * @function addCategory
 * @desc  add categories
 */

export const addCategory = data => {
  const url = '/basic_benefit_program_categories';
  return postRequest(url, data)
};

/**
 * @function updateCategory
 * @desc  update categories
 */

export const updateCategory = data => {
  const url = `/basic_benefit_program_categories/${data.id}`;
  return putRequest(url)
};

/**
 * @function deleteCategory
 * @desc  delete categories
 */

export const deleteCategory = data => {
  const url = `/basic_benefit_program_categories/${data.id}`;
  return deleteRequest(url)
};

/**
 * @function getPlaidCategories
 * @desc  get plaid categories
 */

export const getPlaidCategories = () => {
  const url = '/plaid_categories';
  return getRequest(url)
};

/**
 * @function associateCategory
 * @desc  associate categories
 */

export const associateCategory = data => {
  const url = `/plaid_categories/${data.plaidCategoryId}/associate`;
  return postRequest(url, { basic_benefit_program_category_id: data.categoryId })
};

/**
 * @function disassociateCategory
 * @desc  disassociate categories
 */

export const disassociateCategory = data => {
  const url = `/plaid_categories/${data.plaidCategoryId}/disassociate`;
  return postRequest(url, { basic_benefit_program_category_id: data.categoryId })
};

/**
 * @function getAssociatedCategories
 * @desc  get associated categories
 */

export const getAssociatedCategories = plaidCategoryId => {
  const url = `/plaid_categories/${plaidCategoryId}`;
  return getRequest(url)
};
