import React, { Component } from "react";
import { Form, message } from "antd";
import moment from "moment";
import EmployeesView from "./EmployeesView";
import {
  getEmployeesList,
  reinviteEmployee,
  cancelInvites,
  inviteEmployee,
  inviteEmployeesCSV,
  refreshTransactions,
  deleteEmployee,
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  history,
} from "../../helpers";
import InviteEmployeesModal from "./InviteEmployeesModal";
import InviteBulkEmployeesModal from "../InviteBulkEmployees/InviteBulkEmployeesModal";
import ConfirmationModalView from "./ConfirmationModalView";
import RefreshTransactionsModal from "../Transactions/RefreshTransactionsModal";
class EmployeesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalProcessing: false,
      loading: false,
      totalPages: 0,
      currentPage: 1,
      employeesList: [],
      showModal: false,
      email: "",
      totalRecords: "",
      searchText: "",
      employerId: "",
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      showBulkModal: false,
      file: null,
      fileList: [],
      showEmployeeModal: false,
      showDeleteEmployeeConfirmModal: false,
      employeeId: null,
      showRefreshModal: false,
      name: "",
    };
  }

  componentDidMount() {
    const { id } = this.props;
    const { selectedFilter } = this.state;
    // if (id && id !== "" && id !== prevProps.id) {
    this.setState({ employerId: id }, () => {
      this.getEmployees(1, selectedFilter, "");
    });
    // }
  }

  /**
   * @function getEmployees
   * @para currentpage
   * @param filter
   * @description it will take the filter and current page and return employers list
   */
  getEmployees = (currentPage, selectedFilter, searchText) => {
    const { employerId } = this.state;
    this.setState({ isProcessing: true, employeesList: [] });
    getEmployeesList(employerId, currentPage, selectedFilter, searchText)
      .then((response) => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { total_pages, total_records } = response.data.data;
          const totalPages = total_pages;
          const totalRecords = total_records;
          this.setState({
            employeesList: response.data.data.users,
            totalPages,
            totalRecords,
          });
        } else {
          this.setState({ employeesList: [], totalPages: 0, totalRecords: 0 });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = (page) => {
    const { selectedFilter, searchText } = this.state;
    this.setState({ currentPage: page });
    this.getEmployees(page, selectedFilter, searchText);
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openBulkEmployeeModal = () => {
    this.setState({ showBulkModal: true });
  };

  /**
   * @function toggleBulkModal
   * @description it will close the modal from modal cancel button
   */
  toggleBulkModal = () => {
    const { showBulkModal } = this.state;
    this.setState({
      showBulkModal: !showBulkModal,
    });
  };

  /**
   * @function toggleBulkModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  selectEnabled = (benefitPrograms) => {
    let filteredPrograms = [];
    benefitPrograms.forEach((benefitProgram) => {
      if (benefitProgram.enabled == true && benefitProgram.scheduled_start_date != undefined) {
        benefitProgram.scheduled_start_date = this.antDateToRealDate(benefitProgram.scheduled_start_date);
        filteredPrograms.push(benefitProgram);
      }
    })
    return filteredPrograms;
  };

  antDateToRealDate = (antDate) => {
    const invDate = new Date(antDate.toString());
    return invDate.getFullYear() +
      "-" + ('0' + (invDate.getMonth() + 1)).slice(-2) +
      "-" + ('0' + invDate.getDate()).slice(-2);
  }

  handleInviteEmployer = (e) => {
    const { form } = this.props;

    const { employerId, selectedFilter, searchText, currentPage } = this.state;
    e.preventDefault();

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.setState({ showModal: false, isProcessing: true });
        const employeeData = {
          employees: [{
            email: value.email,
            name: value.name,
            invite_date: this.antDateToRealDate(value.date),
            role_id: value.role_id,
            benefit_programs: this.selectEnabled(value.benefit_programs),
          }]
        };
        inviteEmployee(employerId, employeeData)
          .then((response) => {
            form.resetFields();
            this.setState({ isProcessing: false });
            if (response.data.results.length == 1) {
              const successMsg = "Employee invited successfully.";
              showSuccessNotification(successMsg);

              this.getEmployees(currentPage, selectedFilter, searchText);
            } else {
              const error = response.data.errors[0];
              const email = Object.keys(error)[0];
              const errorMsg = error[email];

              showErrorNotification("Could not invite " + email + ": " + errorMsg);
            }
          })
          .catch(() => {
            this.setState({ isProcessing: false });
          });
      };
    });
  };

  /**
   * @function reinviteEmployees
   * @param inviteId
   * @description it will reinvite the employee
   */
  reinviteEmployees = (userId) => {
    const { employerId, selectedFilter, currentPage, searchText } = this.state;
    this.setState({ isProcessing: true });
    reinviteEmployee(userId)
      .then((response) => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const successMsg = "Employee re-invited successfully.";
          showSuccessNotification(successMsg);

          this.getEmployees(currentPage, selectedFilter, searchText);
        } else {
          const { errors } = response.data;
          const error = errors.title;

          showErrorNotification(error);
        }
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function cancelInvite
   * @param inviteId
   * @description it will cancel the invite of employer
   */
  cancelInvite = (userId) => {
    const { employerId, selectedFilter, currentPage, searchText } = this.state;
    this.setState({ isProcessing: true });
    cancelInvites(userId)
      .then((response) => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const successMsg = "Employee invite cancelled successfully.";
          showSuccessNotification(successMsg);

          this.getEmployees(currentPage, selectedFilter, searchText);
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch((response) => {
        const { errors } = response.data;
        const error = errors.title;

        showErrorNotification(error);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = (value) => {
    const page = 1;
    this.setState({ selectedFilter: value, currentPage: 1 }, () => {
      this.getEmployees(page, value, "");
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText, selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.setState({ currentPage: 1 }, () =>
        this.getEmployees(1, selectedFilter, searchText)
      );
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /**
   * @function handleFileChange
   * @params file
   * @description This will handle file change
   */
  handleFileChange = (file) => {
    const fileList = [];
    fileList.push(file);
    this.setState({
      file,
      fileList,
    });
    return false;
  };

  /**
   * @function handleRemoveFile
   * @description This will handle remove file
   */
  handleRemoveFile = () => {
    this.setState({
      file: null,
      fileList: [],
    });
  };

  /**
   * @function handleFileUpload
   * @params file
   * @description This will handle file change
   */
  handleFileUpload = () => {
    const { file, selectedFilter } = this.state;
    const { id } = this.props;
    this.setState({
      loading: true,
    });
    const formData = new FormData();
    formData.append("file", file);
    inviteEmployeesCSV(id, formData).then((res) => {
      if (res.data.success) {
        this.toggleBulkModal();
        message.success("File uploaded successfully.");
        this.setState(
          {
            loading: false,
            file: null,
            fileList: [],
            currentPage: 1,
          },
          () => {
            this.getEmployees(1, selectedFilter, "");
          }
        );
      } else {
        message.success("Problem uploading file, please check format or contact support");
      }
    });
  };

  handleEmployeeSelect = (row, e) => {
    const { employerId } = this.state;
    const isAdmin = row.has_administrative_permission === null
      ? false
      : row.has_administrative_permission

    history.push(
      `/employers/${employerId}/employees/${row.id}/profile`
    );
  };

  toggleEmployeeModal = () => {
    this.setState({
      showEmployeeModal: !this.state.showEmployeeModal,
    });
  };

  /**
   * @function refreshTransactions
   * @params empId
   * @description This will handle refresh transactions
   */
  refreshTransactions = (userId) => {
    this.setState({
      isProcessing: true,
      showRefreshModal: false,
    });
    refreshTransactions(userId).then((res) => {
      if (res.data.success === true) {
        message.success(res.data.message);
      } else {
        message.error(res.data.errors.join());
      }
      this.setState({
        isProcessing: false,
      });
    });
  };

  /**
   * @function removeEmployee
   * @params e
   * @description This will handle employee deletion
   */
  removeEmployee = (e) => {
    e.stopPropagation();
    const {
      employerId,
      currentPage,
      selectedFilter,
      searchText,
      employeeId,
    } = this.state;
    this.setState({
      isProcessing: true,
    });
    deleteEmployee(employerId, employeeId)
      .then((res) => {
        if (res.data.success === true) {
          message.success(res.data.message);
          this.setState({ showDeleteEmployeeConfirmModal: false });
          this.getEmployees(currentPage, selectedFilter, searchText);
        } else {
          message.error(res.data.errors.title);
        }
        this.setState({
          isProcessing: false,
        });
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function toggleRemoveEmployeeConfirmation
   * @params "empObj", "e"
   * @description This will handle employee deletion confirmation and setting employee id who is to be removed
   */
  toggleRemoveEmployeeConfirmation = (empObj = null, e) => {
    if (e) {
      e.stopPropagation();
    }

    const { showDeleteEmployeeConfirmModal } = this.state;
    this.setState({
      showDeleteEmployeeConfirmModal: !showDeleteEmployeeConfirmModal,
      employeeId: empObj ? empObj.id : null,
    });
  };

  /**
   *  @function toggleRefreshModal
   * @desc - toggles the transaction refresh modal window
   */
  toggleRefreshModal = (empId, e) => {
    if (empId) {
      this.setState({
        employeeId: empId,
      });
    }
    this.setState({ showRefreshModal: !this.state.showRefreshModal });
    if (e) {
      e.stopPropagation();
    }
  };

  render() {
    const {
      filteredInfo,
      sortedInfo,
      employeesList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      showModal,
      email,
      name,
      showBulkModal,
      isModalProcessing,
      totalRecords,
      searchText,
      fileList,
      loading,
      showDeleteEmployeeConfirmModal,
      showRefreshModal,
    } = this.state;
    const { id, form } = this.props;

    return (
      <div>
        <EmployeesView
          openBulkEmployeeModal={this.openBulkEmployeeModal}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onchangeSearch={this.handleChange}
          cancelInvites={this.cancelInvite}
          reinviteEmployees={this.reinviteEmployees}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          employeesList={employeesList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
          handleEmployeeSelect={this.handleEmployeeSelect}
          toggleRemoveEmployeeConfirmation={
            this.toggleRemoveEmployeeConfirmation
          }
          toggleRefreshModal={this.toggleRefreshModal}
        />
        <InviteEmployeesModal
          isModalProcessing={isModalProcessing}
          form={form}
          showModal={showModal}
          handleInviteEmployer={this.handleInviteEmployer}
          toggleModal={this.toggleModal}
          email={email}
          name={name}
          employerId={id}
        />
        <InviteBulkEmployeesModal
          toggleBulkModal={this.toggleBulkModal}
          showBulkModal={showBulkModal}
          loading={loading}
          fileList={fileList}
          handleFileChange={this.handleFileChange}
          handleFileUpload={this.handleFileUpload}
          handleRemoveFile={this.handleRemoveFile}
        />

        {showDeleteEmployeeConfirmModal && (
          <ConfirmationModalView
            showModal={showDeleteEmployeeConfirmModal}
            modalTitle="Delete Employee"
            modalContent="Are you sure you want to remove this employee?"
            toggleModal={this.toggleRemoveEmployeeConfirmation}
            handleOkButton={this.removeEmployee}
            noBtnText="No"
            yesBtnText="Yes"
          />
        )}
        <RefreshTransactionsModal
          toggleRefreshModal={this.toggleRefreshModal}
          showRefreshModal={showRefreshModal}
          handleRefreshTransaction={this.refreshTransactions}
        />
      </div>
    );
  }
}
const employeeForm = Form.create()(EmployeesContainer);
export default employeeForm;
