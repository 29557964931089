export const getProp = p => o =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

/**
 * @function saveEmployerId
 * @param {*} employerId
 * @description it will save the user data in local storage
 */
export const saveEmployerId = employerId => {
  localStorage.setItem("employer_id", `${employerId}`);
};

export const Currency = (number, config) =>
  Number(number).toLocaleString("en-US", {
    style: "currency",
    currency: "usd",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...config
  });

/**
 * @function getCurrentTabName
 * @params pathName
 * @description It will return the current active tab
 */
export const getCurrentTabName = pathName => {
  let currentPageParamsArr = [];
  // pathName = employers/201/employees/203/profile
  // fetch last param from url : like "profile"
  currentPageParamsArr = pathName.split("/");
  return currentPageParamsArr[currentPageParamsArr.length - 1];
};
