import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Menu } from "antd";
import "./body.css";
import { navigationAction } from "./Navigation";
import { menuClick } from "../../helpers";

const { Sider } = Layout;
const { SubMenu } = Menu;

/**
 * @function selectCurrentPage
 * @return pageNumber
 * @desc This function handles showing active page, if dashboard is selected then pageNumber will be 1,
 * else 2
 */
const selectCurrentPage = activePage => {
  let pageNumber = "";
  switch (activePage) {
    case "request-a-demo":
      pageNumber = "3";
      break;
    case "transactions":
      pageNumber = "2";
      break;
    case "employers":
    case "employer-details":
    case "employee-detail":
      pageNumber = "1";
      break;
    case "joon-categories":
      pageNumber = "5_1";
      break;
    case "plaid-categories":
      pageNumber = "5_2";
      break;
    case "merchants":
      pageNumber = "5_3";
      break;
    case "book-keeper":
      pageNumber = "4";
      break;
    case "errors":
      pageNumber = "6";
      break;
    default:
      break;
  }
  return pageNumber;
};

class LeftNav extends Component {
  rootSubmenuKeys = ["settings"];

  state = {
    openKeys: [],
    selectedPage: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.currentPage !== prevState.selectedPage) {
      return { selectedPage: selectCurrentPage(nextProps.currentPage) };
    }

    return null;
  }

  componentDidMount() {
    const activeItem = this.props.currentPage;

    // if the current page props have any _ in it
    // we have to open the submenu also
    const selectedPage = selectCurrentPage(activeItem);
    this.setState({
      selectedPage,
      openKeys:
        activeItem === "joon-categories" || activeItem === "plaid-categories"
          ? ["settings"]
          : []
    });
  }

  /**
   * @function onMenuClick
   * @desc This function handle menu link click
   */
  onMenuClick = e => {
    if (this.props.visible) {
      this.props.closeDrawer();
    }
    menuClick(e, this.props.navigateTo);
  };

  /**
   * @function onOpenChange
   * @desc handles open and close submenu
   */
  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(
      key => this.state.openKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  render() {
    const { selectedPage } = this.state;

    return (
      <Sider width={200} style={{ background: "#fff" }}>
        <Menu
          className="left-nav"
          mode="inline"
          selectedKeys={[selectedPage]}
          style={{ height: "100%", borderRight: 0 }}
          onOpenChange={this.onOpenChange}
        >
          <Menu.Item key="1">
            <Link to="/employers">Employers</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/transactions/reported-transactions">Transactions</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/errors/plaid-account-errors">Errors</Link>
          </Menu.Item>
          <SubMenu
            key="settings"
            title={
              <React.Fragment>
                <span>Settings</span>
              </React.Fragment>
            }
          >
            <Menu.Item key="5_1">
              <Link to="/joon-categories">JOON Categories</Link>
            </Menu.Item>
            <Menu.Item key="5_2">
              <Link to="/plaid-categories">Plaid Categories</Link>
            </Menu.Item>
            <Menu.Item key="5_3">
              <Link to="/merchants">Merchants</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="4">
            <Link to="/reports">Reports</Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: (page, tab) => dispatch(navigationAction(page, tab))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftNav);
