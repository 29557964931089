import React from "react";
import { Button, Icon } from "antd";
import { allEmployeesReport } from "../../services";
import FileSaver from "file-saver";

class JoonReports extends React.Component {
  getReports = () =>
    allEmployeesReport().then(
      response => {
        if (response) {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });

          const fileName = "all.xls"
          FileSaver.saveAs(blob, fileName, true);
        }
      }
    );

  render() {
    return (
      <p>
        <Button
          shape="circle"
          type="primary"
          size="small"
          onClick={() => this.getReports()}
        >
          <Icon type="download"/>
        </Button>
        --> export all non-deleted / non-deactivated roster members from the platform
      </p>
    );
  }
}

export default JoonReports;