import moment from "moment";
import { getRequest, postRequest, deleteRequest, putRequest } from './index'

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getEmployersList = (page, filter, searchText) => {
  let url = "";

  if (filter !== "") {
    url += `/employers?search_text=${searchText}&invite_status=${filter}&page=${page}&perpage=10`;
  }
  if (searchText !== "") {
    url += `/employers?search_text=${searchText}&invite_status=${filter}&page=1&perpage=10`;
  }

  return getRequest(url)
};

/**
 * @function inviteEmployer
 * @desc  invite employer
 */

export const inviteEmployer = data => {
  const url = '/employers/invites';
  return postRequest(url, data)
};

/**
 * @function inviteEmployeesCSV
 * @desc  invite employees in bulk
 */

export const inviteEmployeesCSV = (id, data) => {
  const url = `/employers/${id}/users/invites/bulk_invite`;
  return postRequest(url, data)
};

/**
 * @function cancelInviteEmployer
 * @desc  it will cancel the icited employer
 */

export const cancelInviteEmployer = inviteId => {
  const url = `/employers/invites/${inviteId}/cancel`;
  return postRequest(url)
};

/**
 * @function deleteJoonEmployer
 * @desc  it will delete the employer
 */

export const deleteJoonEmployer = employerId => {
  const url = `/employers/${employerId}`;
  return deleteRequest(url)
};

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getEmployerDetails = employerId => {
  const url = `/employers/${employerId}`;
  return getRequest(url)
};

/**
 * @function getEmployersBenefitProgram
 * @param employerId
 * @desc employers benefit program
 */

export const getEmployersBenefitProgram = employerId => {
  const url = `/employers/${employerId}/benefit_program_categories`;
  return getRequest(url)
};

/**
 * @function enableCategoryToEmployersBenefitProgram
 * @param employerId
 * @desc add category to employers benefit program
 */

export const enableCategoryToEmployersBenefitProgram = (employerId, catId) => {
  const url = `/employers/${employerId}/benefit_program_categories/enable`;
  return postRequest(url, { basic_benefit_program_category_id: catId })
};

/**
 * @function disableCategoryFromEmployersBenefitProgram
 * @param employerId
 * @desc add category to employers benefit program
 */

export const disableCategoryFromEmployersBenefitProgram = (
  employerId,
  catId
) => {
  const url = `/employers/${employerId}/benefit_program_categories/disable`;
  return postRequest(url, { basic_benefit_program_category_id: catId })
};

/**
 * @function addCategoryToEmployersBenefitProgram
 * @param employerId
 * @desc add category to employers benefit program
 */

export const addCategoryToEmployersBenefitProgram = (employerId, catId) => {
  const url = `/employers/${employerId}/benefit_program_categories/add`;
  return postRequest(url, { basic_benefit_program_category_id: catId })
};

/**
 * @function removeCategoryFromEmployersBenefitProgram
 * @param employerId
 * @desc add category to employers benefit program
 */

export const removeCategoryFromEmployersBenefitProgram = (
  employerId,
  catId
) => {
  const url = `/employers/${employerId}/benefit_program_categories/remove`;
  return postRequest(url, { basic_benefit_program_category_id: catId })
};

/**
 * @function addCategoryToEmployersBenefitProgram
 * @param employerId
 * @desc add category to employers benefit program
 */

export const updateEmployerDetails = (employerId, data) => {
  const url = `/employers/${employerId}`;
  return putRequest(url, data)
};

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getRequestADemoList = (page, filter, searchText) => {
  let url = "";
  if (filter !== "") {
    url += `/employer_access_requests?search_text=${searchText}&request_status=${filter}&page=${page}&perpage=10`;
  }
  return getRequest(url)
};

/**
 * @function getEmployersList
 * @desc employers list
 */
export const getEmployerRequestDetails = listId => {
  const url = `/employer_access_requests/${listId}`;
  return getRequest(url)
};

/**
 * @function getEmployersList
 * @desc employers list
 */
export const updateEmployerRequestDetails = (listId, data) => {
  const url = `/employer_access_requests/${listId}`;
  return putRequest(url, data)
};

/**
 * @function generateInitialCharge
 * @param employerId
 * @desc employer initial charge
 */
export const generateInitialCharge = employerId => {
  const url = `/employers/${employerId}/invoices/initial_charge`;
  return postRequest(url)
};

/**
 * @function getReports
 * @param "employerId", "month", "year", "selectedReportType"
 * @desc This service allows fetching employee roster
 */
export const getReports = (
  employerId,
  firstDateParam,
  secondDateParam,
  selectedReportType
) => {
  let url = "";
  // Benefit recipients overview
  if (selectedReportType === 1) {
    url = `/employers/${employerId}/users/reports/benefit_recipients_overview?month=${firstDateParam}&year=${secondDateParam}`;
  }
  if (selectedReportType === 2) {
    url = `/employers/${employerId}/users/reports/purchases_report?date=${firstDateParam}`;
  }
  if (selectedReportType === 3) {
    url = `/employers/${employerId}/users/reports/year_reimbursement_report`;
  }
  if (selectedReportType === 4) {
    const year = moment().subtract(1, 'years').format('YYYY')
    url = `/employers/${employerId}/users/reports/year_reimbursement_report?year=${year}`;
  }

  return getRequest(url)
};

export const allEmployeesReport = () => {
  const url = '/admin/employees/report/all_employees';
  return getRequest(url)
}
