import React from "react";
import {
  Table,
  Pagination,
  Input,
  Row,
  Col,
  Form,
  Button,
  Spin,
} from "antd";
import moment from "moment";
const FormItem = Form.Item;

const StripeErrorsView = props => {
  const {
    errorList,
    isProcessing,
    totalRecords,
    currentPage,
    onChangePage,
    searchText,
    onChangeSearch,
    handleSearch,
    toggleMetaErrorModal
  } = props;

  const columns = [
    {
      title: "Employee Email",

      render: record => {
        return (
          <div>
            {record &&
              record.employer_details &&
              record.employer_details.employee_email && (
                <div>{record && record.employee_email}</div>
              )}
          </div>
        );
      }
    },
    {
      title: "Employer Email",
      render: record => {
        return (
          <div>
            {record &&
              record.employer_details &&
              record.employer_details.employer_email && (
                <div>{record.employer_details.employer_email}</div>
              )}
            {record &&
              record.employee_details &&
              record.employee_details.employee_email && (
                <div>{record.employee_details.employee_email}</div>
              )}
          </div>
        );
      }
    },
    {
      title: "Employer Name",

      render: record => {
        return (
          <div>
            {record &&
              record.employer_details &&
              record.employer_details.user_name && (
                <div>{record && record.employer_details.user_name}</div>
              )}
          </div>
        );
      }
    },

    {
      title: "Error Type",
      dataIndex: "error_type",
      render: record => {
        return <div>{record && record}</div>;
      }
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: record => {
        return (
          <div>
            {record && <div>{moment.utc(record).format("DD MMM, YYYY")}</div>}
          </div>
        );
      }
    },

    {
      title: "",
      // width: "200px",
      render: record => {
        return (
          <div>
            {record && (
              <Row type="flex" justify="space-between" flexDirection="row">
                <Col className="my-5">
                  <Button
                    onClick={() =>
                      toggleMetaErrorModal(record.error_message, record.doc_url)
                    }
                    type="primary"
                    size="small"
                  >
                    Error Message
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        );
      }
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col>
                <FormItem>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        name="searchText"
                        placeholder="Search by error code, name, error type etc."
                        value={searchText}
                        onChange={onChangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>
            </Row>
          </div>
          {errorList !== undefined && errorList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={errorList}
                pagination={false}
                rowKey="id"
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
              <div className="blank-View">
                {!isProcessing && (
                  <Row>
                    <Col span={24}>
                      <FormItem>
                        <p>There are no plaid transaction errors.</p>
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default StripeErrorsView;
