import React, { Component } from "react";
import { Form, message, Modal } from "antd";
import moment from "moment";
import _ from "lodash";
import TransactionsView from "./TransactionsView";
import {
  getTransactions,
  getEmployersBenefitProgram,
  saveTransaction,
  deleteTransaction,
  refreshTransactions,
  getPdf,
  adminUpdateTransaction,
  addBulkPurchases
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  getProp
} from "../../helpers";
import AddTransactionModal from "./AddTransactionModal";
import ConfirmationModalView from "../Employees/ConfirmationModalView";
import TransactionDetailModal from "./TransactionDetailModal";
import TransactionHistoryModal from "./TransactionHistoryModal";
import PreviewReceiptModal from "../Reportedtransactions/PreviewReceiptModal";
import ValidateTransactionModal from "./ValidateTransactionModal";
import AddBulkPurchasesModal from "../AddBulkPurchases/AddBulkPurchasesModal";
import ErrorsModal from "../Errors/ErrorsModal"

let filesList = [];

class TransactionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionHistory: [],
      showHistoryModal: false,
      totalPages: 0,
      currentPage: 1,
      perPage: localStorage.getItem("perPage")
        ? parseInt(localStorage.getItem("perPage"))
        : 10,
      transactionsList: [],
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      showModal: false,
      totalRecords: "",
      searchText: "",
      employeeId: "",
      showAddTransactionModal: false,
      showValidateTransactionModal: false,
      benefitPrograms: [],
      employerId: "",
      transactionId: "",
      showDeleteTransactionConfirmModal: false,
      selectedBenefitCategoryId: "",
      showDetailModal: false,
      transactionDetail: {},
      loadingPreview: false,
      uploadingFile: false,
      openReceiptPreviewModal: false,
      previewFileType: "",
      fileName: "",
      attachmentData: "",
      fileList: [],
      disabledUploadBtn: false,
      isProcessing: false,
      showBulkPurchasesModal: false
    };
  }

  componentDidMount() {
    filesList = [];
    const { currentPage, selectedFilter } = this.state;
    const { fromEmployeeDetail } = this.props;

    if (!fromEmployeeDetail) {
      this.getTransactionsList(currentPage, selectedFilter, "", "");
    }
  }

  componentWillReceiveProps(prevProps) {
    const { currentPage, selectedFilter } = this.state;
    const { employeeId, employerId } = this.props;

    if (prevProps.employeeId !== employeeId) {
      this.setState({ employeeId: prevProps.employeeId });
      this.getTransactionsList(
        currentPage,
        selectedFilter,
        "",
        prevProps.employeeId
      );
    }

    if (prevProps.employerId !== employerId) {
      this.setState({ employerId: prevProps.employerId });
      this.getCategories(prevProps.employerId);
    }
  }

  /**
   * @function getCategories
   */
  getCategories = async employerId => {
    const response = await getEmployersBenefitProgram(employerId)

    const getSuccess = getProp(["data", "success"]);
    if (getSuccess(response)) {
      const getPrograms = getProp([
        "data",
        "data",
        "benefit_programs"
      ]);
      const programs = getPrograms(response);
      this.setState({ benefitPrograms: programs });
      return programs
    } else {
      this.setState({ benefitPrograms: [] });
    }
    return []
  };

  /**
   * @function getTransactionsList
   * @param currentPage
   * @param filter
   * @param searchText
   * @param employeeId
   * @description it will take the filter and current page and return employers list
   */
  getTransactionsList = (currentPage, filter, searchText, employeeId) => {
    const { perPage } = this.state;
    this.setState({ isProcessing: true, transactionsList: [] });
    getTransactions(currentPage, filter, searchText, employeeId, perPage)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          // eslint-disable-next-line camelcase
          const { total_pages, total_records, data } = response.data;
          const { transactions } = data;
          const transactionsList = transactions;
          // eslint-disable-next-line camelcase
          const totalPages = total_pages;
          // eslint-disable-next-line camelcase
          const totalRecords = total_records;
          this.setState({ transactionsList, totalPages, totalRecords });
        } else {
          this.setState({
            transactionsList: [],
            totalPages: 0,
            totalRecords: 0
          });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = value => {
    const page = 1;
    const { employeeId } = this.state;
    this.setState(
      { selectedFilter: value, currentPage: page, searchText: "" },
      () => {
        this.getTransactionsList(page, value, "", employeeId);
      }
    );
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    const { selectedFilter, searchText, employeeId } = this.state;
    this.setState({ currentPage: page });
    this.getTransactionsList(page, selectedFilter, searchText, employeeId);
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function toggleReferralModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  refreshTransactions = () => {
    const { employeeId } = this.state;
    this.setState({
      isProcessing: true
    });
    refreshTransactions(employeeId).then(({ data }) => {
      const message = data.success ? data.message : data.errors.join()
      this.setState({
        showPlaidLinkModal: true,
        plaidTokenCheckMessage: message,
        isProcessing: false
      })
    });
  };

  closePlaidTokenModal = () => {
    this.setState({ showPlaidLinkModal: false, plaidTokenCheckMessage: "" })
  }

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText, selectedFilter, employeeId } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.setState({ currentPage: 1 }, () => {
        this.getTransactionsList(1, selectedFilter, searchText, employeeId);
      });
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /**
   * @function updateTransactions
   * @description it updates the transactions.
   */
  updateTransaction = (transactionId, valid, benefitProgramId, reload = true) => {
    const { selectedFilter, searchText, currentPage, employeeId } = this.state;
    this.setState({ isProcessing: true });
    const payload = {
      valid,
      benefit_program_id: benefitProgramId
    };
    adminUpdateTransaction(transactionId, payload)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          let successMsg = "";
          if (valid) {
            successMsg = "Transactions validated successfully.";
          } else {
            successMsg = "Transactions in-validated successfully.";
          }

          showSuccessNotification(successMsg);

          if (reload) {
            this.getTransactionsList(
              currentPage,
              selectedFilter,
              searchText,
              employeeId
            )
          } else {
            this.updateTransactionValid(valid, benefitProgramId)
          }
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch(response => {
        const getError = getProp(["response", "data", "message", "title"]);
        const errorMsg = getError(response) || "There is some error!";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function toggleAddTransactionModal
   * @description it toggles "Add transaction modal"
   */
  toggleAddTransactionModal = () => {
    const { showAddTransactionModal, benefitPrograms } = this.state;
    filesList = [];

    if (benefitPrograms.length > 0) {
      this.setState({
        showAddTransactionModal: !showAddTransactionModal,
        fileList: []
      });
    } else {
      Modal.info({
        title: "Add benefit categories",
        content: (
          <div>
            <p>Please add benefit categories to continue adding transactions</p>
          </div>
        ),
        onOk() {
        }
      });
    }
  };

  /**
   * @function toggleValidateTransactionModal
   * @description it toggles "Add transaction modal"
   */
  toggleValidateTransactionModal = async (transactionId, employerId) => {
    const { showValidateTransactionModal } = this.state

    this.setState({ transactionId: transactionId })

    const benefitPrograms = await this.getCategories(employerId)

    if (benefitPrograms.length > 0 || showValidateTransactionModal) {
      this.setState({ showValidateTransactionModal: !showValidateTransactionModal })
    } else {
      Modal.info({
        title: "Add benefit categories",
        content: (
          <div>
            <p>Please add benefit categories to continue adding transactions</p>
          </div>
        ),
        onOk() {
        }
      })
    }
  }

  /**
   * @function handleSaveTransaction
   * @description it handles saving and updating transaction
   */
  handleSaveTransaction = e => {
    const { form } = this.props;
    const {
      selectedFilter,
      searchText,
      currentPage,
      employeeId,
      transactionId,
      fileList
    } = this.state;
    const { fromEmployeeDetail } = this.props;
    e.preventDefault();
    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        const formData = new FormData();

        fileList.forEach((file, index) => {
          formData.append(`file${index}`, file);
        });

        this.setState({ isProcessing: true });
        formData.append(`user_id`, parseInt(employeeId, 10));
        formData.append(`name`, value.transactionName);
        formData.append(`amount`, parseFloat(value.amount));
        formData.append(`payment_meta`, value.description);
        formData.append(
          `benefit_program_id`,
          parseInt(value.benefitProgram, 10)
        );
        formData.append(
          `basic_benefit_program_category_id`,
          parseInt(value.benefitCategory, 10)
        );
        formData.append(
          `transaction_date`,
          moment(value.transactionDate).format("YYYY-MM-DD")
        );
        formData.append(`transaction_id`, transactionId);

        // save the transaction detail
        saveTransaction(formData)
          .then(response => {
            this.setState({ isProcessing: false });
            if (response && response.data.success) {
              // show success and reload transactions
              let successMsg = "";
              if (transactionId) {
                successMsg = `Transaction updated successfully.`;
              } else {
                successMsg = "Transaction saved successfully.";
              }
              showSuccessNotification(successMsg);
              this.setState({ showAddTransactionModal: false });

              let employeeIdFilter = employeeId;
              if (!fromEmployeeDetail) {
                employeeIdFilter = "";
              }

              this.getTransactionsList(
                currentPage,
                selectedFilter,
                searchText,
                employeeIdFilter
              );
            } else {
              const { errors } = response.data;
              const error = errors.title;
              showErrorNotification(error);
            }
          })
          .catch(err => {
            const getError = getProp(["response", "data", "message", "title"]);
            const errorMsg = getError(err) || "There is some error!";
            showErrorNotification(errorMsg);
            this.setState({
              isProcessing: false,
              showAddTransactionModal: false
            });
          });
      }
    });
  };

  updateTransactionValid = (valid, benefitProgramId) => {
    const { transactionId, transactionsList } = this.state

    const bpId = valid ? benefitProgramId : null

    this.setState({
      transactionsList: transactionsList.map(transaction => {
        if (transaction.id === transactionId) {
          return {
            ...transaction,
            joon_valid: valid,
            benefit_program_id: bpId
          }
        } else {
          return transaction
        }
      })
    })
  }

  /**
   * @function handleSubmitValidation
   * @description it handles validating a transaction
   */
  handleSubmitValidation = (e) => {
    e.preventDefault()
    const { transactionId, showValidateTransactionModal } = this.state
    const { form } = this.props;

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.updateTransaction(transactionId, true, parseInt(value.benefitProgram, 10), false)
        this.setState({ showValidateTransactionModal: !showValidateTransactionModal })
      }
    })
  }

  /**
   * @function toggleDeleteConfirmation
   * @param transactionObj
   * @desc This method handles toggling delete confirmation modal
   */
  toggleDeleteConfirmation = (transactionObj = {}) => {
    const { showDeleteTransactionConfirmModal } = this.state;
    const { fromEmployeeDetail } = this.props;
    this.setState({
      showDeleteTransactionConfirmModal: !showDeleteTransactionConfirmModal,
      transactionId: transactionObj.id
    });
    if (!fromEmployeeDetail && _.isEmpty({ transactionObj })) {
      this.setState({
        employeeId: transactionObj.employee_details.employer_id
      });
    }
  };

  /**
   * @function removeTransaction
   * @param e
   * @desc This method handles removing a transaction
   */
  removeTransaction = e => {
    const {
      selectedFilter,
      searchText,
      currentPage,
      employeeId,
      transactionId
    } = this.state;
    const { fromEmployeeDetail } = this.props;
    e.stopPropagation();

    this.setState({
      isProcessing: true
    });
    deleteTransaction(transactionId, employeeId)
      .then(res => {
        if (res.data.success === true) {
          showSuccessNotification(res.data.message);
          this.setState({ showDeleteTransactionConfirmModal: false });
          let employeeIdFilter = employeeId;
          if (!fromEmployeeDetail) {
            employeeIdFilter = "";
          }
          this.getTransactionsList(
            currentPage,
            selectedFilter,
            searchText,
            employeeIdFilter
          );
        } else {
          showErrorNotification(res.data.errors.title);
        }
        this.setState({
          isProcessing: false
        });
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /** @function toggleDetailModal
   * @param transactionDetail
   * @desc - toggles the transaction detail modal window
   */
  toggleDetailModal = (transactionDetail = {}) => {
    const { showDetailModal } = this.state;
    this.setState({ showDetailModal: !showDetailModal, transactionDetail });
  };

  /** @function toggleHistoryModal
   * @param transactionHistory
   * @desc - toggles the transaction detail modal window
   */
  toggleHistoryModal = (transactionHistory = {}) => {
    const { showHistoryModal } = this.state;
    this.setState({
      showHistoryModal: !showHistoryModal,
      transactionHistory
    });
  };


  previewReceipt = (fileName, urls, fileType) => {
    this.setState({
      loadingPreview: true,
      openReceiptPreviewModal: true,
      previewFileType: fileType,
      fileName
    });
    const url = fileType === "application/pdf" ? urls.pdf : urls.original;
    if (url) {
      getPdf(url)
        .then(res => {
          const reader = new window.FileReader();
          reader.readAsDataURL(res.data);
          reader.onloadend = () => {
            const base64data = reader.result;
            this.setState({
              attachmentData: base64data,
              loadingPreview: false
            });
          };
        })
        .catch(() => {
          this.setState({
            loadingPreview: false
          });
        });
    }
  };

  /** @function togglePreviewModal
   * @desc - toggles the pdf modal window
   */
  togglePreviewModal = () => {
    const { openReceiptPreviewModal } = this.state;
    this.setState({
      openReceiptPreviewModal: !openReceiptPreviewModal,
      attachmentData: ""
    });
  };

  /** @function changePerPage
   * @desc - handle change perPage
   */
  changePerPage = page => {
    const { employeeId, selectedFilter } = this.state;
    const currentPage = 1;
    localStorage.setItem("perPage", page);
    this.setState(
      { currentPage, searchText: "", perPage: parseInt(page) },
      () => {
        this.getTransactionsList(currentPage, selectedFilter, "", employeeId);
      }
    );
  };

  openBulkPurchasesModal = () => {
    this.setState({ showBulkPurchasesModal: true });
  };

  toggleBulkPurchasesModal = () => {
    const { showBulkPurchasesModal } = this.state;
    this.setState({
      showBulkPurchasesModal: !showBulkPurchasesModal,
    });
  };

  /**
   * @function handleFileUpload
   * @params file
   * @description This will handle file change
   */
  handleFileUpload = () => {
    const { file, selectedFilter, employeeIdFilter, searchText, currentPage } = this.state;
    this.setState({
      uploadingFile: true,
    });
    const formData = new FormData();
    formData.append("file", file);
    addBulkPurchases(formData).then((res) => {
      if (res.status === 200) {
        this.toggleBulkPurchasesModal();
        message.success(res.data.message);

      }
    }).finally(() => {
      this.setState(
        {
          uploadingFile: false,
          file: null,
          fileList: []
        }
      )

      this.getTransactionsList(
        currentPage,
        selectedFilter,
        searchText,
        employeeIdFilter
      );
    }
    )
  };

  /**
   * @function handleFileChange
   * @params file
   * @description This will handle file change
   */
  handleFileChange = (file) => {
    const fileList = [];
    fileList.push(file);
    this.setState({
      file,
      fileList,
    });
    return false;
  };

  /**
   * @function handleRemoveFile
   * @description This will handle remove file
   */
  handleRemoveFile = () => {
    this.setState({
      file: null,
      fileList: [],
    });
  };

  render() {
    const uploadProps = {
      showUploadList: false,
      multiple: true,
      accept: ".pdf, .jpeg, .jpg, .png",
      disabled: this.state.disabledUploadBtn,
      onRemove: file => {
        const i = filesList.indexOf(file);
        filesList.splice(i, 1);

        this.setState(({ fileList }) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList
          };
        });
        setTimeout(() => {
          if (filesList.length < 9 || this.state.fileList.length < 9) {
            this.setState({ disabledUploadBtn: false });
          }
        }, 20);
      },
      beforeUpload: file => {
        filesList.push(file);

        if (filesList.length <= 9) {
          this.setState({ disabledUploadBtn: false });
          // if (filesList.length === 9) {
          //   this.showUploadWarning();
          // }
        } else {
          filesList.splice(8, filesList.length - 9);
          // this.showUploadWarning();
          return false;
        }

        this.setState(({ fileList }) => ({
          fileList: [...fileList, file]
        }));

        return false;
      },
      fileList: this.state.fileList
    };

    const {
      filteredInfo,
      sortedInfo,
      transactionsList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      totalRecords,
      searchText,
      showAddTransactionModal,
      showValidateTransactionModal,
      benefitPrograms,
      showDeleteTransactionConfirmModal,
      showDetailModal,
      transactionDetail,
      showPlaidLinkModal,
      plaidTokenCheckMessage,
      loadingPreview,
      openReceiptPreviewModal,
      previewFileType,
      fileName,
      attachmentData,
      fileList,
      perPage,
      transactionHistory,
      showHistoryModal,
      showBulkPurchasesModal,
      uploadingFile
    } = this.state;
    const { fromEmployeeDetail, form } = this.props; // we will get "employeeId" as props, from Employee detail container

    return (
      <div>
        <ErrorsModal
          showMetaErrorModal={showPlaidLinkModal}
          hideErrorModal={this.closePlaidTokenModal}
          title={plaidTokenCheckMessage}
        />
        <TransactionsView
          openBulkPurchasesModal={this.openBulkPurchasesModal}
          updateTransactions={this.updateTransaction}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onChangeSearch={this.handleChange}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          transactionsList={transactionsList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
          fromEmployeeDetail={fromEmployeeDetail}
          toggleAddTransactionModal={this.toggleAddTransactionModal}
          toggleValidateTransactionModal={this.toggleValidateTransactionModal}
          toggleDeleteConfirmation={this.toggleDeleteConfirmation}
          toggleDetailModal={this.toggleDetailModal}
          refreshTransactions={this.refreshTransactions}
          perPage={perPage}
          changePerPage={this.changePerPage}
          toggleHistoryModal={this.toggleHistoryModal}
        />

        {showAddTransactionModal && (
          <AddTransactionModal
            showAddTransactionModal={showAddTransactionModal}
            toggleAddTransactionModal={this.toggleAddTransactionModal}
            form={form}
            benefitPrograms={benefitPrograms}
            handleSaveTransaction={this.handleSaveTransaction}
            uploadProps={uploadProps}
            fileList={fileList}
          />
        )}


        <AddBulkPurchasesModal
          toggleBulkPurchasesModal={this.toggleBulkPurchasesModal}
          showBulkPurchasesModal={showBulkPurchasesModal}
          loading={uploadingFile}
          fileList={fileList}
          handleFileChange={this.handleFileChange}
          handleFileUpload={this.handleFileUpload}
          handleRemoveFile={this.handleRemoveFile}
        />

        {showValidateTransactionModal && (
          <ValidateTransactionModal
            showValidateTransactionModal={showValidateTransactionModal}
            toggleValidateTransactionModal={this.toggleValidateTransactionModal}
            form={form}
            benefitPrograms={benefitPrograms}
            handleSubmitValidation={this.handleSubmitValidation}
          />
        )}

        {showDeleteTransactionConfirmModal && (
          <ConfirmationModalView
            showModal={showDeleteTransactionConfirmModal}
            modalTitle="Delete Transaction"
            modalContent="Are you sure you want to remove this transaction?"
            toggleModal={this.toggleDeleteConfirmation}
            handleOkButton={this.removeTransaction}
            noBtnText="No"
            yesBtnText="Yes"
          />
        )}
        <TransactionDetailModal
          toggleDetailModal={this.toggleDetailModal}
          showDetailModal={showDetailModal}
          transactionDetail={transactionDetail}
          previewReceipt={this.previewReceipt}
        />
        <TransactionHistoryModal
          toggleHistoryModal={this.toggleHistoryModal}
          showHistoryModal={showHistoryModal}
          transactionHistory={transactionHistory}
        />

        <PreviewReceiptModal
          attachmentData={attachmentData}
          openReceiptPreviewModal={openReceiptPreviewModal}
          loadingPreview={loadingPreview}
          togglePreviewModal={this.togglePreviewModal}
          fileName={fileName}
          previewFileType={previewFileType}
        />
      </div>
    );
  }
}

const employeeForm = Form.create()(TransactionsContainer);
export default employeeForm;
