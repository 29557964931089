import React, { Component } from "react";
import EmployersView from "./EmployersView";
import { Form, Modal } from "antd";

import {
  getEmployersList,
  cancelInviteEmployer,
  deleteJoonEmployer
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification,
  history
} from "../../helpers";
const { confirm } = Modal;

class EmployersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalPages: 0,
      currentPage: 1,
      employersList: [],
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      totalRecords: "",
      searchText: "",
    };
  }

  componentDidMount() {
    const { currentPage, selectedFilter } = this.state;
    this.getEmployers(currentPage, selectedFilter, "");
  }

  /**
   * @function getEmployers
   * @param currentPage
   * @param filter
   * @param searchText
   * @description it will take the filter and current page and return employers list
   */
  getEmployers = (currentPage, filter, searchText) => {
    this.setState({ isProcessing: true, employersList: [] });
    getEmployersList(currentPage, filter, searchText)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { total_pages, total_records, data } = response.data;
          const { employers } = data;
          const employersList = employers;
          const totalPages = total_pages;
          const totalRecords = total_records;
          this.setState({ employersList, totalPages, totalRecords });
        } else {
          this.setState({ employersList: [], totalPages: 0, totalRecords: 0 });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = value => {
    const page = 1;
    this.setState({ selectedFilter: value, currentPage: page }, () => {
      this.getEmployers(page, value, "");
    });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    const { selectedFilter } = this.state;
    this.setState({ currentPage: page });
    this.getEmployers(page, selectedFilter, "");
  };

  /**
   * @function cancelInviteEmployers
   * @param inviteId
   * @description it will cancel the invite of employer
   */
  cancelInviteEmployers = inviteId => {
    const { selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    cancelInviteEmployer(inviteId)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const successMsg = "Employer Cancelled successfully.";
          showSuccessNotification(successMsg);
          this.setState({ currentPage: 1 }, () => {
            this.getEmployers(1, selectedFilter, "");
          });
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText, currentPage, selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.getEmployers(currentPage, selectedFilter, searchText);
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /**
   * @function openDetailsPage
   * @description it will navigate the user to details page
   */
  openDetailsPage = data => {
    if (data.status === "ACTIVE") {
      history.push(`/employers/${data.id}/profile`);
    }
  };

  /**
   * @function onPressDelete
   * @param employerId
   * @description it will delete the  employer
   */
  onPressDelete = (e, employerId) => {
    e.stopPropagation();
    const { selectedFilter } = this.state;
    confirm({
      title: "Are you sure you want to delete this employer?",
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        this.setState({ isProcessing: true });
        deleteJoonEmployer(employerId)
          .then(response => {
            this.setState({ isProcessing: false });
            if (response.data.success) {
              const successMsg = "Employer deleted successfully.";
              showSuccessNotification(successMsg);
              this.setState({ currentPage: 1 }, () => {
                this.getEmployers(1, selectedFilter, "");
              });
            } else {
              const { errors } = response.data;
              const error = errors.title;
              showErrorNotification(error);
            }
          })
          .catch(() => {
            const errorMsg =
              "There was some error while processing your request.";
            showErrorNotification(errorMsg);
            this.setState({ isProcessing: false });
          });
      }
    });
  };

  render() {
    const {
      filteredInfo,
      sortedInfo,
      employersList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      totalRecords,
      searchText,
    } = this.state;
    return (
      <div>
        <EmployersView
          onPressDelete={this.onPressDelete}
          openDetailsPage={this.openDetailsPage}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onchangeSearch={this.handleChange}
          cancelInviteEmployers={this.cancelInviteEmployers}
          reinviteEmployers={this.reinviteEmployers}
          totalRecords={totalRecords}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          employersList={employersList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
          setAgeSort={this.setAgeSort}
        />
      </div>
    );
  }
}
const employeeForm = Form.create()(EmployersContainer);
export default employeeForm;
