import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Avatar, Dropdown, Menu } from "antd";
import { getUser, history } from "../../helpers";
import { navigationAction } from "../body/Navigation";
import { logoutActionContainer } from "../login/loginActions";
// Top Nav Component
class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * @function logout
   * @desc logout user
   */

  logout = () => {
    const { logoutUser } = this.props;
    logoutUser();
  };


  /**
   * @function menuClick
   * @desc handles menu click
   */

  menuClick = ({ key }) => {
    switch (key) {
      case "2":
        this.logout();
        break;
      default:
        break;
    }
  };

  render() {
    const menu = (
      <Menu onClick={this.menuClick}>
        <Menu.Item key="2">
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="top-nav">
        <Row type="flex" justify="space-between" align="middle">
          <Col span={12}>
            <div>JOON ADMIN</div>
          </Col>

          {getUser() !== null ? (
            <Col span={12} className="text-right">
              <div>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <div className="ant-dropdown-link">
                    <Avatar size="small" icon="user" />
                  </div>
                </Dropdown>
              </div>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    currentPage: state.navigation.currentPage,
    IsUserLoggedIn: state.login.userSignedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: (page, tab) => dispatch(navigationAction(page, tab)),
    logoutUser: () => dispatch(logoutActionContainer())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopNav);
