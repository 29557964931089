import React from "react";
import { Modal, Descriptions, List, Table } from "antd";
import moment from "moment";

export default class TransactionHistoryModal extends React.Component {
  /**
   * @function showPlaidCategory
   * @param record
   * @description it will fetch and return the last category
   */
  showPlaidCategory = record => {
    return record[record.length - 1];
  };

  /**
   * @function isTransactionValid
   * @param joonValid
   * @description it will show if a transaction is joon valid or not
   */
  isTransactionValid = joonValid => {
    let status = "";
    if (joonValid) {
      status = "Yes";
    } else {
      status = "No";
    }
    return status;
  };

  /**
   * @function isAutoValidated
   * @param validatedByClassifier
   * @description it will show if a transaction is auto validated or not
   */
  isAutoValidated = validatedByClassifier => {
    let validated = "";
    if (validatedByClassifier) {
      validated = "Yes";
    } else {
      validated = "No";
    }
    return validated;
  };

  /**
   * @function renderHistory
   * @description render history record
   */

  renderHistory = record => {
    const transactionDescription = `Transaction was ${record.transaction_is_valid ? "validated " : "invalidated "
      }by ${record.transaction_classifier ? "transaction classifier" : "admin"}.
    `;

    const columns = [
      {
        title: "Classified as",
        dataIndex: "match_type"
      },
      {
        title: "Name",
        dataIndex: "match_name"
      }
    ];

    return (
      <div>
        <Descriptions column={1} title={transactionDescription}>
          <Descriptions.Item label="Updated at">
            {moment.utc(record).format("DD MMM, YYYY")}
          </Descriptions.Item>
        </Descriptions>

        {record.transaction_classifier && (
          <Table
            columns={columns}
            dataSource={record.activity_record}
            pagination={false}
            rowKey="basic_benefit_program_category_id"
          />
        )}
      </div>
    );
  };

  render() {
    const {
      transactionHistory,
      showHistoryModal,
      toggleHistoryModal
    } = this.props;
    const history = transactionHistory.transaction_activities_list;

    return (
      <div>
        <Modal
          title={`Transaction History: ${transactionHistory &&
            transactionHistory.id}`}
          visible={showHistoryModal}
          onCancel={toggleHistoryModal}
          footer={null}
          className="data-modal"
        >
          <List
            itemLayout="horizontal"
            dataSource={history}
            renderItem={item => (
              <List.Item>{this.renderHistory(item)}</List.Item>
            )}
          />
        </Modal>
      </div>
    );
  }
}
