import React, { Component } from "react";
import TransactionsTabsView from "./TransactionsTabsView";

/**
 * @function getCurrentTabName
 * @params pathName
 * @description It will return the current active tab
 */
const getCurrentTabName = pathName => pathName.split("/")[2];

export default class TransactionsTabsContainer extends Component {
  state = {
    currentPath: null,
    loading: false,
    id: "",
    refresh: ""
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // get pathname like '/settings/vitals' from url
    const pathName = nextProps.location.pathname;
    const currentPage = getCurrentTabName(pathName);

    if (!prevState.currentPath !== currentPage) {
      return { currentPath: currentPage };
    }
    return null;
  }

  /**
   * @func onChange
   * @desc this will rerender the components
   */
  onChange = e => {
    if (e === "all-transactions") {
      this.setState({ refresh: "all" });
    } else {
      this.setState({ refresh: "reported" });
    }
  };

  render() {
    const { currentPath, refresh } = this.state;
    return (
      <div>
        <TransactionsTabsView
          refresh={refresh}
          currentPath={currentPath}
          onChange={this.onChange}
        />
      </div>
    );
  }
}
