import React from "react";
import { Modal, Descriptions } from "antd";
export default class DetailsModal extends React.Component {
  render() {
    const { hideErrorModal, title, showMetaErrorModal, details } = this.props;

    return (
      <div>
        <Modal
          footer={null}
          className="data-modal"
          title={title}
          visible={showMetaErrorModal}
          onCancel={hideErrorModal}
        >
          <Descriptions column={2}>
            <Descriptions.Item label="Bank Name">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.account_name}
            </Descriptions.Item>
            <Descriptions.Item label="Bank Account Type">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.account_type}
            </Descriptions.Item>
            <Descriptions.Item label="Request Id">
              {details && details.request_id && details.request_id}
            </Descriptions.Item>
            <Descriptions.Item label="Account Id" className="error-wrap">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.account_id}
            </Descriptions.Item>
            <Descriptions.Item label="Institution Id">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.institution_id}
            </Descriptions.Item>
            <Descriptions.Item label="Institution Name" className="error-wrap">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.institution_name}
            </Descriptions.Item>
            <Descriptions.Item label="Mask">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.mask}
            </Descriptions.Item>
            <Descriptions.Item label="Sub Type" className="error-wrap">
              {details &&
                details.transaction_account_details &&
                details.transaction_account_details.sub_type}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      </div>
    );
  }
}
