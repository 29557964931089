import React, { Component } from "react";
import MerchantsListView from "./MerchantsListView";
import {
  getMerchants,
  associateMerchantCategory,
  disassociateMerchantsCategory,
  getAssociatedMerchantCategories,
  addNewMerchant
} from "../../services";

import {
  showErrorNotification,
  showSuccessNotification,
  getProp
} from "../../helpers";
import { Form } from "antd";
import MerchantModal from "./MerchantModal";
class MerchantsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedKey: "",
      showModal: false,
      merchants: [],
      isProcessing: false,
      totalPages: 0,
      currentPage: 1,
      totalRecords: "",
      searchText: "",
      categories: [],
      showAddMerchantModal: false
    };
  }

  componentWillMount() {
    const { currentPage, searchText } = this.state;
    this.getJoonMerchants(currentPage, searchText);
  }

  /**
   * @param currentPage
   * @param searchText
   * @description match merchants by name using given text
   */
  getJoonMerchants = (currentPage, searchText) => {
    this.setState({ isProcessing: true });
    getMerchants(currentPage, searchText)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { total_pages, total_records, data } = response.data;
          const merchantList = data;
          const totalPages = total_pages;
          const totalRecords = total_records;
          this.setState({ merchants: merchantList, totalPages, totalRecords });
        } else {
          this.setState({ merchants: [], totalPages: 0, totalRecords: 0 });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    this.setState({ currentPage: page });
    const { searchText } = this.state;
    this.getJoonMerchants(page, searchText);
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function toggleReferralModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  /**
   * @function handleAssociateCategory
   * @param category
   * @description associate category with merchant
   */
  handleAssociateCategory = category => {
    const { selectedKey } = this.state;
    const params = { merchantId: selectedKey.id, categoryId: category.id };
    this.setState({ isProcessing: true, categoryId: category.id });
    associateMerchantCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getJoonCategories(selectedKey.id);
        showSuccessNotification("Category associated successfully");
      } else {
        showErrorNotification("There is some error in association.");
      }
    });
  };

  /**
   * @function handleDisassociateCategory
   * @description disassociate category from merchant
   */
  handleDisassociateCategory = category => {
    const { selectedKey } = this.state;
    const params = { merchantId: selectedKey.id, categoryId: category.id };
    this.setState({ isProcessing: true, categoryId: category.id });
    disassociateMerchantsCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getJoonCategories(selectedKey.id);
        showSuccessNotification("Category disassociated successfully");
      } else {
        showErrorNotification("There is some error in disassociation.");
      }
    });
  };

  /**
   * @function getJoonCategories
   * @param merchantId
   * @description gets the categories associated to a given merchant
   */
  getJoonCategories = merchantId => {
    this.setState({ isProcessing: true });
    getAssociatedMerchantCategories(merchantId)
      .then(response => {
        if (response.data.success) {
          const { data } = response.data;
          const categoryList = data.basic_benefit_program_categories;
          this.setState({
            categories: categoryList,
            isProcessing: false,
            showModal: true
          });
        } else {
          this.setState({
            categories: [],
            isProcessing: false,
            showModal: true
          });
        }
      })
      .catch(() => {
        this.setState({
          categories: [],
          isProcessing: false
        });
      });
  };

  /**
   * @function onSelectKey
   * @description select plaid category
   */
  onSelectKey = merchant => {
    this.getJoonCategories(merchant.id);
    this.setState({ selectedKey: merchant });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleSearch
   * @desc search merchants
   */

  handleSearch = () => {
    const { searchText, currentPage } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.getJoonMerchants(currentPage, searchText);
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /**
   * @function toggleAddMerchant
   *
   */
  toggleAddMerchant = () => {
    const { showAddMerchantModal } = this.state;
    this.setState({ showAddMerchantModal: !showAddMerchantModal });
  };

  /**
   * @function handleAddMerchant
   * @description it will get the form data and hit the api
   */
  handleAddMerchant = e => {
    const { currentPage, searchText } = this.state;
    const { form } = this.props;

    e.preventDefault();

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.setState({ showAddMerchantModal: false, isProcessing: true });

        const merchantParams = {
          name: value.userName
        };

        addNewMerchant(merchantParams)
          .then(response => {
            form.resetFields();
            this.setState({ isProcessing: false });
            if (response.data.success) {
              const successMsg = "Merchant added successfully";
              showSuccessNotification(successMsg);
              this.getJoonMerchants(currentPage, searchText);
            } else {
              const { errors } = response.data;
              const error = errors.title;

              showErrorNotification(error);
            }
          })
          .catch(() => {
            this.setState({ isProcessing: false });
          });
      }
    });
  };

  render() {
    const {
      merchants,
      isProcessing,
      searchText,
      selectedKey,
      showModal,
      currentPage,
      totalPages,
      totalRecords,
      categories,
      categoryId,

      showAddMerchantModal
    } = this.state;
    const { form } = this.props;

    return (
      <div>
        <MerchantsListView
          toggleAddMerchant={this.toggleAddMerchant}
          form={form}
          categories={categories}
          onChangePage={this.onChangePage}
          openModal={this.openModal}
          toggleModal={this.toggleModal}
          merchants={merchants}
          isProcessing={isProcessing}
          searchText={searchText}
          selectedKey={selectedKey}
          showModal={showModal}
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          handleDisassociateCategory={this.handleDisassociateCategory}
          handleAssociateCategory={this.handleAssociateCategory}
          categoryId={categoryId}
          handleSearch={this.handleSearch}
          onchangeSearch={this.handleChange}
          onSelectKey={this.onSelectKey}
        />
        <MerchantModal
          handleAddMerchant={this.handleAddMerchant}
          form={form}
          showAddMerchantModal={showAddMerchantModal}
          toggleAddMerchant={this.toggleAddMerchant}
        />
      </div>
    );
  }
}
const merchantForm = Form.create()(MerchantsListContainer);
export default merchantForm;
