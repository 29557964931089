import React from "react";
import { Upload, Button, Row, Col, Modal, Icon } from "antd";

class InviteBulkEmployeesModal extends React.Component {
  render() {
    const {
      handleFileChange,
      handleFileUpload,
      fileList,
      loading,
      handleRemoveFile,
      showBulkModal,
      toggleBulkModal
    } = this.props;
    return (
      <Modal
        title={"Invite Bulk Employee"}
        visible={showBulkModal}
        onCancel={toggleBulkModal}
        footer={null}
      >
        <Row>
          <Col>
            <h4 className="mb-10">Upload .csv file </h4>
            <Row type="flex">
              <Col span={6} className="mb-10">
                <Upload
                  beforeUpload={handleFileChange}
                  fileList={fileList}
                  onRemove={handleRemoveFile}
                  accept=".csv"
                >
                  <Button>
                    <Icon type="upload" /> Select File
                  </Button>
                </Upload>
              </Col>
              <Col span={24}>
                <Button
                  loading={loading}
                  type="primary"
                  onClick={handleFileUpload}
                  disabled={fileList.length === 0}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default InviteBulkEmployeesModal;
