import React, { Component } from "react";
import CategoriesListView from "./CategoriesListView";
import { Form, Modal } from "antd";
import {
  getCategories,
  addCategory,
  deleteCategory,
  updateCategory,
  disassociateCategory,
  disassociateMerchantsCategory
} from "../../services";
import {
  showSuccessNotification,
  showErrorNotification,
  getProp
} from "../../helpers";
import AddCategoryModal from "./AddCategoryModal";

const { confirm } = Modal;

class CategoriesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalProcessing: false,
      totalPages: 0,
      currentPage: 1,
      categoryList: [],
      showModal: false,
      name: "",
      description: "",
      totalRecords: "",
      editCategory: ""
    };
  }

  componentDidMount() {
    const { currentPage } = this.state;
    this.getJoonCategories(currentPage);
  }

  /**
   * @function getJoonCategories
   * @param currentPage
   * @description it will take the filter and current page and return Joon categories list
   */
  getJoonCategories = currentPage => {
    this.setState({ isProcessing: true });
    getCategories(currentPage)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { total_pages, total_records, data } = response.data;
          const categoryList = data;
          const totalPages = total_pages;
          const totalRecords = total_records;
          this.setState({ categoryList, totalPages, totalRecords });
        } else {
          this.setState({ categoryList: [], totalPages: 0, totalRecords: 0 });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    this.setState({ currentPage: page });
    this.getJoonCategories(page);
  };

  /**
   * @function openModal
   * @description it will show the modal when user press on invite button
   */
  openModal = () => {
    this.setState({ showModal: true });
  };

  /**
   * @function toggleReferralModal
   * @description it will close the modal from modal cancel button
   */
  toggleModal = cancel => {
    if (cancel) {
      this.setState({
        name: "",
        description: ""
      });
    }
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal
    });
  };

  /**
   * @function handleAddCategory
   * @description it will get the form data and hit the api to add category
   */
  handleAddCategory = e => {
    const { form } = this.props;
    e.preventDefault();

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.setState({ showModal: false, isProcessing: true });
        const params = {
          name: value.name,
          description: value.description
        };

        addCategory(params)
          .then(response => {
            form.resetFields();
            this.setState({ isProcessing: false });
            if (response.data.success) {
              const successMsg = "Category added successfully.";
              showSuccessNotification(successMsg);
              this.getJoonCategories(1);
            } else {
              const { errors } = response.data;
              const error = errors.title;
              const errorMsg = "Category already exists";
              showErrorNotification(
                error === errorMsg ? "Category already exists." : errorMsg
              );
            }
          })
          .catch(() => {
            this.setState({ isProcessing: false });
          });
      }
    });
  };

  /**
   * @function showDeletionConfirmModal
   * @description Deletion confirm modal
   */

  showDeletionConfirmModal = category => {
    confirm({
      title: "Delete Category",
      content: "Do you want to delete this category?",
      onOk: () => {
        this.deleteJoonCategory(category);
      },
      onCancel() { }
    });
  };

  /**
   * @function deleteJoonCategory
   * @param data
   * @description it will delete the joon category
   */
  deleteJoonCategory = data => {
    this.setState({ isProcessing: true });
    deleteCategory(data)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const successMsg = "Category deleted successfully.";
          showSuccessNotification(successMsg);
          this.getJoonCategories(1);
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch(() => {
        const errorMsg = "There was some error while processing your request.";
        showErrorNotification(errorMsg);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function editJoonCategory
   * @param category
   * @description edit the joon category
   */

  editJoonCategory = category => {
    this.setState({
      showModal: true,
      name: category.name,
      description: category.description,
      editCategory: category
    });
  };

  /**
   * @function updateJoonCategory
   * @description it will update the joon category
   */

  updateJoonCategory = e => {
    const { form } = this.props;
    e.preventDefault();

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        form.resetFields();
        this.setState({ showModal: false, isProcessing: true });
        const { editCategory } = this.state;
        editCategory.name = value.name;
        editCategory.description = value.description;
        updateCategory(editCategory)
          .then(response => {
            this.setState({
              isProcessing: false,
              editCategory: "",
              name: "",
              description: ""
            });
            if (response.data.success) {
              const successMsg = "Category updated successfully.";
              showSuccessNotification(successMsg);
              this.getJoonCategories(1);
            } else {
              const { errors } = response.data;
              const error = errors.title;
              showErrorNotification(error);
            }
          })
          .catch(() => {
            const errorMsg =
              "There was some error while processing your request.";
            showErrorNotification(errorMsg);
            this.setState({ isProcessing: false });
          });
      }
    });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  /**
   * @function handleDisassociateCategory
   * @description disassociate category with plaid category
   */

  handleDisassociateMerchantCategory = (merchantId, categoryId) => {
    const { currentPage } = this.state;
    const params = { merchantId, categoryId };
    this.setState({ isProcessing: true });
    disassociateMerchantsCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getJoonCategories(currentPage);
        showSuccessNotification("Category disassociated successfully");
      } else {
        showErrorNotification("There is some error in disassociation.");
      }
    });
  };

  /**
   * @function handleDisassociateCategory
   * @description disassociate category with plaid category
   */

  handleDisassociateCategory = (plaidCatId, categoryId) => {
    const { currentPage } = this.state;
    const params = { plaidCategoryId: plaidCatId, categoryId };
    this.setState({ isProcessing: true });
    disassociateCategory(params).then(response => {
      const getSuccess = getProp(["data", "success"]);
      this.setState({ isProcessing: false });
      if (getSuccess(response)) {
        this.getJoonCategories(currentPage);
        showSuccessNotification("Category disassociated successfully");
      } else {
        showErrorNotification("There is some error in disassociation.");
      }
    });
  };

  render() {
    const {
      name,
      categoryList,
      currentPage,
      totalPages,
      isProcessing,
      showModal,
      isModalProcessing,
      totalRecords,
      editCategory,
      description
    } = this.state;
    const { form } = this.props;
    return (
      <div>
        <CategoriesListView
          deleteCategory={this.showDeletionConfirmModal}
          updateCategory={this.editJoonCategory}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          categoryList={categoryList}
          removeCategory={this.handleDisassociateCategory}
          removeMerchant={this.handleDisassociateMerchantCategory}
        />
        <AddCategoryModal
          editCategory={editCategory}
          name={name}
          description={description}
          isModalProcessing={isModalProcessing}
          form={form}
          showModal={showModal}
          handleAddCategory={this.handleAddCategory}
          updateJoonCategory={this.updateJoonCategory}
          toggleModal={this.toggleModal}
        />
      </div>
    );
  }
}
const employeeForm = Form.create()(CategoriesListContainer);
export default employeeForm;
