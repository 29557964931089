import React, { Component } from "react";
import { Tree, Spin } from "antd";
const { TreeNode } = Tree;

class PlaidCategoriesView extends Component {
  createNodes = categories => {
    return categories.map(parentItem => {
      return (
        <TreeNode title={<h4>{parentItem.name}</h4>} key={parentItem.id}>
          {parentItem.children &&
            parentItem.children.map(childItem => {
              return (
                <TreeNode title={<h4>{childItem.name}</h4>} key={childItem.id}>
                  {childItem.children &&
                    childItem.children.map(grandChildItem => {
                      return (
                        <TreeNode
                          title={<h5>{grandChildItem.name}</h5>}
                          key={grandChildItem.id}
                        />
                      );
                    })}
                </TreeNode>
              );
            })}
        </TreeNode>
      );
    });
  };

  render() {
    const { isProcessing, categoryList, onSelectKey, selectedKey } = this.props;
    return (
      <Spin spinning={isProcessing} className="fixed-spinner">
        <Tree
          showLine
          onSelect={(selectedK, e) => {
            const {
              props: {
                title: {
                  props: { children }
                }
              }
            } = e.node;
            onSelectKey({
              id: selectedK.length ? selectedK[0] : selectedKey.id,
              category: children
            });
          }}
        >
          {categoryList && this.createNodes(categoryList)}
        </Tree>
      </Spin>
    );
  }
}

export default PlaidCategoriesView;
