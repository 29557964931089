import React from "react";
import { Modal, DatePicker } from "antd";
import moment from "moment";
import FileSaver from "file-saver";
import ReportsView from "./ReportsView";
import { getReports } from "../../../services";
import { showErrorNotification, showInfoNotification } from "../../../helpers";

const { MonthPicker, RangePicker } = DatePicker;

class ReportsContainer extends React.Component {
  state = {
    rostersList: [
      { id: 1, title: "Benefit Recipients Overview" },
      { id: 2, title: "Purchases Report" },
      { id: 3, title: "Year To Date Reimbrusement Report" },
      { id: 4, title: "Past Year Reimbrusement Report" }
    ],
    openMonthSelectorModal: false,
    errorMsg: null,
    selectedReportType: null,
    selectedDate: moment(),
    startDate: moment(),
    endDate: moment()
  };

  /**
   * @function toggleMonthSelectorModal
   * @param recordId
   * @desc This handles toggling month/ year selector modal
   */
  toggleMonthSelectorModal = (recordId = null) => {
    this.setState({
      selectedReportType: recordId,
      errorMsg: null
    });

    if (recordId === 3 || recordId === 4) {
      showInfoNotification("Generating report. Please wait....")
      this.fetchRoster(recordId)
    } else {
      const { openMonthSelectorModal } = this.state;
      this.setState({ openMonthSelectorModal: !openMonthSelectorModal })
    }
  };

  buildFileName = dateTime => {
    const { selectedReportType, startDate, endDate } = this.state;
    let fileName = "";
    switch (selectedReportType) {
      case 1:
        fileName = `benefit_recipients_report${dateTime}.xls`;
        break;
      case 2:
        fileName = `employees_purchases_report_${startDate}-${endDate}.xls`;
        break;
      case 3:
        fileName = `ytd_reimbursement_report_${dateTime}.xls`;
        break;
      case 4:
        fileName = `past_year_reimbursement_report_${dateTime}.xls`;
        break;
      default:
        break;
    }
    return fileName;
  };

  /**
   * @function fetchRoster
   * @desc This allows fetching / downloading employee roster
   */
  fetchRoster = async (selectedReportType) => {
    const { selectedDate, startDate, endDate } = this.state;
    let firstDateParam
    let secondDateParam = null;
    this.setState({ errorMsg: null })

    if (selectedReportType === 2) {
      firstDateParam = moment(selectedDate).utc().toISOString()
    } else if (selectedReportType !== 5 && selectedReportType !== 9) {
      firstDateParam = parseInt(moment(selectedDate).format("M"), 10);
      secondDateParam = parseInt(moment(selectedDate).format("YYYY"), 10);
    } else if (selectedReportType === 9) {
      firstDateParam = moment(startDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      secondDateParam = moment(endDate)
        .endOf("month")
        .format("YYYY-MM-DD");
    } else {
      // send "start_date" and "end_date" for downloading approved purchases
      firstDateParam = moment(selectedDate)
        .startOf("month")
        .format("DD-MM-YYYY");
      secondDateParam = moment(selectedDate)
        .endOf("month")
        .format("DD-MM-YYYY");
    }

    const { id } = this.props;
    const dateTime = moment(selectedDate).format("MM-YYYY");
    const fileName = this.buildFileName(dateTime);
    return getReports(id, firstDateParam, secondDateParam, selectedReportType).then(
      response => {
        if (response) {
          this.setState({ openMonthSelectorModal: false });
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });

          FileSaver.saveAs(blob, fileName, true);
          this.setState({ selectedDate: moment() });
        }
      }
    ).catch(e => {
      console.error(e.response)
      const errorMsg = `Error downloading ${this.state.rostersList.find(r => r.id === selectedReportType)?.title}`
      if (selectedReportType === 3 || selectedReportType === 4)
        showErrorNotification(errorMsg)
      else
        this.setState({ errorMsg })
    })
  };

  /**
   * @function handleMonthChange
   * @desc This executes on month change
   */
  handleMonthChange = date => {
    this.setState({
      selectedDate: date
    });
  };

  /**
   * @function disabledDate
   * @param current
   * @desc This disable the future dates in datepicker
   */
  disabledDate = current => {
    // Can not select days after today and today
    return current && current > moment().endOf("day");
  };

  /**
   * @function onDateFilterSelect
   * @param date
   * @param dateString
   * @desc sets date range/ start date and end date for billing summary filter
   */
  onDateFilterSelect = (date, dateString) => {
    const dateToday = moment(new Date()).format("YYYY-MM-DD");
    if (dateString) {
      const startDate = dateString[0] || dateToday;
      const endDate = dateString[1] || dateToday;
      this.setState({ startDate, endDate });
    } else {
      this.setState({
        startDate: dateToday,
        endDate: dateToday
      });
    }
  };

  render() {
    const {
      rostersList,
      openMonthSelectorModal,
      selectedDate,
      selectedReportType,
      errorMsg
    } = this.state;
    return (
      <div>
        <ReportsView
          rostersList={rostersList}
          toggleMonthSelectorModal={this.toggleMonthSelectorModal}
        />
        {selectedReportType !== 3 && selectedReportType !== 4 && (<Modal
          destroyOnClose
          title="Select month to download report for"
          visible={openMonthSelectorModal}
          onOk={() => this.fetchRoster(selectedReportType)}
          onCancel={this.toggleMonthSelectorModal}
        >
          {selectedReportType === 9 && (
            <RangePicker
              disabledDate={this.disabledDate}
              onChange={this.onDateFilterSelect}
              className="br-rd-4"
              value={[moment(this.state.startDate), moment(this.state.endDate)]}
            />
          )}
          {selectedReportType !== 9 && (
            <MonthPicker
              onChange={this.handleMonthChange}
              placeholder="Select month"
              disabledDate={this.disabledDate}
              defaultValue={selectedDate}
            />
          )}
          <div style={{ color: 'red' }} >{errorMsg}</div>
        </Modal>)}
      </div>
    );
  }
}

export default ReportsContainer;
