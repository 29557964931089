import { loginConstants } from "./loginConstants";

export const loginReducer = (
  state = { isProcessing: false, userSignedIn: false },
  action
) => {
  switch (action.type) {
    case loginConstants.LOGIN_PROCESSING:
      return {
        ...state,
        isProcessing: action.isProcessing
      };
    case loginConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      };
    case loginConstants.USER_LOGGED_IN:
      return {
        ...state,
        userSignedIn: action.userSignedIn
      };
    case loginConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
