import React from "react";
import { Modal, Descriptions, Divider, Button } from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

export default class TransactionDetailModal extends React.Component {
  /**
   * @function showPlaidCategory
   * @param record
   * @description it will fetch and return the last category
   */
  showPlaidCategory = record => {
    return record[record.length - 1];
  };

  /**
   * @function isTransactionValid
   * @param joonValid
   * @description it will show if a transaction is joon valid or not
   */
  isTransactionValid = joonValid => {
    let status = "";
    if (joonValid) {
      status = "Yes";
    } else {
      status = "No";
    }
    return status;
  };

  /**
   * @function isAutoValidated
   * @param validatedByClassifier
   * @description it will show if a transaction is auto validated or not
   */
  isAutoValidated = validatedByClassifier => {
    let validated = "";
    if (validatedByClassifier) {
      validated = "Yes";
    } else {
      validated = "No";
    }
    return validated;
  };

  render() {
    const {
      transactionDetail,
      showDetailModal,
      toggleDetailModal,
      previewReceipt
    } = this.props;
    const transaction = transactionDetail;

    return (
      <div>
        <Modal
          title={`Transaction Details: ${transactionDetail &&
            transactionDetail.id}`}
          visible={showDetailModal}
          onCancel={toggleDetailModal}
          footer={null}
          className="data-modal"
        >
          <Descriptions column={2}>
            <Descriptions.Item label="Employee Name">
              {transaction &&
                transaction.employee_details &&
                transaction.employee_details.employee_name}
            </Descriptions.Item>
            <Descriptions.Item label="Employee Email">
              {transaction &&
                transaction.employee_details &&
                transaction.employee_details.email}
            </Descriptions.Item>
            <Descriptions.Item label="Employer Name">
              {transaction &&
                transaction.employee_details &&
                transaction.employee_details.employer_name}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant">
              {transaction && transaction.name}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {transaction && Dollar(transaction.amount)}
            </Descriptions.Item>
            <Descriptions.Item label="Plaid Category">
              {transaction &&
                transaction.category &&
                transaction.category.length > 0
                ? this.showPlaidCategory(transaction.category)
                : "--"}
            </Descriptions.Item>

            <Descriptions.Item label="Transaction date">
              {transaction &&
                transaction.transaction_date &&
                moment.utc(transaction.transaction_date).format("DD MMM, YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Valid">
              {transactionDetail &&
                this.isTransactionValid(transactionDetail.joon_valid)}
            </Descriptions.Item>
            <Descriptions.Item label="Validated At">
              {transactionDetail && transactionDetail.joon_validated_at
                ? moment(transactionDetail.joon_validated_at).format(
                  "DD MMM, YYYY"
                )
                : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Auto Validated">
              {transaction &&
                this.isAutoValidated(transaction.validated_by_classifier)}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions column={1}>
            <Descriptions.Item label="Plaid Transaction Id">
              {transaction && transaction.transaction_id
                ? transaction.transaction_id
                : "--"}
            </Descriptions.Item>


            <Descriptions.Item label="Description">
              {transaction &&
                transaction.payment_meta &&
                transaction.payment_meta.length > 0
                ? transaction.payment_meta
                : "--"}
            </Descriptions.Item>

          </Descriptions>

          {transaction &&
            transaction.documents &&
            transaction.documents.documents_list.length > 0 && <Divider />}

          <Descriptions layout="vertical">
            {transaction &&
              transaction.documents &&
              transaction.documents.documents_list.length > 0 && (
                <Descriptions.Item label="Documents">
                  <ul className="receipt_list">
                    {transaction.documents.documents_list.map(documentObj => {
                      return (
                        <li>
                          <Button
                            type="link"
                            className="download_button"
                            onClick={() =>
                              previewReceipt(
                                documentObj.original_filename,
                                documentObj.urls,
                                documentObj.file_type
                              )
                            }
                          >
                            {documentObj.original_filename}
                          </Button>
                        </li>
                      );
                    })}
                  </ul>
                </Descriptions.Item>
              )}
          </Descriptions>
        </Modal>
      </div>
    );
  }
}
