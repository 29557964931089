import React from "react";
import { Card, Row, Col, Button } from "antd";

export default (record, removeCategory, removeMerchant) => {
  const { id, plaid_categories, merchants } = record;
  return (
    <div>
      <Card
        size="small"
        title={`Associated Plaid Categories`}
        style={{ width: "100%" }}
      >
        {plaid_categories && plaid_categories.length > 0 ? (
          plaid_categories.map(categoryObject => {
            return (
              <Row
                id={id}
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
              >
                <Col>
                  <h5>{categoryObject.name}</h5>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      removeCategory(categoryObject.id, id);
                    }}
                    size="small"
                  >
                    Disassociate
                  </Button>
                </Col>
              </Row>
            );
          })
        ) : (
            <Row
              id={id}
              type="flex"
              justify="center"
              style={{ marginBottom: 10 }}
            >
              <Col>
                <span>No associated categories</span>
              </Col>
            </Row>
          )}
      </Card>

      <Card
        size="small"
        title={`Associated Merchants`}
        style={{ width: "100%", marginTop: 20 }}
      >
        {merchants && merchants.length > 0 ? (
          merchants.map(merchant => {
            return (
              <Row
                id={id}
                type="flex"
                justify="space-between"
                style={{ marginBottom: 10 }}
              >
                <Col>
                  <h5>{merchant.name}</h5>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      removeMerchant(merchant.id, id);
                    }}
                    size="small"
                  >
                    Disassociate
                  </Button>
                </Col>
              </Row>
            );
          })
        ) : (
            <Row
              id={id}
              type="flex"
              justify="center"
              style={{ marginBottom: 10 }}
            >
              <Col>
                <span>No associated merchants</span>
              </Col>
            </Row>
          )}
      </Card>
    </div>
  );
};
