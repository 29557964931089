/**
 * @function getUser
 * @desc This returns user info if the user is logged in
 */
export const getUser = () => {
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};

/**
 * @function isLoggedIn
 * @desc This checks whether user is logged in or not
 */
export const isLoggedIn = () => {
  const user = localStorage.getItem("user");
  return user || false;
};

export const hasAdminRole = roles => roles.some(r => r.name === "JoonEngineer")

/**
 * @function capitalize
 * @desc This capitalizes the string
 */
export const capitalize = s => {
  if (s === "" || s === null) {
    return;
  }
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
};

export const UserStatus = {
  ENABLED: "ENABLED",
  REMOVED: "REMOVED",
  INVITED: "INVITED",
  DISABLED: "DISABLED"
}
