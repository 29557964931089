import React from "react";
import { notification, Icon } from "antd";


/**
 * @function showInfoNotification
 * @param title
 * @param description
 * @description Show Notification for showing info
 */
export const showInfoNotification = (description, title) => {
  notification.destroy();
  notification.info({
    message: title || "Info",
    description
  });
};

/**
 * @function showSuccessNotification
 * @param description
 * @description Show Notification for showing success message to user
 */
export const showSuccessNotification = (description, duration) => {
  notification.destroy();
  notification.success({
    message: "Success!",
    description,
    duration: duration ? duration : 4.5
  });
};

/**
 * @function showErrorNotification
 * @param description
 * @description Show Notification for showing error message to user
 */
export const showErrorNotification = description => {
  notification.destroy();
  notification.error({
    message: "Error!",
    description,
    icon: <Icon />
  });
};
