import React from "react"
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  Select,
} from "antd"

const FormItem = Form.Item
const { Option } = Select

export default class ValidateTransactionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedBenefitProgramId: "",
      benefitProgramOptions: []
    }
  }

  componentDidMount() {
    const bpOptions = [].concat.apply([], this.props.benefitPrograms.map(bp => {
      return {
        id: bp.benefit_program_id,
        name: bp.benefit_program_name
      }
    }))
    this.setState({ benefitProgramOptions: bpOptions })
    this.setSelectedBenefitProgram(bpOptions?.[0].id || "")
  }

  setSelectedBenefitProgram = (bp_id) => {
    this.setState({ selectedBenefitProgramId: bp_id })
  }

  render() {
    const {
      showValidateTransactionModal,
      toggleValidateTransactionModal,
      form,
      handleSubmitValidation
    } = this.props
    const { getFieldDecorator } = form

    return (
      <Modal
        title={"Validate Transaction"}
        visible={showValidateTransactionModal}
        onCancel={toggleValidateTransactionModal}
        footer={null}
      >
        <div style={{ marginBottom: 10 }}>
          Please note that these are the EMPLOYER'S benefit programs, not necessarily
          the ones the employee belongs to. Please double check the program.
        </div>
        <Form onSubmit={handleSubmitValidation}>
          <Row type="flex" justify="space-between" gutter={16}>
            {/* Benefit Program */}
            <Col span={12}>
              <FormItem label="Benefit Program">
                {getFieldDecorator("benefitProgram", {
                    valuePropName: "value",
                    initialValue: this.state.selectedBenefitProgramId,
                    rules: [
                      {
                        required: true,
                        message: "Benefit program is required"
                      }
                    ]
                  },
                )(
                  <Select style={{ width: 'auto' }}
                          onChange={this.setSelectedBenefitProgram}>
                    {this.state.benefitProgramOptions.length > 0 &&
                      this.state.benefitProgramOptions.map(bpOption => (
                        <Option key={bpOption.id} value={bpOption.id}>
                          {bpOption.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row className="modal-footer-row">
            <Col span={24} className="text-right">
              <Button
                size="small"
                onClick={toggleValidateTransactionModal}
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                className="ml-8"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    )
  }
}
