import axios from "axios";
import { error } from "react-notification-system-redux";
import { logoutAction } from "../components/login/loginActions";
import { showInfoNotification } from "../helpers";
/**
 * @function InterceptorService
 * @param props
 * @desc The response interceptor catches the errors and shows it on UI in the form of notifications
 */

const InterceptorService = props => {
  // Response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (errorMsg) {
      if (
        // Logout on 401 and 403 errors
        (errorMsg.response && errorMsg.response.status === 401)
      ) {
        props.dispatch(logoutAction);

        let notification = {
          title: "Unauthorized!",
          message: "Unauthorized access. Signing out..."
        };
        showInfoNotification(notification.title, notification.message);
        return Promise.reject(errorMsg);
      } else {
        return Promise.reject(errorMsg);
      }
    }
  );
};
export { InterceptorService };
