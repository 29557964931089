import React from "react";
import {
  Table,
  Pagination,
  Input,
  Select,
  Row,
  Col,
  Form,
  Button,
  Spin,
  Tooltip,
  Icon
} from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

const FormItem = Form.Item;
const { Option } = Select;
const ReportedTransactionsView = props => {
  const {
    updateTransaction,
    isProcessing,
    filterChange,
    transactionsList,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    searchText,
    onchangeSearch,
    handleSearch,
    toggleDetailModal,
    changePerPage,
    perPage
  } = props;

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id"
    },
    {
      title: "Employee Email",
      dataIndex: "transaction_details.employee_details.email"
    },
    {
      title: "Employer Name",
      dataIndex: "transaction_details.employee_details.employer_name"
    },
    {
      title: "Merchant",
      dataIndex: "transaction_details.name"
    },
    {
      title: "Amount",
      key: "transaction_details.amount",
      render: record => {
        return <div>{Dollar(record && record.transaction_details.amount)}</div>;
      }
    },
    {
      title: "Reported Category",
      dataIndex: "benefit_program_category_name",
      render: record => {
        return <div>{record && <div>{record}</div>}</div>;
      }
    },
    {
      title: "Status",
      dataIndex: "status"
    },
    {
      title: "Trans. Date",
      key: "trans_date",
      render: record => {
        return (
          <div>
            {record && (
              <div>
                {moment
                  .utc(record.transaction_details.transaction_date)
                  .format("DD MMM, YYYY")}
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: "Benefit Program Name",
      dataIndex: "transaction_details.benefit_program_name"
    },
    {
      title: "",
      width: "200px",
      render: record => {
        return (
          <div>
            {record &&
              record.status !== null &&
              record.status === "REPORTED" &&
              (record.uiState ? (
                <Button
                  className="my-5"
                  type="primary"
                  size="small"
                  disabled
                >
                  {record.uiState}
                </Button>
              ) : (
                <Row type="flex" justify="space-between">
                  <Col className="my-5">
                    <Button
                      onClick={() =>
                        updateTransaction(
                          record.transaction_details.id,
                          true,
                          record.benefit_program_id
                        )
                      }
                      type="primary"
                      size="small"
                      disabled={record.uiPending}
                    >
                      Approve
                    </Button>
                  </Col>
                  <Col className="my-5">
                    <Button
                      onClick={() =>
                        updateTransaction(
                          record.transaction_details.id,
                          false,
                          record.benefit_program_id
                        )
                      }
                      type="primary"
                      size="small"
                      disabled={record.uiPending}
                    >
                      Decline
                    </Button>
                  </Col>
                </Row>
              ))}
          </div>
        );
      }
    },
    {
      title: "",
      key: "view",
      width: 80,
      render: record => (
        <div>
          <Tooltip title="View Details">
            <Button
              shape="circle"
              type="primary"
              size="small"
              onClick={() => toggleDetailModal(record)}
              className="mr-10"
            >
              <Icon type="eye" />
            </Button>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col span={10}>
                <FormItem label="Search">
                  <Row gutter={16}>
                    <Col span={18}>
                      <Input
                        name="searchText"
                        placeholder="Search by name, email"
                        value={searchText}
                        onChange={onchangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>

              <Col span={14}>
                <Row type="flex" justify="end" gutter={16}>
                  <Col span={4}>
                    <FormItem label="Per Page">
                      <Select
                        className="full-width"
                        defaultValue={perPage}
                        onChange={changePerPage}
                        value={perPage}
                      >
                        <Option value="10">10</Option>
                        <Option value="25">25</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="Filter">
                      <Select
                        className="full-width"
                        defaultValue="all"
                        onChange={filterChange}
                        value={selectedFilter}
                      >
                        <Option value="all">Reported</Option>
                        <Option value="accepted">Accepted</Option>
                        <Option value="rejected">Rejected</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {transactionsList !== undefined && transactionsList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={transactionsList}
                pagination={false}
                rowKey="id"
              // onRowClick={row => toggleDetailModal(row)}
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={perPage}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
              <div className="blank-View">
                {!isProcessing && (
                  <Row>
                    <Col span={24}>
                      <FormItem>
                        <p>There are no reported transactions.</p>
                      </FormItem>
                    </Col>
                  </Row>
                )}
              </div>
            )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default ReportedTransactionsView;
