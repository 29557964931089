import React from "react";
import { Modal, Row, Form } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
export default class ErrorsModal extends React.Component {
  render() {
    const {
      hideErrorModal,

      title,
      showMetaErrorModal,
      json,
      CopyTo,
      copied
    } = this.props;

    return (
      <Modal
        title={title}
        visible={showMetaErrorModal}
        onCancel={hideErrorModal}
        footer={null}
      >
        <Form>
          <Row type="flex" gutter={24}>
            <div className="error-wrap">
              <code>{json}</code>
              <CopyToClipboard text={json} onCopy={CopyTo}>
                <div className="copy-btn-wrap">
                  <button className="ant-btn-primary ant-btn-sm">
                    Copy to clipboard
                  </button>
                  {copied && <div className="green-text mt-10"> Copied!</div>}
                </div>
              </CopyToClipboard>
            </div>
          </Row>
        </Form>
      </Modal>
    );
  }
}
