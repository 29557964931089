import React, { Component } from "react";
import ProfileView from "./ProfileView";
import {
  getEmployerDetails,
  updateEmployerDetails,
  generateInitialCharge
} from "../../services";
import { showSuccessNotification, showErrorNotification } from "../../helpers";
export default class ProfileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employerId: "",
      isProcessing: false,
      employerDetails: [],
      processing_fee: "",
      setup_fee: "",
      policy_end_date: "",
      website: "",
      benefit_program_amount: "",
      benefit_program_link: "",
      benefit_type: "",
      auto_payment: "",
      checked: false,
      invite_pricing_description: ""
    };
  }

  componentDidMount() {
    const { id } = this.props;

    this.setState({ employerId: id }, () => {
      this.employerDetails();
    });
  }

  toggleChecked = (value) => {
    this.setState({
      auto_payment: value
    });
  }

  onChangeBenefitProgramUrl = (e) => {
    this.setState({ benefit_program_link: e.target.value })
  }

  /**
   * 
   * @function employerDetails
   * @description it will get the employer details
   */
  employerDetails = () => {
    const { employerId } = this.state;
    this.setState({ isProcessing: true });
    getEmployerDetails(employerId)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { data } = response.data;
          const { employer_details } = data;

          this.setState({
            employerDetails: employer_details,
            auto_payment: employer_details.auto_payment,
          });
        } else {
          this.setState({ employerDetails: [] });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function updateEmployerDetails
   * @description it will update the employer details
   */
  updateEmployerDetails = () => {
    const { setup_fee, processing_fee } = this.state;

    if (setup_fee !== null && processing_fee !== null) {
      this.updateEmployer();
    } else {
      const error = "Either Set up fee or Processing fee is empty.";
      showErrorNotification(error);
    }
  };

  /**
   * @function employerDetails
   * @description it will get the employer details
   */
  updateEmployer = () => {
    const {
      setup_fee,
      processing_fee,
      employerId,
      policy_end_date,
      benefit_program_amount,
      benefit_program_link,
      auto_payment
    } = this.state;
    const data = {
      setup_fee,
      policy_end_date,
      processing_fee,
      benefit_program_amount,
      benefit_program_link,
      auto_payment
    };
    this.setState({ isProcessing: true });
    updateEmployerDetails(employerId, data).then(response => {
      this.setState({ isProcessing: false });
      if (response.data.success) {
        const successMsg = "Employer details updated successfully.";
        showSuccessNotification(successMsg);
        const { data } = response.data;
        const { employer_details } = data;
        const { benefit_program, auto_payment } = employer_details;
        const {
          processing_fee,
          setup_fee,
          policy_end_date,
          benefit_program_amount,
          benefit_program_link
        } = benefit_program;

        this.setState({
          employerDetails: employer_details,
          processing_fee,
          setup_fee,
          auto_payment,
          policy_end_date,
          benefit_program_amount,
          benefit_program_link
        });
        this.employerDetails();
      } else {
        const { errors } = response.data;
        const error = errors.title;
        showErrorNotification(error);
      }
    });
  };

  /**
   * @func onChange
   * @description it will change the state for the user
   */
  onChange = (key, value) => {
    if (value !== 0 || value !== "") {
      this.setState({ [key]: value });
    }
  };

  /**
   * @function generateInitialCharge
   * @description it will generate initial charge
   */
  generateInitialCharge = () => {
    this.setState({ isProcessing: true });
    const { employerId } = this.state;
    generateInitialCharge(employerId).then(res => {
      if (res.data.success === true) {
        this.setState({ isProcessing: false });
        this.employerDetails();
        const successMsg = "Initial charge generated!";
        showSuccessNotification(successMsg);
      } else {
        this.setState({ isProcessing: false });
        showErrorNotification(res.data.errors.title);
      }
    });
  };

  render() {
    const {
      isProcessing,
      employerDetails,
      processing_fee,
      setup_fee,
      benefit_type,
      policy_end_date,
      website,
      auto_payment
    } = this.state;
    return (
      <div>
        <ProfileView
          website={website}
          updateEmployerDetails={this.updateEmployerDetails}
          onChange={this.onChange}
          processing_fee={processing_fee}
          setup_fee={setup_fee}
          auto_payment={auto_payment}
          benefit_type={benefit_type}
          onChangeBenefitProgramUrl={this.onChangeBenefitProgramUrl}
          toggleChecked={this.toggleChecked}
          policy_end_date={policy_end_date}
          isProcessing={isProcessing}
          employerDetails={employerDetails}
          generateInitialCharge={this.generateInitialCharge}
        />
      </div>
    );
  }
}
