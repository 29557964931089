import React, { Component } from "react";
import { Layout } from "antd";
import { AppHeader } from "./components/app/AppHeader";
import AppContent from "./components/body/AppContent";
import LeftNav from "./components/body/LeftNav";
import { getUser } from "./helpers";
const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let currentPage = null;
    const pathName = window.location.pathname;
    // highlight navigation on pageload
    const pathStr = (pathName.match(new RegExp("/", "g")) || []).length;
    if (pathStr === 1) {
      currentPage = pathName.replace("/", "");
    } else {
      currentPage = pathName.substring(1).split("/")[0];
    }

    return (
      <div id="wrapper">
        <Layout>
          {getUser() !== null ? <AppHeader /> : ""}
          <Layout>
            {getUser() !== null ? <LeftNav currentPage={currentPage} /> : ""}
            <Layout>
              <Content
                className={`content_container ${getUser() ? "logged-in-container" : ""
                  }`}
              >
                <AppContent />
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default App;
