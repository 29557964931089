import React from "react";
import { Descriptions, Row, Col, Button, Modal } from "antd";
import moment from "moment";
import { checkPlaidToken } from "../../services/transactionsService"
import AccountCard from "../profile/AccountCard";
import Dollar from "../../helpers/Dollar";
import ErrorsModal from "../Errors/ErrorsModal";

export default class EmployeeDetailModal extends React.Component {
  state = {
    showPlaidLinkModal: false,
    plaidTokenCheckMessage: ""
  }

  checkPlaidLink = (userId, transactionAccountId) => {
    checkPlaidToken(userId, transactionAccountId)
      .then(({ data, statusText }) => {
        if (data.errors.length > 0) {
          this.setState({
            showPlaidLinkModal: true,
            plaidTokenCheckMessage: data.errors.join(",")
          })
        } else {
          this.setState({
            showPlaidLinkModal: true,
            plaidTokenCheckMessage: data.message
          })
        }
      })
      .catch(() => {

      })
  }

  closePlaidTokenModal = () => {
    this.setState({ showPlaidLinkModal: false, plaidTokenCheckMessage: "" })
  }

  render() {
    const { employeeDetails } = this.props;
    const { showPlaidLinkModal, plaidTokenCheckMessage } = this.state;
    const isAdmin = this.props.hasAdministrativePermission;

    return (
      <div>
        <ErrorsModal
          showMetaErrorModal={showPlaidLinkModal}
          hideErrorModal={this.closePlaidTokenModal}

          title={plaidTokenCheckMessage}
        />
        <h1> {employeeDetails && employeeDetails.deleted_at ? 'This is a deleted employee' : ''} </h1>
        <Descriptions column={2}>
          <Descriptions.Item label="Name">
            {employeeDetails?.user_name || `${employeeDetails?.first_name} ${employeeDetails?.last_name}`}{" "}
            {employeeDetails && employeeDetails.invite_status === "ADMIN"
              ? "(Admin)"
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {employeeDetails && employeeDetails.email}
          </Descriptions.Item>
          <Descriptions.Item label="Invited at">
            {employeeDetails && employeeDetails.invited_at
              ? moment(employeeDetails.invited_at).format("DD MMM, YYYY")
              : "--"}
          </Descriptions.Item>
          <Descriptions.Item label="Invite status">
            {employeeDetails && employeeDetails.invite_status}
          </Descriptions.Item>
          <Descriptions.Item label="Joined at">
            {employeeDetails &&
              moment(employeeDetails.joined_at).format("DD MMM, YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Deleted at">
            {employeeDetails &&
              moment(employeeDetails.deleted_at).format("DD MMM, YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Current month utilization">
            {employeeDetails &&
              employeeDetails.current_month_utilisation &&
              employeeDetails.current_month_utilisation !== 0
              ? Dollar(employeeDetails.current_month_utilisation)
              : `$0.00`}
          </Descriptions.Item>
          <Descriptions.Item label="Treat Preferences">
            {employeeDetails && employeeDetails.treat_preferences
              ? employeeDetails.treat_preferences.join(", ")
              : "--"}
          </Descriptions.Item>
          <Descriptions.Item style={{ color: "red" }} label="Payout Message">
            <span style={{ color: '#800080' }}>
              {employeeDetails && employeeDetails.payout_message
                ? employeeDetails.payout_message
                : "--"}
            </span>
          </Descriptions.Item>
          <Descriptions.Item style={{ color: "red" }} label="Permissions">
            <span style={{ color: '#800080' }}>
              {employeeDetails && employeeDetails.roles && employeeDetails.roles.map(role => {
                return (
                  <span key={role.id}>{role.name} </span>
                )
              })}
            </span>
          </Descriptions.Item>
        </Descriptions>
        <Row type="flex" justify="space-between" gutter={16}>
          <Col span={12}>
            {employeeDetails && employeeDetails.deposit_account && (
              <>
                <h3 className="account-heading">Deposit Account</h3>
                <AccountCard accountDetails={employeeDetails.deposit_account} />
              </>
            )}
            {employeeDetails && employeeDetails.status !== "INACTIVE" && (
              <Row style={{ marginTop: "64px" }}>
                <Button
                  type="primary"
                  style={{ minWidth: "207px" }}
                  onClick={() => {
                    (employeeDetails &&
                      employeeDetails.invite_status === "ADMIN") ||
                      isAdmin
                      ? this.props.removeAsAdmin(employeeDetails.id)
                      : this.props.makeAdmin(employeeDetails.id);
                  }}
                >
                  {(employeeDetails &&
                    employeeDetails.invite_status === "ADMIN") ||
                    isAdmin
                    ? "Remove Administrative Permission"
                    : "Add Administrative Permission"}
                </Button>
              </Row>
            )}
          </Col>
          {employeeDetails?.transaction_accounts_list &&
            employeeDetails.transaction_accounts_list.length > 0 && (
              <Col span={12}>
                <h3 className="account-heading">Transaction Account</h3>
                {employeeDetails.transaction_accounts_list.map((account) => {
                  return (
                    <div key={account.id}>
                      <AccountCard accountDetails={account} key={Math.random()} />
                      <Row style={{ marginTop: "20px" }}>
                        <Button
                          type="primary"
                          style={{ minWidth: "207px" }}
                          onClick={() => {
                            this.checkPlaidLink(employeeDetails.id, account.id)
                          }}
                        >
                          Check Plaid Link
                        </Button>
                      </Row>
                    </div>
                  );
                })}
              </Col>
            )}
        </Row>
      </div>
    );
  }
}
