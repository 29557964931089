import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { history, store } from "./helpers";
import { InterceptorService } from "./services/interceptor";
import App from "./App";

// all the custom style other than theme will go here
import "./css/custom.css";

// initialize mixpanel

if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
  document.body.classList.add("firefox");
}
window.store = store;

InterceptorService(store);
// initialize google analytics

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
