import React from "react";
import { getAllRoles } from "../../services/roles.js";
import { getEmployersBenefitProgram } from "../../services/employers.js";
import { Checkbox, Select, DatePicker, Modal, Form, Input, Row, Col, Button } from "antd";

const FormItem = Form.Item;
const { Option } = Select;
let Roles = [];
let BenefitPrograms = [];
let benefit_programs = [];

export default class InviteEmployeesModal extends React.Component {
  constructor(props) {
    super(props);
  }
  /**
   * @function validateEmail
   * @param rules
   * @param value
   * @param callback
   */
  validateEmail = (rule, value, callback) => {
    const { email } = this.props;
    if (value === "" || (value && value.trim() === "")) {
      callback(`Email required`);
    }
    const regExp = /\S+@\S+\.\S+/;

    if (regExp.test(value) === false) {
      callback("Email is invalid");
    }

    if (email === null) {
      callback("Email required");
    }
    callback();
  };

  componentDidMount() {
    const { employerId } = this.props;
    getAllRoles().then(
      response => {
        Roles = response?.data?.data
      },
      error => {}
    );
    getEmployersBenefitProgram(employerId).then(
      response => {
        BenefitPrograms = response?.data?.data?.benefit_programs
        benefit_programs = BenefitPrograms.map((bp) => {
          return {
            id: bp.benefit_program_id,
            name: bp.benefit_program_name,
            scheduled_start_date: "",
            enabled: false,
          }
        });
      },
      error => {}
    );
  }

  render() {
    const {
      showModal,
      handleInviteEmployer,
      toggleModal,
      email,
      name,
      form,
    } = this.props;

    const { getFieldDecorator } = form;

    const MakeOption = function(role) {
      return <Option key={role.id} value={role.id}>{role.name}</Option>
    };

    const MakeBpEntry = function(bp, index) {
      return (
        <li key={bp.benefit_program_id}>
          {getFieldDecorator("benefit_programs["+index+"].scheduled_start_date", {
          })(<DatePicker className="datepicker" />)}
          {getFieldDecorator("benefit_programs["+index+"].enabled", {
            valuePropName: 'checked',
          })(<Checkbox>
            {bp.benefit_program_name}
          </Checkbox>)}
          {getFieldDecorator("benefit_programs["+index+"].id", {
            initialValue: bp.benefit_program_id
          })(<Input type="hidden" />)}
        </li>
      )
    };

    return (
      <div>
        <Modal
          title={"Invite Employee"}
          visible={showModal}
          onCancel={toggleModal}
          footer={null}
        >
          <Form onSubmit={handleInviteEmployer}>
            <Row type="flex" justify="space-between" gutter={16}>
              <Col span={12}>
                <FormItem label="Email">
                  {getFieldDecorator("email", {
                    initialValue: `${email}`,
                    rules: [
                      {
                        required: true,
                        validator: this.validateEmail,
                      },
                    ],
                  })(<Input type="email" placeholder="Email" name="email" />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Name">
                  {getFieldDecorator("name", {
                    initialValue: `${name}`,
                  })(<Input type="text" placeholder="Name" name="name" />)}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={16}>
              <Col span={12}>
                <FormItem label="Role">
                  {getFieldDecorator("role_id", {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<Select initialValue="Employee">{Roles.map(MakeOption)}</Select>)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Invite Date">
                  {getFieldDecorator("date", {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(<DatePicker name="date" />)}
                </FormItem>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" gutter={16}>
              <Col span={24}>
                <FormItem label="Benefit Programs">
                  <ul className="invite_bp_list">
                    {BenefitPrograms.map(MakeBpEntry)}
                  </ul>
                </FormItem>
              </Col>
            </Row>
            <Row className="modal-footer-row">
              <Col span={24} className="text-right">
                <Button size="small" onClick={toggleModal}>
                  {"Cancel"}
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  size="small"
                  className="ml-8"
                >
                  {"Invite"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
