import React, { Component } from "react";
import RequestAccessView from "./RequestAccessView";
import { Form } from "antd";
import {
  getRequestADemoList,
  updateEmployerRequestDetails,
  getEmployerRequestDetails,
  inviteEmployer
} from "../../services/index";
import {
  showSuccessNotification,
  showErrorNotification
} from "../../helpers";

class RequestAccessContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalPages: 0,
      RequestAccessDetails: [],
      currentPage: 1,
      employerAccessList: [],
      email: "",
      totalRecords: "",
      searchText: "",
      employerId: "",
      filteredInfo: null,
      sortedInfo: null,
      selectedFilter: "all",
      requestAccessData: {
        email: "",
        SetUpFee: "500",
        processingFee: "5",
        PromotionalPriceExpirationDate: "",
        PromotionalSetUpFee: "",
        PromotionalProcessingFee: "",
        SetAllowance: "100",
        PolicyEndDate: "",
        benefitType: "Monthly"
      },
      joonCategories: []
    };
  }

  componentDidMount() {
    const { selectedFilter } = this.state;
    this.getEmployees(1, selectedFilter, "");
  }

  /**
   * @function getEmployees
   * @param currentPage
   * @param selectedFilter
   * @param searchText
   * @description it will take the filter and current page and return employers list
   */
  getEmployees = (currentPage, selectedFilter, searchText) => {
    this.setState({ isProcessing: true, employerAccessList: [] });
    getRequestADemoList(currentPage, selectedFilter, searchText)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { total_pages, total_records } = response.data;
          const totalPages = total_pages;
          const totalRecords = total_records;
          this.setState({
            employerAccessList:
              response.data.data.employer_access_requests_list,
            totalPages,
            totalRecords
          });
        } else {
          this.setState({
            employerAccessList: [],
            totalPages: 0,
            totalRecords: 0
          });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function getRequestAccessData
   * @param employerId
   */
  getRequestAccessData = employerId => {
    this.setState({ isProcessing: true });
    getEmployerRequestDetails(employerId)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const { data } = response.data;
          const { email, user_name } = data;
          this.onChangeText("email", email);
          this.onChangeText("userName", user_name);
          this.toggleModal();
        } else {
          this.setState({
            RequestAccessDetails: []
          });
        }
      })
      .catch(() => {
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function onChangeText
   * @param key
   * @param value
   * @description Called when editing changed on text fields
   */
  onChangeText = (key, value) => {
    const { requestAccessData } = this.state;
    this.setState({
      requestAccessData: {
        ...requestAccessData,
        [key]: value
      }
    });
  };

  /**
   * @function onChangePage
   * @desc pagination
   */

  onChangePage = page => {
    const { selectedFilter } = this.state;
    this.setState({ currentPage: page });
    this.getEmployees(page, selectedFilter, "");
  };

  /**
   * @function cancelEmployersRequest
   * @param id
   * @description it will cancel the invite of employer
   */
  cancelEmployersRequest = id => {
    const { selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    const status = {
      request_status: "cancelled"
    };
    updateEmployerRequestDetails(id, status)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.success) {
          const successMsg = "Demo request cancelled successfully.";
          showSuccessNotification(successMsg);
          this.setState({ currentPage: 1 }, () => {
            this.getEmployees(1, selectedFilter, "");
          });
        } else {
          const { errors } = response.data;
          const error = errors.title;
          showErrorNotification(error);
        }
      })
      .catch(response => {
        const { errors } = response.data;
        const error = errors.title;

        showErrorNotification(error);
        this.setState({ isProcessing: false });
      });
  };

  /**
   * @function handleChange
   * @param e
   * @return nothing
   * @desc This method sets notes when user types in the textbox
   */
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  /**
   * @function filterChange
   * @param value
   * @description it will get the filter name and will fetch the filtered employers
   */
  filterChange = value => {
    const page = 1;
    this.setState({ selectedFilter: value, currentPage: 1 }, () => {
      this.getEmployees(page, value, "");
    });
  };

  /**
   * @function handleSearch
   * @description it will search the employer list
   */
  handleSearch = () => {
    const { searchText, currentPage, selectedFilter } = this.state;
    this.setState({ isProcessing: true });
    if (searchText !== null) {
      this.getEmployees(currentPage, selectedFilter, searchText);
    } else {
      const errorMsg = "Please enter some value in order to search";
      showErrorNotification(errorMsg);
    }
  };

  /**
   * @function handleInviteEmployer
   * @description it will get the form data and hit the api
   */
  handleInviteEmployer = e => {
    const { form } = this.props;
    const { requestAccessData, selectedFilter } = this.state;
    e.preventDefault();

    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.setState({ isProcessing: true });

        let concatenatedDateFormat = "";
        let policyDateFormat = "";

        //This validates that the format time is correct for Promotional Price Exp. Date
        if (value.PromotionalPriceExpirationDate) {
          const getTimeFromValue = value.PromotionalPriceExpirationDate.format(
            "HH:mm:ss"
          );
          const getDateFromValue = value.PromotionalPriceExpirationDate.format(
            "YYYY-MM-DD"
          );
          concatenatedDateFormat = `${getDateFromValue} ${getTimeFromValue}`;
        }

        //This validates that the format time is correct for Policy Period
        if (value.PolicyEndDate) {
          const getTimeFromValuePolicy = value.PolicyEndDate.format(
            "HH:mm:ss"
          );
          const getDateFromValuePolicy = value.PolicyEndDate.format(
            "YYYY-MM-DD"
          );
          policyDateFormat = `${getDateFromValuePolicy} ${getTimeFromValuePolicy}`;
        }

        const employersInviteParams = {
          email: value.email,
          name: value.userName,
          processing_fee: value.processingFee === "" ? requestAccessData.processingFee : value.processingFee,
          setup_fee: value.SetUpFee === "" ? requestAccessData.SetUpFee : value.SetUpFee,
          set_allowance: value.SetAllowance === "" ? requestAccessData.SetAllowance : value.SetAllowance,
          benefitType: value.BenefitType === "" ? requestAccessData.BenefitType : value.BenefitType,
          promo_processing_fee: value.PromotionalProcessingFee,
          promo_setup_fee: value.PromotionalSetUpFee,
          promo_price_expiry: concatenatedDateFormat,
          billing_type: "stripe",
          policy_end_date: policyDateFormat,
          basic_benefit_program_category_ids: value.joonCategories
        };

        inviteEmployer(employersInviteParams)
          .then(response => {
            form.resetFields();
            this.setState({ isProcessing: false });
            if (response.data.success) {
              const successMsg = "Employer invited successfully.";
              showSuccessNotification(successMsg);
              this.setState({ currentPage: 1 }, () => {
                this.getEmployees(1, selectedFilter, "");
              });
            } else {
              const { errors } = response.data;
              const error = errors.title;

              showErrorNotification(error);
            }
          })
          .catch(() => {
            this.setState({ isProcessing: false });
          });
      }
    });
  };

  render() {
    const {
      filteredInfo,
      sortedInfo,
      employerAccessList,
      currentPage,
      totalPages,
      selectedFilter,
      isProcessing,
      totalRecords,
      searchText,
    } = this.state;

    return (
      <div>
        <RequestAccessView
          getRequestAccessData={this.getRequestAccessData}
          openBulkEmployeeModal={this.openBulkEmployeeModal}
          handleSearch={this.handleSearch}
          searchText={searchText}
          onchangeSearch={this.handleChange}
          cancelEmployersRequest={this.cancelEmployersRequest}
          reinviteEmployers={this.reinviteEmployers}
          totalRecords={totalRecords}
          openModal={this.openModal}
          isProcessing={isProcessing}
          selectedFilter={selectedFilter}
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          totalPages={totalPages}
          employerAccessList={employerAccessList}
          sortedInfo={sortedInfo}
          filteredInfo={filteredInfo}
          clearFilters={this.clearFilters}
          clearAll={this.clearAll}
          filterChange={this.filterChange}
        />
      </div>
    );
  }
}
const requestADemoForm = Form.create()(RequestAccessContainer);
export default requestADemoForm;
