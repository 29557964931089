import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, Alert, Button, Input, Spin } from "antd";

const FormItem = Form.Item;
class LoginView extends Component {
  render() {
    const { props } = this;
    const { isProcessing, errorMsg } = props;
    const { getFieldDecorator } = this.props.form;

    const buttonDisabled =
      props.email.trim() === "" || props.password.trim() === "";
    return (
      <Spin spinning={isProcessing}>
        <div className="auth-form">
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="auth-form-row"
          >
            <Col xs={24} md={12} sm={16} lg={8} xl={6}>
              <div className="login-form-wrapper">
                <h2 className="login-title text-center">Sign In</h2>
                <h4 className="login-sub-title text-center">
                  Please sign in with your email and password
                </h4>
                <div className="form-alerts-wrapper">
                  {/* {props.error && (
                  <Alert message={"error"} type="error" showIcon />
                )} */}

                  {errorMsg && (
                    <Alert message={errorMsg} type="error" showIcon />
                  )}
                </div>

                <Form onSubmit={props.handleSubmit} className="login-form">
                  <FormItem>
                    {getFieldDecorator("email", {
                      initialValue: `${props.email}`,
                      rules: [
                        {
                          required: true,
                          message: `${"Enter your email"}`
                        }
                      ]
                    })(
                      <Input
                        name="email"
                        onChange={props.handleChange}
                        placeholder="Email"
                        type="email"
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator("password", {
                      initialValue: `${props.password}`,
                      rules: [
                        {
                          required: true,
                          message: `${"Password required"}`
                        }
                      ]
                    })(
                      <Input
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={props.handleChange}
                      />
                    )}
                  </FormItem>
                  <div className="text-center">
                    <FormItem>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={24}>
                          <Button
                            disabled={buttonDisabled}
                            //loading={props.isProcessing}
                            type="primary"
                            htmlType="submit"
                            className="login-form-button text-uppercase"
                          >
                            {"Sign In"}
                          </Button>
                        </Col>
                      </Row>
                    </FormItem>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}

export default Form.create()(LoginView);
