import { getRequest, postRequest, deleteRequest, putRequest } from './index'

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getEmployeesList = (employerId, page, filter, searchText) => {
  let url = '';
  if (filter !== "") {
    url += `/employers/${employerId}/users?search_text=${searchText}&status=${filter}&page=${page}&per_page=10`;
  }

  return getRequest(url)
};

/**
 * @function inviteEmployee
 * @param employersId
 * @param employeeData
 * @desc This invites the employee
 */
export const inviteEmployee = (employersId, employeeData) => {
  const url = `/employers/${employersId}/users/invites/invite_multiple/`;
  return postRequest(url, employeeData)
};

/**
 * @function reinviteInviteEmployee
 * @desc  it will reinvite employee
 */

export const reinviteEmployee = (userId) => {
  const url = '/invites/reinvite';
  return postRequest(url, { user_id: userId })
};

/**
 * @function cancelInvites
 * @desc  it will cancel the invited employee
 */

export const cancelInvites = (userId) => {
  const url = '/invites/cancel';
  return postRequest(url, { user_id: userId })
};

/**
 * @function getEmployeeDetails
 * @desc  it will get employee details
 */

export const getEmployeeDetails = (employeeId) => {
  const url = `/users/${employeeId}`;
  return getRequest(url)
};

/**
 * @function refreshTransactions
 * @param empId
 * @desc  it will refresh employee transactions
 */

export const refreshTransactions = (user_id) => {
  const url = `/users/${user_id}/refresh_transactions`;
  return postRequest(url)
};

/**
 * @function deleteEmployee
 * @param "employerId", "employeeId"
 * @desc  api to delete employee
 */

export const deleteEmployee = (employerId, userId) => {
  const url = `/employers/${employerId}/users/${userId}`;
  return deleteRequest(url)
};

export const getBenefitTransfers = (employeeId) => {
  const url = `/admin/employees/${employeeId}/list_benefit_transfers`;
  return getRequest(url)
};

/**
 * @function removeAdministrativePermission
 * @param "employeeId"
 * @desc  Will Remove administrative permission
 */

export const removeAdministrativePermission = (employeeId) => {
  const url = `/admin/employees/${employeeId}/remove_administrative_permission`;
  return putRequest(url)
};

/**
 * @function addAdministrativePermission
 * @param "employeeId"
 * @desc  Will add administrative permission
 */

export const addAdministrativePermission = (employeeId) => {
  const url = `/admin/employees/${employeeId}/add_administrative_permission`;
  return putRequest(url)
};
