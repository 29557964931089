import React from "react";
import { Table, Button, Tooltip, Icon } from "antd";

class ReportsView extends React.Component {
  render() {
    const { toggleMonthSelectorModal, rostersList } = this.props;

    const columns = [
      {
        title: "Report",
        key: "id",
        dataIndex: "title"
      },
      {
        title: "Action",
        key: "id",
        render: (text, record) => {
          return (
            <Tooltip title="Download XLS">
              <Button
                shape="circle"
                type="primary"
                size="small"
                onClick={() => toggleMonthSelectorModal(record.id)}
              >
                <Icon type="download" />
              </Button>
            </Tooltip>
          );
        }
      }
    ];

    return (
      <div>
        <Table
          pagination={false}
          rowKey="id"
          dataSource={rostersList}
          columns={columns}
        />
      </div>
    );
  }
}

export default ReportsView;
