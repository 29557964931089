import React, { Component } from "react";
import { Form } from "antd";
import BenefitTransfersView from "./BenefitTransfersView";
import {getBenefitTransfers} from "../../services/index";

class BenefitTransfersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeId: "",
      isProcessing: false
    };
  }

  componentWillReceiveProps(prevProps) {
    const { employeeId } = this.props;

    if (prevProps.employeeId !== employeeId) {
      this.setState({ employeeId: prevProps.employeeId });
      this.fetchBenefitTransfers(prevProps.employeeId);
    }
    
  }
  
  /**
   * @function fetchBenefitTransfers
   * @param employeeId
   * @description It will get all benefit transfers for given employee
   */
  fetchBenefitTransfers = (employeeId) => {
    this.setState({ isProcessing: true });
    getBenefitTransfers(employeeId)
      .then(response => {
        this.setState({ isProcessing: false });
        if (response.data.benefit_transfers && response.data.benefit_transfers.length > 0) {
          // eslint-disable-next-line camelcase
          const { benefit_transfers } = response.data;
          this.setState({ benefit_transfers });
        } else {
          this.setState({
            benefit_transfers: []
          });
        }
      })
      .catch((error) => {
        this.setState({ isProcessing: false });
      });
  };

  render() {
    const {
      benefit_transfers
    } = this.state;

    return (
      <div>
        <BenefitTransfersView
          benefitTransfersList={benefit_transfers}
        />
      </div>
    )      
      
  }
}
const employeeForm = Form.create()(BenefitTransfersContainer);
export default employeeForm;
