import React from "react";
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Upload
} from "antd";
import NumericInput from "../../helpers/NumericInput";

const FormItem = Form.Item;
const { Option } = Select;

export default class AddTransactionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBenefitProgramId: "",
      selectedBenefitProgramCategoryId: "",
      benefitProgramOptions: [],
      benefitProgramCategoryOptions: []
    };
  }

  componentDidMount() {
    const bpOptions = [].concat.apply([], this.props.benefitPrograms.map(bp => {
      return {
        id: bp.benefit_program_id,
        name: bp.benefit_program_name
      }
    }))
    this.setState({ benefitProgramOptions: bpOptions })
    this.setSelectedBenefitProgram(bpOptions?.[0].id || "")
  }

  /**
   * @function validateAmount
   * @param rule
   * @param value
   * @param callback
   */
  validateAmount = (rule, value, callback) => {
    if (value === "" || (value && value.trim() === "") || value === undefined) {
      callback("Amount is required");
    }
    if (value < 0) {
      callback("Amount cannot be negative");
    }

    callback();
  };

  setSelectedBenefitProgram = (bp_id) => {
    this.setState({ selectedBenefitProgramId: bp_id })

    if (bp_id) {
      const selectedBenefitProgram = this.props.benefitPrograms.find(bp => bp.benefit_program_id === bp_id)
      const bpcOptions = selectedBenefitProgram.benefit_program_categories

      this.setState({ benefitProgramCategoryOptions: bpcOptions })
      this.setSelectedBenefitProgramCategory(bpcOptions?.[0].id || "")
    }
  }

  setSelectedBenefitProgramCategory = (bpc_id) => {
    this.setState({ selectedBenefitProgramCategoryId: bpc_id })
  }

  render() {
    const {
      showAddTransactionModal,
      toggleAddTransactionModal,
      form,
      handleSaveTransaction,
      uploadProps,
      fileList
    } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title={"Add Transaction"}
        visible={showAddTransactionModal}
        onCancel={toggleAddTransactionModal}
        footer={null}
      >
        <Form onSubmit={handleSaveTransaction}>
          <Row type="flex" justify="space-between" gutter={16}>
            <Col span={12}>
              <FormItem label="Transaction name">
                {getFieldDecorator("transactionName", {
                  rules: [
                    {
                      required: true,
                      message: "Transaction name is required"
                    }
                  ]
                })(
                  <Input
                    type="text"
                    placeholder="Transaction name"
                    name="transactionName"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Amount">
                {getFieldDecorator("amount", {
                  rules: [
                    {
                      required: true,
                      validator: this.validateAmount
                    }
                  ]
                })(
                  <NumericInput
                    className="with-dollar"
                    placeholder="Amount"
                    name="amount"
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-between" gutter={16}>
            <Col span={12}>
              <FormItem label="Transaction date">
                {getFieldDecorator("transactionDate", {
                  rules: [
                    {
                      required: true,
                      message: "Transaction date is required"
                    }
                  ]
                })(
                  <DatePicker
                    className="full-width"
                    type="date"
                    name="transactionDate"
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row type="flex"gutter={16}>
            <Col span={24}>
              <FormItem label="Benefit Program">
                {getFieldDecorator("benefitProgram", {
                    valuePropName: "value",
                    initialValue: this.state.selectedBenefitProgramId,
                    rules: [
                      {
                        required: true,
                        message: "Benefit program is required"
                      }
                    ]
                  },
                )(
                  <Select className="full-width"
                          onChange={this.setSelectedBenefitProgram}>
                    {this.state.benefitProgramOptions.length > 0 &&
                      this.state.benefitProgramOptions.map(bpOption => (
                        <Option key={bpOption.id} value={bpOption.id}>
                          {bpOption.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
            </Row>
            <Row type="flex" gutter={16}>
            <Col span={24}>
              {this.state.selectedBenefitProgramCategoryId &&
                <FormItem label="Benefit Category">
                  {getFieldDecorator("benefitCategory", {
                    valuePropName: "value",
                    initialValue: this.state.selectedBenefitProgramCategoryId,
                    rules: [
                      {
                        required: true,
                        message: "Benefit category is required"
                      }
                    ]
                  })(
                    <Select className="full-width"
                            onChange={this.setSelectedBenefitProgramCategory}>
                      {this.state.benefitProgramCategoryOptions.length &&
                        this.state.benefitProgramCategoryOptions.map(categoryObj => (
                          <Option key={categoryObj.id} value={categoryObj.id}>
                            {categoryObj.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
              }
            </Col>
          </Row>

         

          <Row>
            {/* upload section start */}
            <div className="receipt_upload_wrap mb_10">
              {fileList.length < 9 && (
                <Upload {...uploadProps}>
                  <div
                    className="add_receipt_btn">
                    Add Receipt
                  </div>
                </Upload>
              )}
              {fileList &&
                fileList.length > 0 &&
                fileList.map(fileObj => {
                  return (
                    <div className="uploaded_file_wrapper" key={Math.random()}>
                      <div className="uploaded_filename">{fileObj.name}</div>
                      <span onClick={e => uploadProps.onRemove(fileObj, e)} className="remove_report_link"></span>
                    </div>
                  );
                })}
            </div>
            {/* upload section ends */}
          </Row>
          <Row className="modal-footer-row">
            <Col span={24} className="text-right">
              <Button size="small" onClick={toggleAddTransactionModal}>
                {"Cancel"}
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                className="ml-8"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
