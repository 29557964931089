import React from "react";
import { Modal, Row, Form } from "antd";

export default class ErrorsStripeModal extends React.Component {
  render() {
    const { hideErrorModal, title, showMetaErrorModal, json, url } = this.props;

    return (
      <Modal
        title={title}
        visible={showMetaErrorModal}
        onCancel={hideErrorModal}
        footer={null}
      >
        <Form>
          <Row type="flex">
            <div className="error-wrap">
              <code>Error Message: {json}</code>
            </div>
          </Row>
          <Row type="flex" style={{ marginTop: 20 }}>
            <div className="error-wrap">
              <code>Doc Url: {url}</code>
            </div>
          </Row>
        </Form>
      </Modal>
    );
  }
}
