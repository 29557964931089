import React from "react";
import { Modal, Row, Col, Button } from "antd";

export default class ConfirmationModalView extends React.Component {
  render() {
    const {
      showModal,
      handleOkButton,
      toggleModal,
      modalTitle,
      modalContent,
      noBtnText,
      yesBtnText
    } = this.props;
    return (
      <Modal
        title={modalTitle}
        visible={showModal}
        onCancel={toggleModal}
        footer={[
          <Button size="small" onClick={toggleModal}>
            {noBtnText}
          </Button>,
          <Button
            type="primary"
            size="small"
            className="ml-8"
            onClick={handleOkButton}
          >
            {yesBtnText}
          </Button>
        ]}
      >
        <Row>
          <Col>{modalContent}</Col>
        </Row>
      </Modal>
    );
  }
}
