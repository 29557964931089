import { deleteRequest, getRequest, postRequest, putRequest } from "./index";

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getTransactions = (
  page,
  filter,
  searchText,
  employeeId = "",
  perPage
) => {
  let url = "";
  if (filter !== "") {
    url += `/transactions?search_text=${searchText}&transaction_status=${filter}&page=${page}&perpage=${perPage}&user_id=${employeeId}`;
  }
  return getRequest(url)
};

/**
 * @function getEmployersList
 * @desc employers list
 */

export const getReportedTransactions = (page, filter, searchText, perPage) => {
  let url = "";
  if (filter !== "") {
    url += `/reported_transactions?search_text=${searchText}&transaction_status=${filter}&page=${page}&perpage=${perPage}`;
  }
  return getRequest(url)
};

/**
 * @function adminUpdateTransaction
 * @desc Update a transaction / associated reported transaction
 */
export const adminUpdateTransaction = (transactionId, payload) => {
  const url = `/transactions/${transactionId}`;
  return putRequest(url, payload)
};

/**
 * @function getPdf
 * @param url
 * @desc This function gets the attachment file of selected order
 */
export const getPdf = url => {
  return getRequest(url)
}

/**
 * @function saveTransaction
 * @desc This saves/ updates manually added transaction
 */
export const saveTransaction = transactionObj => {
  // let dataObj = {};
  let url = "/manual_transactions";
  if (!transactionObj.transactionId) {
    return postRequest(url, transactionObj)
  } else {
    url = `/manual_transactions/${transactionObj.transactionId}`;
    return postRequest(url, transactionObj)
  }
};

/**
 * @function deleteTransaction
 * @param "employerId", "employeeId"
 * @desc  api to delete employee
 */

export const deleteTransaction = (transactionId, employeeId) => {
  const url = `/manual_transactions/${transactionId}?employee_id=${employeeId}`;
  return deleteRequest(url)
};

/**
 * @function addBulkPurchases
 * @desc  add purchases in bulk
 */
export const addBulkPurchases = (data) => {
  const url = `/manual_transactions/bulk_create`
  return postRequest(url, data)
}

export const checkPlaidToken = (userId, transactionAccountId) => {
  const url = `/users/${userId}/transaction_accounts/${transactionAccountId}/check_plaid_token`
  return postRequest(url)
}
