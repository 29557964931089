import React from "react";
import {
  Table,
  Pagination,
  Row,
  Col,
  Form,
  Button,
  Spin
} from "antd";
import CategoryDetailView from "./CategoryDetails";

const FormItem = Form.Item;
const CategoriesListView = props => {
  const {
    isProcessing,
    categoryList,
    totalRecords,
    currentPage,
    onChangePage,
    openModal,
    updateCategory,
    deleteCategory,
    removeMerchant,
    removeCategory
  } = props;

  const columns = [
    {
      title: "Id",
      key: "id",
      dataIndex: "id"
    },
    {
      title: "Name",
      key: "id",
      dataIndex: "name"
    },
    {
      title: "",
      key: "id",
      render: record => {
        return (
          <div>
            <Row type="flex" justify="end">
              <Col className="mr-10">
                <Button
                  onClick={() => updateCategory(record)}
                  type="primary"
                  size="small"
                >
                  Edit
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => deleteCategory(record)}
                  type="primary"
                  size="small"
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </div>
        );
      }
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter="24" justify="space-between">
              <Col span={6} className="no_colon">
                <FormItem label="&nbsp;">
                  <Button type="primary" onClick={openModal}>
                    Add Category
                  </Button>
                </FormItem>
              </Col>
            </Row>
          </div>
          {categoryList !== undefined && categoryList.length > 0 ? (
            <div>
              <Table
                expandedRowRender={record =>
                  CategoryDetailView(record, removeCategory, removeMerchant)
                }
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={categoryList}
                pagination={false}
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="blank-View">
              <Row>
                <Col span={24}>
                  <FormItem>
                    <p>There are no categories.</p>
                  </FormItem>
                </Col>
              </Row>
            </div>
          )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default CategoriesListView;
