import { loginConstants } from "./loginConstants";
import { postLogin, logout } from "../../services";
import { getProp, history, hasAdminRole } from "../../helpers";

export const loginProcessing = isProcessing => {
  return { type: loginConstants.LOGIN_PROCESSING, isProcessing };
};
export const loginSuccess = user => {
  return { type: loginConstants.LOGIN_SUCCESS, user };
};
export const loginFailure = error => {
  return { type: loginConstants.LOGIN_FAILURE, error };
};
export const userLoggedIn = userSignedIn => {
  return { type: loginConstants.USER_LOGGED_IN, userSignedIn };
};

/**
 * @function setDetails
 */
const setDetails = user => {
  localStorage.setItem("user_id", user.id);
  localStorage.setItem("user_name", user.name);
  return hasAdminRole(user.roles);
};

/**
 * @function loginAction
 * @param email
 * @param password
 * @desc This logins the user.
 */
export const loginAction = (email, password) => {
  return dispatch => {
    dispatch(loginProcessing(true));
    postLogin(email, password).then(response => {
      // login successful
      const getUser = getProp(["data", "data", "user"]);
      const getErrors = getProp(["data", "errors"]);

      if (getUser(response)) {
        const admin = setDetails(getUser(response));

        if (admin) {
          localStorage.setItem("user_role", admin ? "admin" : "others");
          localStorage.setItem("user", JSON.stringify(getUser(response)));
          dispatch(loginSuccess(getUser(response)));
          dispatch(userLoggedIn(true));
        } else {
          dispatch(loginFailure({ source: "not an admin" }));
          dispatch(userLoggedIn(false));
          dispatch(loginProcessing(false));
          return;
        }
      } else if (getErrors(response)) {
        const { errors } = response.data;
        // showErrorNotification(errors && errors[0] && errors[0].title);
        let error = errors;
        if (Array.isArray(errors)) {
          error = errors[0];
        }
        switch (error.source) {
          case "account":
            if (error.title.toLowerCase() === "account not verified") {
            }
            break;
          default:
            dispatch(loginSuccess(undefined));
            dispatch(loginFailure(error));
            dispatch(userLoggedIn(false));
        }
      }
      dispatch(loginProcessing(false));
    });
  };
};

export const resetReducers = () => {
  return { type: "RESET_REDUCERS" };
};

/**
 * @function logoutAction
 * @param dispatch
 * @desc This function logout the user.
 */
export const logoutAction = dispatch => {
  localStorage.removeItem("user");
  localStorage.removeItem("user_role");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_name");
  dispatch(userLoggedIn(false));
  history.push("/sign-in");
};

/**
 * @function logoutAction
 * @param dispatch
 * @desc This function logout the user.
 */
export const logoutActionContainer = () => {
  return dispatch => {
    logout().then(response => {
      localStorage.removeItem("user");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      dispatch(userLoggedIn(false));
      history.push("/sign-in");
    })
  }
};
