import { history } from "./history";

/**
 * @function menuClick
 * @return nothing
 * @desc This function handles redirections when user clicks on top right user menu, navigateTo function is used for showing the selected menu item in left nav
 */
export default (e, navigateTo, logout) => {
  // this is case when lab acoount does not exist
  if (localStorage.getItem("lab_profile_incomplete") === "true") {
    return;
  }

  switch (e.key) {
    case "1":
      history.push("/employers");
      //navigateTo("dashboard", "dashboard");
      break;
    case "2":
      history.push("/profile");
      navigateTo("profile", "profile");
      break;
    case "3":
      history.push("/profile");
      navigateTo("profile", "profile");
      break;
    case "4":
      history.push("/profile");
      navigateTo("profile", "profile");
      break;
    case "5":
      history.push("/profile");
      navigateTo("profile", "profile");
      break;
    default:
      logout();
      break;
  }
};
