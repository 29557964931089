import React from "react";
import { Tabs } from "antd";
import { Link } from "react-router-dom";
import ProfileContainer from "../profile/ProfileContainer";
import EmployeesContainer from "../Employees/EmployeesContainer";
import ReportsContainer from "./Reports/ReportsContainer";

const { TabPane } = Tabs;
class EmployersDetailsView extends React.Component {
  render() {
    const { id, currentPath } = this.props;

    return (
      <Tabs activeKey={currentPath}>
        <TabPane
          key="profile"
          tab={<Link to={`/employers/${id}/profile`}>Profile</Link>}
        >
          <ProfileContainer {...this.props} />
        </TabPane>
        <TabPane
          key="employee"
          tab={<Link to={`/employers/${id}/employee`}>Employees</Link>}
        >
          <EmployeesContainer {...this.props} />
        </TabPane>
        <TabPane
          key="reports"
          tab={<Link to={`/employers/${id}/reports`}>Reports</Link>}
        >
          <ReportsContainer {...this.props} />
        </TabPane>
      </Tabs>
    );
  }
}

export default EmployersDetailsView;
