import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Form,
  Collapse,
  Button,
  InputNumber,
  Spin,
  Empty,
  Descriptions,
  Switch,
  Input
} from "antd";
import moment from "moment";
import AccountCard from "./AccountCard";
import { Currency } from "../../helpers";

const { Panel } = Collapse;
const { Item: FormItem } = Form;

class ProfileView extends Component {
  render() {
    const {
      isProcessing,
      employerDetails,
    } = this.props;

    return (
      <Layout>
        <Spin spinning={isProcessing} className="fixed-spinner">
          <Row>
            <Col>
              <Card>
                <Form>
                  <Collapse activeKey={["f"]} bordered={false}>
                    <Panel key="f" showArrow={false}>
                      <Row type="flex">
                        <Col xs={16}>
                          <FormItem label="Name">
                            {employerDetails.business_name || 'No Name'}
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem label="Admins">
                            {(employerDetails.admins || []).map((admin) =>
                              <div key={admin.id}>{admin.email}</div>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col xs={16}>
                          <FormItem label="Created At">
                            {employerDetails.created_at ? moment(employerDetails.created_at).format(
                              "DD MMM, YYYY"
                            ) : "No date"}
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem label="Status">
                            {employerDetails &&
                              employerDetails.status && (
                                <div>
                                  {employerDetails.status}
                                </div>
                              )}
                          </FormItem>
                        </Col>
                      </Row>

                      <Row type="flex">
                        <Col xs={16}>
                          <FormItem label="Pricing Description">
                            <ul>
                              <li>Processing Fee: {employerDetails.processing_fee || ''}</li>
                              <li>Setup Fee: {employerDetails.setup_fee || ''}</li>
                              <li>Charge per: {employerDetails.charge_fee_per || ''}</li>
                              <li>Description: {employerDetails.pricing_description || ''}</li>
                            </ul>
                            {employerDetails &&
                              employerDetails.invite_pricing_description !== undefined && (
                                <div>
                                  {employerDetails.invite_pricing_description !== null
                                    ? employerDetails.invite_pricing_description
                                    : "N/A"}
                                </div>
                              )}
                          </FormItem>
                        </Col>
                        <Col>
                          <FormItem label="Countries">
                            {employerDetails &&
                              employerDetails.countries && (
                                <div>
                                  {employerDetails.countries.join(', ')}
                                </div>
                              )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                </Form>
              </Card>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={12}>
              {employerDetails && employerDetails.funding_account && (
                <React.Fragment>
                  <h3 className="account-heading">Funding Account</h3>
                  <AccountCard
                    accountDetails={employerDetails.funding_account}
                  />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Spin>
      </Layout>
    );
  }
}

export default ProfileView;
