// Navigation action
export const navigationAction = (currentPage, currentTab) => {
  return { type: "NAVIGATION_SUCCESS", currentPage, currentTab };
};
// Navigation Reducer
const initialState = {
  currentPage: "dashboard",
  currentTab: "dashboard"
};
export const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NAVIGATION_SUCCESS":
      return {
        ...state,
        currentPage: action.currentPage,
        currentTab: action.currentTab
      };
    default:
      return state;
  }
};
