import React from "react";
import {
  Table,
  Pagination,
  Input,
  Select,
  Row,
  Col,
  Form,
  Button,
  Spin
} from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

const FormItem = Form.Item;
const { Option } = Select;

const EmployersView = props => {
  const {
    isProcessing,
    filterChange,
    employersList,
    totalRecords,
    currentPage,
    onChangePage,
    selectedFilter,
    cancelInviteEmployers,
    searchText,
    onchangeSearch,
    handleSearch,
    openDetailsPage,
    onPressDelete
  } = props;

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id"
    },
    {
      title: "Employer name",
      dataIndex: "employer_name",
      key: "employer_name"
    },
    {
      title: "Admin Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Created At",
      dataIndex: "created_at",

      render: record => (
        <div>
          {record && <div>{moment(record).format("DD MMM, YYYY")}</div>}
        </div>
      )
    },
    {
      title: "",
      key: `${Math.random()}`,
      width: "100px",
      render: record => {
        return (
          <div>
            {record &&
              record.invite_status !== null &&
              record.invite_status === "ACCEPTED" && (
                <Row type="flex">
                  <Col className="my-5">
                    <Button
                      onClick={e => onPressDelete(e, record.id)}
                      type="primary"
                      size="small"
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              )}
          </div>
        );
      }
    },
    {
      key: `${Math.random()}`,
      title: "",
      width: "75px",
      render: record => {
        return (
          <div>
            {record &&
              record.invite_status !== null &&
              record.invite_status === "INVITED" && (
                <Row type="flex" justify="space-between">
                  <Col className="my-5">
                    <Button
                      onClick={() => cancelInviteEmployers(record.invite_id)}
                      type="primary"
                      size="small"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              )}
          </div>
        );
      }
    },
    {
      title: "",
      key: `${Math.random()}`,
      width: "75px",
      render: record => {
        return (
          <div>
            {record &&
              record.id && (
                <Row type="flex" justify="space-between">
                  <Col className="my-5">
                    <Button
                      onClick={e => openDetailsPage(record)}
                      type="primary"
                      size="small">
                      View
                    </Button>
                  </Col>
                </Row>
              )}
          </div>
        )
      }
    }
  ];
  return (
    <div>
      <Spin spinning={isProcessing} className="fixed-spinner">
        <React.Fragment>
          <div>
            <Row gutter={16} justify="space-between">
              <Col span={10}>
                <FormItem>
                  <Row gutter={16}>
                    <Col span={18}>
                      <Input
                        name="searchText"
                        placeholder="Search by name, business name, email"
                        value={searchText}
                        onChange={onchangeSearch}
                        onPressEnter={handleSearch}
                      />
                    </Col>
                    <Col span={4}>
                      <Button type="primary" onClick={handleSearch}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </Col>

              <Col span={14}>
                <Row type="flex" justify="end" gutter={16}>
                  <Col span={6}>
                    <FormItem>
                      <Select
                        className="full-width"
                        defaultValue="all"
                        onChange={filterChange}
                        value={selectedFilter}
                      >
                        <Option value="all">All</Option>
                        <Option value="accepted">Accepted</Option>
                        <Option value="invited">Invited</Option>
                        <Option value="cancelled">Cancelled</Option>
                      </Select>
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          {employersList !== undefined && employersList.length > 0 ? (
            <div className="with-hand-cursor">
              <Table
                columns={columns}
                dataSource={employersList}
                pagination={false}
                rowKey={`id`}
              />

              <div className="pagination-wrapper">
                <div className="total-results-wrap">
                  <span className="result-count">{totalRecords}</span>

                  {totalRecords > 1 ? "results" : "result"}
                </div>

                <Pagination
                  total={totalRecords}
                  current={currentPage}
                  onChange={onChangePage}
                  pageSize={10}
                  defaultCurrent={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="blank-View">
              {!isProcessing && (
                <Row>
                  <Col span={24}>
                    <FormItem>
                      <p>There are no employers.</p>
                    </FormItem>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </React.Fragment>
      </Spin>
    </div>
  );
};

export default EmployersView;
