import React from "react";
import { Modal, Descriptions, Divider } from "antd";
import moment from "moment";
import Dollar from "../../helpers/Dollar";

export default class ReportedTransactionDetailModal extends React.Component {
  /**
   * @function showPlaidCategory
   * @param record
   * @description it will fetch and return the last category
   */
  showPlaidCategory = record => {
    return record[record.length - 1];
  };

  render() {
    const {
      transactionDetail,
      showDetailModal,
      toggleDetailModal,
      previewReceipt
    } = this.props;

    let transaction = {};
    if (transactionDetail) {
      transaction = transactionDetail.transaction_details;
    }

    return (
      <div>
        <Modal
          title={`Transaction Details: ${transactionDetail &&
            transactionDetail.id}`}
          visible={showDetailModal}
          onCancel={toggleDetailModal}
          footer={null}
          className="data-modal"
        >
          <Descriptions column={2}>
            <Descriptions.Item label="Employee Name">
              {transaction &&
                transaction.employee_details &&
                transaction.employee_details.employee_name
                ? transaction.employee_details.employee_name
                : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Employee Email">
              {transaction &&
                transaction.employee_details &&
                transaction.employee_details.email
                ? transaction.employee_details.email
                : "--"}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant">
              {transaction && transaction.name}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">
              {transaction &&
                transaction.amount &&
                transaction.amount !== 0
                ? Dollar(transaction.amount)
                : `$0.00`}
            </Descriptions.Item>
            <Descriptions.Item label="Plaid Category">
              {transaction && transaction.plaid_category || '--'}
            </Descriptions.Item>

            <Descriptions.Item label="Reported Category">
              {transaction && transaction.reported_category || '--'}
            </Descriptions.Item>

            <Descriptions.Item label="Transaction date">
              {transaction &&
                transaction.transaction_date &&
                moment.utc(transaction.transaction_date).format("DD MMM, YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {transactionDetail && transactionDetail.status}
            </Descriptions.Item>
            <Descriptions.Item label="Reported At">
              {transactionDetail &&
                transactionDetail.created_at &&
                moment(transactionDetail.created_at).format("DD MMM, YYYY")}
            </Descriptions.Item>

            <Descriptions.Item label="Reported Transaction Description">
              {transaction && transaction.report_details
                ? transaction.report_details.description
                : "--"}
            </Descriptions.Item>

            <Descriptions.Item label="Manual Transaction Description">
              {transaction &&
                transaction.payment_meta &&
                transaction.payment_meta.length > 0
                ? transaction.payment_meta
                : "--"}
            </Descriptions.Item>

          </Descriptions>

          <Descriptions column={1}>
            <Descriptions.Item label="Plaid Transaction Id">
              {transaction && transaction.transaction_id
                ? transaction.transaction_id
                : "--"}
            </Descriptions.Item>
          </Descriptions>

          {transaction &&
            transaction.documents &&
            transaction.documents.documents_list.length > 0 && <Divider />}
          <Descriptions layout="vertical">
            {transaction &&
              transaction.documents &&
              transaction.documents.documents_list.length > 0 && (
                <Descriptions.Item label="Documents">
                  <ul className="receipt_list">
                    {transaction.documents.documents_list.map(documentObj => {
                      return (
                        <li key={documentObj.id}>
                          <a
                            href="#/"
                            onClick={() =>
                              previewReceipt(
                                documentObj.original_filename,
                                documentObj.urls,
                                documentObj.file_type
                              )
                            }
                            className="underline_anchor"
                          >
                            {documentObj.original_filename}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </Descriptions.Item>
              )}
          </Descriptions>
        </Modal>
      </div>
    );
  }
}
